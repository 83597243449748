"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Moment = require("moment");
const _ = require("lodash");
const UserProvider_1 = require("@/providers/UserProvider");
const SelectLineItem_1 = require("../global/SelectLineItem");
const CopyBudgetBtn = ({ budgets, periodStart, _showToast, onSaveBudget }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [showButton, setShowButton] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        var _a;
        setShowButton(((_a = getPeriodDates()) === null || _a === void 0 ? void 0 : _a.filter(o => !!copyBudgetFromPeriod(o.value)).length) > 0);
    }, [budgets]);
    const getPeriodDates = () => {
        const budgetSample = budgets.categories[0];
        if (budgetSample) {
            // Get the current occurrence
            const currentIndex = _.findIndex(budgetSample.occurrences, o => !o.historical);
            return [2, 1, -1].map(o => {
                const occurrenceToCopy = budgetSample.occurrences[currentIndex - o];
                return {
                    key: `copy-budget-${o}`,
                    text: `${Moment(occurrenceToCopy.start_date).format(_user.getFormat('month_day'))} - ${Moment(occurrenceToCopy.end_date).format(_user.getFormat('month_day'))}`,
                    value: o,
                };
            });
        }
        return null;
    };
    const copyBudgetFromPeriod = periodIndex => {
        const _budgets = budgets === null || budgets === void 0 ? void 0 : budgets.categories;
        const updates = _budgets
            .map(o => {
            // Get the current occurrence
            const currentIndex = _.findIndex(o.occurrences, o => !o.historical);
            const occurrenceToCopy = o.occurrences[currentIndex - periodIndex];
            const newBudget = (occurrenceToCopy === null || occurrenceToCopy === void 0 ? void 0 : occurrenceToCopy.budgeted) !== null
                ? {
                    start_date: periodStart,
                    amount: occurrenceToCopy.budgeted,
                    category_id: o.properties.category.id,
                }
                : null;
            return newBudget;
        })
            .filter(o => !!o);
        if (updates.length == 0) {
            return null;
        }
        else {
            return updates;
        }
    };
    return (React.createElement(React.Fragment, null, showButton && (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "mb-05rem width-100", icon: true, labelPosition: "left" },
            React.createElement(semantic_ui_react_1.Icon, { name: "copy" }),
            React.createElement("span", { className: "position-top-1-5px" }, "Copy Budget")), position: "bottom center", size: "small", basic: true },
        React.createElement("div", { style: { minWidth: '220px' } },
            React.createElement("div", { className: "label-style  mb-05rem" }, "Copy budgeted values from:"), (_a = getPeriodDates()) === null || _a === void 0 ? void 0 :
            _a.map(o => {
                if (copyBudgetFromPeriod(o.value) == null) {
                    return null;
                }
                return (React.createElement(SelectLineItem_1.default, { key: `copy-budget-${o.value}`, keyValue: 'copy-budget', selectedValue: false, label: o.text, hideIcon: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield onSaveBudget(copyBudgetFromPeriod(o.value));
                        _showToast({
                            type: 'success',
                            message: 'Budget copied successfully',
                        });
                    }) }));
            }))))));
};
exports.default = CopyBudgetBtn;
