"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const format_1 = require("@/helpers/format");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const qs = require("query-string");
const React = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Budget_1 = require("../TaskCard/Budget");
const UserProvider_1 = require("@/providers/UserProvider");
const TransferPopup_1 = require("./TransferPopup");
const SelectLineItem_1 = require("../global/SelectLineItem");
const Help = ({ budgets, close }) => {
    var _a;
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [index, setIndex] = (0, react_1.useState)(!!budgets['configs'] ? 7 : null);
    const [openMoveBudget, setOpenMoveBudget] = (0, react_1.useState)(false);
    const historicalValues = [
        {
            text: 'This Period',
            start_date: '2024-12-01',
            end_date: '2024-12-31',
            value: 134,
        },
        {
            start_date: '2024-11-01',
            end_date: '2024-11-30',
            value: 187,
        },
        {
            start_date: '2024-10-01',
            end_date: '2024-10-31',
            value: 192,
        },
        {
            start_date: '2024-09-01',
            end_date: '2024-09-30',
            value: 142,
        },
    ];
    return (React.createElement(semantic_ui_react_1.Modal, { dimmer: 'inverted', open: true, size: 'small' },
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement("div", { className: "content" },
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-1rem" }, "Welcome to the new Budgeting feature!"),
                    React.createElement(semantic_ui_react_1.Message.Content, null,
                        React.createElement("p", null,
                            "Use this as a guide to all the new features. Need to re-access this later on? Just click the",
                            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", size: "small" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "info" }),
                                " Feature Guide"),
                            ' ',
                            "button."),
                        React.createElement("p", null, "Have feedback or need to report a bug regarding this feature in beta? We'd love to hear about it!"),
                        React.createElement("p", null,
                            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: () => {
                                    history.push({
                                        search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { show_feedback: 'beta-testing-feedback' })),
                                    });
                                }, size: "small" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "comment" }),
                                "Share feedback")))),
                React.createElement(semantic_ui_react_1.Accordion, null,
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 0, index: 0, onClick: () => {
                            setIndex(index === 0 ? null : 0);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Custom Budgeting Periods")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 0 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "Budget beyond the month! You can choose to align your budgeting periods to your pay period. Customize your budget to reset every day, week, month or year!"),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null,
                            "Adjust your budget periods by clicking on",
                            ' ',
                            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", size: "small" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "cog" }),
                                " Budget Settings"),
                            ' ',
                            "at the top right corner."),
                        React.createElement(semantic_ui_react_1.Form, { style: { width: '450px' } },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null, [
                                    {
                                        key: 'available funds',
                                        value: 'available funds',
                                        text: `Every calendar month (e.g., the 1st of each month).`,
                                    },
                                    {
                                        key: 'same category',
                                        value: 'same category',
                                        text: `Every paycheck (align your budget with your income schedule).`,
                                    },
                                ].map(o => (React.createElement(SelectLineItem_1.default, { keyValue: o.value, selectedValue: 'available funds', label: o.text }))))))),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 1, index: 1, onClick: () => {
                            setIndex(index === 1 ? null : 1);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Rollover Options")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 1 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "Choose how to handle underspend and overspend for each category. Either rollover to the same category for envelope budgeting, rollover to general pool for zero-based. The best part? You can customize this setting for every individual category."),
                        React.createElement("p", null, "We highly recommend rolling over Income categories to the general pool instead of 'same category'."),
                        React.createElement("h4", null, "How do I access it?"),
                        React.createElement("p", null,
                            "Click on the right arrow (",
                            React.createElement(semantic_ui_react_1.Icon, { name: "angle right", className: "color--grey" }),
                            ") for any individual category (anything but a category group) to open up its side panel. From there, you can choose your rollover option as shown below:"),
                        React.createElement(semantic_ui_react_1.Form, { style: { width: '380px' } },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null, [
                                    {
                                        key: 'same category',
                                        value: 'same category',
                                        text: `Rollover to same category next period`,
                                    },
                                    {
                                        key: 'available funds',
                                        value: 'available funds',
                                        text: `Rollover to general pool next period (Recommended)`,
                                    },
                                    {
                                        key: 'none',
                                        value: null,
                                        text: `Do nothing (Not recommended)`,
                                    },
                                ].map(o => (React.createElement(SelectLineItem_1.default, { keyValue: o.value, selectedValue: 'available funds', label: o.text }))))))),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 2, index: 2, onClick: () => {
                            setIndex(index === 2 ? null : 2);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Rollover Pool")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 2 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "This represents where over- and under-spending from previous periods accumulates."),
                        React.createElement("h4", null, "How is it calculated?"),
                        React.createElement("p", null,
                            "This number is calculated from whatever is \"Left to Budget\" or \"Overbudgeted\" from the previous period, plus rollovers from categories set to rollover to general pool. You can see the exact calculations by hovering over",
                            ' ',
                            React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }),
                            "."),
                        React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, selectable: true, sortable: true, celled: true, className: "p-budget-table" },
                            React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                                React.createElement(semantic_ui_react_1.Table.Row, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Inflow"),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '130px' } }))),
                            React.createElement(semantic_ui_react_1.Table.Body, null,
                                React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, "Rolled over from previous period (Sun, Dec 1 - Tue, Dec 31)"),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align` },
                                        React.createElement("div", { className: "flex--space-between-flex-start" },
                                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "clickable" }), size: "small" },
                                                React.createElement("p", null,
                                                    "This number is calculated from how much was left to budget last period (",
                                                    React.createElement("b", null, (0, format_1.toPrice)(478, _user.primaryCurrency)),
                                                    "), plus rollovers from specific categories:"),
                                                React.createElement("div", { className: `line-item` },
                                                    React.createElement("div", { className: "" }, "Restaurants"),
                                                    React.createElement("div", { className: `underline` }),
                                                    React.createElement("div", { className: `property monospace` }, (0, format_1.toPrice)(-55, _user.primaryCurrency))),
                                                React.createElement("div", { className: `line-item` },
                                                    React.createElement("div", { className: "" }, "Coffee Shops"),
                                                    React.createElement("div", { className: `underline` }),
                                                    React.createElement("div", { className: `property monospace` }, (0, format_1.toPrice)(-12.5, _user.primaryCurrency))),
                                                React.createElement("div", { className: `line-item` },
                                                    React.createElement("div", { className: "font--bold" }, "Total from categories"),
                                                    React.createElement("div", { className: `underline` }),
                                                    React.createElement("div", { className: `property monospace` }, (0, format_1.toPrice)(-67.5, _user.primaryCurrency)))),
                                            React.createElement("div", { className: `color--green` }, (0, format_1.toPrice)(410.5, _user.primaryCurrency))))))),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null, "You can either view your available funds (that is, the total of your rollover pool) from the Totals row of your Inflow table, or from the Task Card on the right side:"),
                        React.createElement(Budget_1.default, { leftToBudget: 410.5 }),
                        React.createElement("p", null, "The Rollover Pool line item under Inflow will display automatically when there are funds from previous periods that have rolled over to the current period."),
                        React.createElement("p", null, "To reset your Rollover Pool back to $0 and ignore all past rollover values, you can update your \"Rollover Start Date\" to the next or current period. If you have Rollover Start Dates synced (highly recommended), you can update this from the Budget Settings."),
                        React.createElement("p", null, "Need envelope budgeting? You'll be able to see easily how much inflow money you have to budget for the month.")),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 3, index: 3, onClick: () => {
                            setIndex(index === 3 ? null : 3);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Quick Budget Actions")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 3 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "You have a few options for quick or bulk actions relating to setting your budget."),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", className: "mb-05rem", style: { width: '260px' }, icon: true, labelPosition: "left" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "magic" }),
                            "One-click budgeting"),
                        React.createElement("p", null,
                            "Customize your one-click budgeting settings for individual categories to an easy way to set your budgets. Available one-click budgeting settings include:",
                            React.createElement("ul", null,
                                React.createElement("li", null, "Add a fixed amount"),
                                React.createElement("li", null, "Fill 'Available' up to a certain amount"),
                                React.createElement("li", null, "Set to previous period's spend"),
                                React.createElement("li", null, "Set to previous period's budget"),
                                React.createElement("li", null, "Set to average spend of last 3 periods"),
                                React.createElement("li", null, "Do nothing"))),
                        React.createElement("p", null, "This also includes options to automatically balance unbudgeted categories with overspend, or a negative 'Available' amount."),
                        React.createElement("p", null, "You'll be presented with all the changes or overrides that will occur and you will have a chance to select or deselect certain categories."),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", className: "mb-05rem", style: { width: '260px' }, icon: true, labelPosition: "left" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "copy" }),
                            "Copy Budget"),
                        React.createElement("p", null, "Use this option to automatically copy the same budgeted values from surrounding periods."),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", className: "mb-05rem", style: { width: '260px' }, icon: true, labelPosition: "left" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "trash" }),
                            "Clear all budgets"),
                        React.createElement("p", null, "Use this option to delete all budgeted values for the current period and start fresh."),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null, "The above buttons will only show up under the Task Card if they will have an effect. For instance, if no budgets are set for the current period, the \"Clear All Budgets\" button will not appear since there's nothing to delete.")),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 4, index: 4, onClick: () => {
                            setIndex(index === 4 ? null : 4);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Moving budgets")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 4 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "Easily move extra money from one category to cover underspending in another! This is envelope budgeting behavior."),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null,
                            "Click the ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "exchange" }),
                            " icon that pops up for eligible amounts (try it out below!)"),
                        React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, selectable: true, sortable: true, celled: true, className: "p-budget-table" },
                            React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                                React.createElement(semantic_ui_react_1.Table.Row, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Inflow"),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '130px' } }))),
                            React.createElement(semantic_ui_react_1.Table.Body, null,
                                React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, "Restaurants"),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align`, style: { width: '150px' } },
                                        React.createElement("div", { className: "flex--space-between-flex-start hover-toggle" },
                                            React.createElement(semantic_ui_react_1.Popup, { wide: "very", open: openMoveBudget, position: 'top left', closeOnTriggerClick: true, onClose: () => {
                                                    setOpenMoveBudget(false);
                                                }, trigger: React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exchange", className: `clickable`, onClick: () => {
                                                            setOpenMoveBudget(!openMoveBudget);
                                                        } }), inverted: true, size: "tiny" }, "Move money to this category") },
                                                React.createElement(TransferPopup_1.default, { leftToBudget: 1500, category_id: -1, group_category_id: null, start_date: '2024-12-01', available: 1000, activity: 50, outflowBudgets: [], onMoveBudget: (obj) => __awaiter(void 0, void 0, void 0, function* () { }) })),
                                            React.createElement("div", null, (0, format_1.toPrice)(123, _user.primaryCurrency)))))))),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 5, index: 5, onClick: () => {
                            setIndex(index === 5 ? null : 5);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " View historical spend activity")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 5 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "View historical spend data from the previous 3 periods for that category. You can also view relevant transactions in a pop-up and percentage changes."),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null, "Hover over any dash underlined amount in the Activity column to see historical spend data for that category. Amounts that have no dashed underline did not have spend in that category for the 3 previous periods. (Try it below!)"),
                        React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, selectable: true, sortable: true, celled: true, className: "p-budget-table" },
                            React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                                React.createElement(semantic_ui_react_1.Table.Row, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Inflow"),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '130px' } }))),
                            React.createElement(semantic_ui_react_1.Table.Body, null,
                                React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, "Restaurants"),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align`, style: { width: '150px' } },
                                        React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("div", { className: "flex--space-between" },
                                                React.createElement("div", null),
                                                React.createElement("div", { className: `display--flex underline-text` }, (0, format_1.toPrice)(134, _user.primaryCurrency))), hoverable: true, size: "small" },
                                            React.createElement("div", { style: { minWidth: '220px' } },
                                                React.createElement("div", { className: "label-style underlined mb-05rem" }, "Historical Activity for Restaurants"),
                                                historicalValues.map((o, i) => {
                                                    return (React.createElement("div", { key: `historical-${i}`, className: "flex--space-between-flex-center mt-05rem" },
                                                        React.createElement("div", { className: "display--flex" },
                                                            React.createElement("div", { className: "monospace no-wrap" }, o.text ||
                                                                Moment(o.start_date).format(_user.getFormat('month_day'))),
                                                            o.value &&
                                                                i !== historicalValues.length - 1 ? (React.createElement("div", { className: " ml-05rem mr-05rem" },
                                                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover" }) }, "View transactions (pop-up)"))) : (React.createElement(React.Fragment, null))),
                                                        React.createElement("div", { className: "monospace flex--align-center" },
                                                            i > 0 && !!historicalValues[i - 1].value && (React.createElement(semantic_ui_react_1.Label, { basic: true, color: o.value > historicalValues[i - 1].value
                                                                    ? 'red'
                                                                    : 'green', className: "custom-budget-label ml-05rem" },
                                                                React.createElement(semantic_ui_react_1.Icon, { color: o.value >
                                                                        historicalValues[i - 1].value
                                                                        ? 'red'
                                                                        : 'green', name: o.value >
                                                                        historicalValues[i - 1].value
                                                                        ? 'caret up'
                                                                        : 'caret down' }),
                                                                (0, format_1.toPercentage)((o.value -
                                                                    historicalValues[i - 1].value) /
                                                                    historicalValues[i - 1].value, 1))),
                                                            React.createElement("div", { className: "ml-05rem no-wrap" }, (0, format_1.toPrice)(o.value, _user.primaryCurrency)))));
                                                })))))))),
                    React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 6, index: 6, onClick: () => {
                            setIndex(index === 6 ? null : 6);
                        } },
                        React.createElement("h3", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                            " Quickly view all settings")),
                    React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 6 },
                        React.createElement("h4", null, "What is it?"),
                        React.createElement("p", null, "Toggle this button to quickly view the budgeting settings for each category. This is convenient if you don\u2019t remember which categories had which settings, and you don't want to click through every individual category to check."),
                        React.createElement("h4", null, "How to access it"),
                        React.createElement("p", null, "This button can be found just under the Date. The highlighted button represents the current view."),
                        React.createElement(semantic_ui_react_1.Button.Group, null,
                            React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange" }, "Budget View"),
                            React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: false }, "Settings View"))),
                    budgets['configs'] && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.AccordionTitle, { active: index === 7, index: 7, onClick: () => {
                                setIndex(index === 7 ? null : 7);
                            } },
                            React.createElement("h3", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                                " Budget configuration migrations")),
                        React.createElement(semantic_ui_react_1.AccordionContent, { active: index === 7 },
                            React.createElement("p", null, "Note: the following existing budgeting configurations have been migrated to one-click budgeting settings:"), (_a = budgets['configs']) === null || _a === void 0 ? void 0 :
                            _a.map((config, index) => {
                                return (React.createElement("div", null,
                                    React.createElement("b", null, _categories.getName(config.category_id)),
                                    ":",
                                    React.createElement("div", { className: "display--flex flex--space-between-flex-start width-100 mt-05rem" },
                                        React.createElement(semantic_ui_react_1.Message, { info: true, style: { width: '50%' } },
                                            React.createElement("b", null, "Original"),
                                            React.createElement("p", null, config.auto_suggest == 'fixed-rollover'
                                                ? `A fixed amount (${(0, format_1.toPrice)(config.amount, config.currency)}) with rollover`
                                                : config.auto_suggest == 'fixed'
                                                    ? `A fixed amount (${(0, format_1.toPrice)(config.amount, config.currency)})`
                                                    : config.auto_suggest == 'budgeted'
                                                        ? `How much I budgeted last month`
                                                        : config.auto_suggest == 'spent'
                                                            ? `How much I spent last month`
                                                            : '')),
                                        React.createElement(semantic_ui_react_1.Message, { success: true, className: "mt-0 ml-05rem", style: { width: '50%' } },
                                            React.createElement("b", null, "New"),
                                            React.createElement("p", null, config.auto_suggest == 'fixed-rollover' ? (React.createElement(React.Fragment, null,
                                                React.createElement("span", null,
                                                    React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }),
                                                    " Add fixed amount (",
                                                    (0, format_1.toPrice)(config.amount, config.currency),
                                                    ")"),
                                                React.createElement("br", null),
                                                React.createElement("span", null,
                                                    React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }),
                                                    ' ',
                                                    _categories.getName(config.category_id),
                                                    ' ',
                                                    "overspend/underspend will rollover to same category next period"))) : config.auto_suggest == 'fixed' ? (React.createElement("span", null,
                                                React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }),
                                                " Add fixed amount (",
                                                (0, format_1.toPrice)(config.amount, config.currency),
                                                ")")) : config.auto_suggest == 'budgeted' ? (React.createElement("span", null,
                                                React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }),
                                                " Set to previous period's budget")) : config.auto_suggest == 'spent' ? (React.createElement("span", null,
                                                React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }),
                                                " Set to previous period's spend")) : (''))))));
                            }))))))),
        React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", color: "green", onClick: () => {
                    close();
                    localStorage.setItem('viewed_budgeting_intro', 'true');
                } },
                "Got it ",
                React.createElement(semantic_ui_react_1.Icon, { name: "check" })))));
};
exports.default = Help;
