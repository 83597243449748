"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const TagStatus_1 = require("./TagStatus");
const react_1 = require("react");
const tags_1 = require("@actions/tags");
const TagSummary = ({ start_date, end_date, displayCurrency, setTriggerRefresh, triggerRefresh, currentFilterName, flipSign, }) => {
    const [tagsSummary, setTagsSummary] = (0, react_1.useState)([]);
    const [tagsUI, setTagsUI] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        const fetchTagSummary = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoading(true);
                const results = yield (0, tags_1.getTagSummary)({
                    start_date,
                    end_date,
                    currency: displayCurrency,
                });
                setTagsSummary(results.summary);
                setTriggerRefresh();
                setIsLoading(false);
            }
            catch (e) {
                console.log('Error loading tag summary', e);
            }
        });
        fetchTagSummary();
    }, [triggerRefresh]);
    (0, react_1.useEffect)(() => {
        if (!tagsSummary || tagsSummary.length == 0) {
            setTagsUI([]);
            setIsLoading(false);
            return;
        }
        try {
            const tags = tagsSummary
                .sort((a, b) => {
                return b.base_total - a.base_total;
            })
                .map((tag, index) => {
                return (React.createElement(TagStatus_1.default, { key: `tag-summary-${index}`, tag: tag, total: tag.base_total, currency: tag.currency, flipSign: flipSign }));
            });
            setTagsUI(tags);
            setIsLoading(false);
        }
        catch (e) {
            console.log('Error generating tag rows', e);
        }
    }, [tagsSummary, currentFilterName, flipSign]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card.Content, null,
            isLoading && React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }),
            !isLoading && tagsUI && tagsUI.length > 0 && tagsUI,
            !isLoading && (tagsUI == null || (tagsUI && tagsUI.length == 0)) && (React.createElement("span", { className: "display--flex align-center" },
                React.createElement(react_router_dom_1.Link, { to: { pathname: `/tags` } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }),
                    " Set up tags"))))));
};
exports.default = TagSummary;
