"use strict";
/**
 *  EditablePrice.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const checks_1 = require("@helpers/checks");
const Autosuggest = require("react-autosuggest");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_2 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
var State;
(function (State) {
    /** Default display */
    State["Default"] = "Default";
    /** Is currently being edited */
    State["Editing"] = "Editing";
    /** Is read only; not editable */
    State["ReadOnly"] = "ReadOnly";
})(State || (State = {}));
const EditablePrice = ({ identifier, amount = null, currency = null, onSave = null, onEnter = () => { }, state = 'Default', location, displayNegativeSign = false, isSplit = false, isGrouped = false, isPartOfGroup = false, autoFocus = false, additionalCurrencies = [], isRecurring = false, showSignLabel = false, saveOnChange = false, saveOnError = false, blurOnEnter = false, showEmptyState = true, nullValue = null, showLoading = false, showIcon = false, iconTooltip = null, iconOnClick = () => { }, allowEmpty = false, allowNegative = true, defaultNotation = 'debit', disabled = false, disableCurrency = false, error = false, isCrypto = false, className = '', allowEscape = true, suggestions = null, shouldConfirmPlaidChange = false, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [showEditCryptoConfirm, setShowEditCryptoConfirm] = (0, react_1.useState)(null);
    // For blur
    const ref = (0, react_1.useRef)();
    useOnClickOutside(ref, () => {
        if ((location === 'button' || location === 'inline-edit') &&
            currentState === State.Editing) {
            if ((0, checks_1.isNullOrEmpty)(currentAmount) && !allowEmpty) {
                setCurrentAmount(amount); // reset values
                setCurrentCurrency(currency); // reset values
                onFinishEditing(amount, currency);
            }
            else {
                onFinishEditing(currentAmount, currentCurrency);
            }
            onEnter();
            setCurrentState(State.Default);
        }
    });
    const currencyOptions = _.uniq([
        ..._user.supportedCurrencies,
        ...additionalCurrencies,
    ])
        .map(currency => {
        return {
            key: currency,
            value: currency,
            text: currency === null || currency === void 0 ? void 0 : currency.toUpperCase(),
        };
    })
        .concat(!(0, checks_1.isNull)(currency) &&
        currency !== _user.primaryCurrency &&
        _user.supportedCurrencies.indexOf(currency) == -1 &&
        additionalCurrencies.indexOf(currency) == -1 &&
        !isCrypto
        ? [
            {
                key: currency,
                value: currency,
                text: currency === null || currency === void 0 ? void 0 : currency.toUpperCase(),
            },
        ]
        : []);
    const [currentState, setCurrentState] = (0, react_1.useState)(State[state] || State.Default);
    const [currentAmount, setCurrentAmount] = (0, react_1.useState)('');
    const [currentCurrency, setCurrentCurrency] = (0, react_1.useState)(currency || _user.primaryCurrency);
    const [showError, setShowError] = (0, react_1.useState)(error);
    const [displayAsNegative, setDisplayAsNegative] = (0, react_1.useState)((defaultNotation == 'debit' && _user.settings['show_debits_as_negative']) ||
        (defaultNotation == 'credit' &&
            !_user.settings['show_debits_as_negative']));
    const [didValuesChange, setDidValuesChange] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        var _a;
        if (currentState == State.Editing &&
            identifier &&
            location == 'inline-edit') {
            (_a = document
                .getElementById(identifier)) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', handleKeyDown, false);
        }
        return () => {
            var _a;
            (_a = document
                .getElementById(identifier)) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', handleKeyDown);
        };
    }, [identifier, isCrypto, currentState, location]);
    /**
     * Key listener on body
     */
    const handleKeyDown = event => {
        if (event.keyCode === 27 && allowEscape) {
            // ESC
            if (isCrypto) {
                setCurrentAmount((0, format_1.toCrypto)(amount.toString(), currency, true));
            }
            else {
                setCurrentAmount((0, format_1.toPrice)(amount === null || amount === void 0 ? void 0 : amount.toString(), currency || _user.primaryCurrency, undefined, true));
            }
            setCurrentCurrency(currency);
            setCurrentState(State.Default);
        }
    };
    (0, react_1.useEffect)(() => {
        setDisplayAsNegative((defaultNotation == 'debit' &&
            _user.settings['show_debits_as_negative']) ||
            (defaultNotation == 'credit' &&
                !_user.settings['show_debits_as_negative']));
    }, [defaultNotation]);
    (0, react_1.useEffect)(() => {
        if (!(0, checks_1.isNull)(amount) && amount !== '') {
            let formattedAmount = amount.toString();
            if (!displayNegativeSign) {
                if (formattedAmount.indexOf('-') == 0) {
                    // is negative in the database
                    if (_user.settings['show_debits_as_negative']) {
                        setDisplayAsNegative(false);
                        formattedAmount = formattedAmount.substring(1);
                    }
                    else {
                        setDisplayAsNegative(true);
                        formattedAmount = formattedAmount.substring(1);
                    }
                }
                else {
                    // is positive in the database
                    if (_user.settings['show_debits_as_negative']) {
                        setDisplayAsNegative(true);
                    }
                    else {
                        setDisplayAsNegative(false);
                    }
                }
            }
            // Format it as it would be
            if (isCrypto) {
                setCurrentAmount((0, format_1.toCrypto)(formattedAmount, currency, true));
            }
            else {
                setCurrentAmount((0, format_1.toPrice)(formattedAmount, currency || _user.primaryCurrency, undefined, true));
            }
        }
        else {
            setCurrentAmount('');
        }
        setCurrentCurrency(currency || _user.primaryCurrency);
        setDidValuesChange(false);
    }, [amount, currency, isCrypto]);
    (0, react_1.useEffect)(() => {
        if (currentState === 'Editing') {
            if (location == 'inline-edit') {
                document.addEventListener('click', handleClick, false);
            }
            return () => {
                document.removeEventListener('click', handleClick, false);
            };
        }
    }, [
        currentState,
        location,
        didValuesChange,
        displayNegativeSign,
        displayAsNegative,
        identifier,
        currentAmount,
        currentCurrency,
    ]);
    (0, react_1.useEffect)(() => {
        setCurrentState(state);
    }, [state]);
    const handleClick = e => {
        if (!document
            .getElementById(`${identifier}-parent`)
            .contains(e.target) &&
            // Exclude new Mui transactions
            // AFAIK this is a differentiator for coming from MUI and not
            !allowEscape) {
            onFinishEditing(currentAmount, currentCurrency);
        }
    };
    const onFinishEditing = (_amount_1, _currency_1, ...args_1) => __awaiter(void 0, [_amount_1, _currency_1, ...args_1], void 0, function* (_amount, _currency, isNegative = displayAsNegative, valuesChanged = false) {
        var _a;
        if (!allowNegative) {
            if (_amount.indexOf('-') == 0) {
                setShowError(true);
                return;
            }
            _amount = Math.abs(_amount) || _amount;
        }
        if (allowEmpty && (_amount == null || _amount == '')) {
            setCurrentState(location === 'inline-edit' ? State.Default : State.Editing);
            if (onSave && (didValuesChange || valuesChanged)) {
                yield onSave(null, _currency);
            }
            return;
        }
        // In case there are supported special characters, like a comma
        // But this might also be used as a thousands separator
        _amount = (0, format_2.parseNumber)(_amount);
        // Make sure the _amount is the right sign before we add it to the db
        if (showSignLabel) {
            if (!isNegative && _user.settings['show_debits_as_negative']) {
                _amount = -1 * _amount;
            }
            else if (isNegative && !_user.settings['show_debits_as_negative']) {
                _amount = -1 * _amount;
            }
        }
        if (
        // typeof _amount == 'undefined' ||
        // _amount === null ||
        // _amount === '' ||
        !didValuesChange &&
            !valuesChanged) {
            setCurrentState(location === 'inline-edit' ? State.Default : State.Editing);
        }
        else if (typeof _amount == 'undefined' ||
            _amount === null ||
            _amount === '' ||
            //Number(_amount) !== 0 && !parseFloat(_amount.toString())) ||
            _amount.toString().length === 0 ||
            isNaN(_amount)) {
            if (allowEmpty) {
                // Don't accept non-numbers.
                setShowError(true);
                yield onSave(null, _currency);
            }
            else {
                setShowError(true);
                if (saveOnError) {
                    yield onSave(_amount, _currency);
                }
            }
            // Don't save if this isn't a proper number
        }
        else {
            setShowError(false);
            // if (location !== 'inline-edit') {
            setCurrentState(location === 'inline-edit' ? State.Default : State.Editing);
            // }
            if (onSave) {
                const results = yield onSave(_amount, _currency);
                // This happens when the onSave fails and we have to revert back to
                // another value.
                if ((results === null || results === void 0 ? void 0 : results.hasOwnProperty('amount')) &&
                    (results === null || results === void 0 ? void 0 : results.hasOwnProperty('currency'))) {
                    setCurrentAmount(((_a = results.amount) === null || _a === void 0 ? void 0 : _a.toString().length) > 0 ? results.amount : '');
                    setCurrentCurrency(results.currency);
                }
            }
        }
    });
    const getDisplayAmount = () => {
        if (currentAmount == null || currentAmount == '') {
            return nullValue || '-';
        }
        let displayAmount = (0, format_2.parseNumber)(currentAmount);
        if (displayAsNegative && showSignLabel) {
            displayAmount = -1 * displayAmount;
        }
        if (isCrypto) {
            return (0, format_1.toCrypto)(displayAmount, currentCurrency);
        }
        else {
            return (0, format_1.toPrice)(displayAmount, currentCurrency);
        }
    };
    const shouldDisplayAsGreen = (force = false) => {
        if (!force &&
            (currentAmount == null ||
                (0, format_2.parseNumber)(currentAmount) == 0 ||
                currentAmount == ''))
            return false;
        return ((_user.settings['show_debits_as_negative'] && !displayAsNegative) ||
            (!_user.settings['show_debits_as_negative'] && displayAsNegative));
    };
    return (React.createElement("div", { ref: ref, className: `g-editable-text ${className} editable-price-${location} ${currentState === State.Editing && location.indexOf('inline') > -1
            ? 'selected'
            : ''}`, id: `${identifier}-parent` },
        currentState === State.ReadOnly &&
            !isSplit &&
            !isGrouped &&
            !isPartOfGroup &&
            !isRecurring && (React.createElement("span", { className: `readonly ${shouldDisplayAsGreen() ? 'color--green' : ''}` }, getDisplayAmount())),
        currentState === State.Default &&
            !isRecurring &&
            // !isNull(currentAmount) &&
            !isSplit &&
            !isGrouped &&
            !isPartOfGroup && (React.createElement("div", { className: `default-state ${location === 'button' ? 'editable-price-default-state' : ''} ${showEmptyState && ((0, checks_1.isNull)(currentAmount) || currentAmount == '')
                ? 'empty-state'
                : ''} ${shouldDisplayAsGreen() ? 'color--green' : ''}`, onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                if (isCrypto && !disableCurrency) {
                    setShowEditCryptoConfirm(true);
                }
                else {
                    setCurrentState(State.Editing);
                }
            } },
            showLoading ? (React.createElement(semantic_ui_react_1.Icon, { loading: true, name: "spinner" })) : showIcon ? (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "check circle", className: "color--grey dark-grey-on-hover clickable position-top--2px", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        e.preventDefault();
                        e.stopPropagation();
                        yield iconOnClick();
                    }) }), inverted: true, size: "small" }, iconTooltip)) : (React.createElement(React.Fragment, null)),
            React.createElement("span", { className: "primary" }, getDisplayAmount()))),
        currentState === State.Default && isRecurring && !isGrouped && !isSplit && (React.createElement("div", { className: `default-state ${location === 'button' ? 'editable-price-default-state' : ''} ${shouldDisplayAsGreen() ? 'color--green' : ''}`, onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, mouseEnterDelay: 750, size: "small", position: 'top center', trigger: React.createElement("span", { className: "recurring-icon" }) },
                    React.createElement("p", null, "This transaction is a recurring transaction.")),
                React.createElement("span", null, getDisplayAmount())))),
        isGrouped && !isRecurring && (React.createElement("span", { className: "readonly" },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement("span", { className: "grouped-icon" }),
                React.createElement("span", { className: `${shouldDisplayAsGreen() ? 'color--green' : ''}` }, getDisplayAmount())))),
        currentState === State.Default && isPartOfGroup && (React.createElement("div", { className: "default-state", onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, mouseEnterDelay: 750, size: "small", position: 'top center', trigger: React.createElement("span", { className: "part-of-group-icon" }) },
                    React.createElement("p", null, "This transaction is part of a grouped transaction.")),
                React.createElement("span", { className: `${shouldDisplayAsGreen() ? 'color--green' : ''}` }, getDisplayAmount())))),
        currentState === State.ReadOnly && isPartOfGroup && (React.createElement("span", { className: "readonly" },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, mouseEnterDelay: 750, size: "small", position: 'top center', trigger: React.createElement("span", { className: "part-of-group-icon" }) },
                    React.createElement("p", null, "This transaction is part of a grouped transaction.")),
                React.createElement("span", { className: `${shouldDisplayAsGreen() ? 'color--green' : ''}` }, getDisplayAmount())))),
        ((currentState === State.ReadOnly && isRecurring && !isSplit) ||
            // Grouped should never be editable
            (currentState === State.Default && isRecurring && isGrouped)) && (React.createElement("span", { className: "readonly" },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, mouseEnterDelay: 750, size: "small", position: 'top center', trigger: React.createElement("span", { className: "recurring-icon" }) },
                    React.createElement("p", null, "This transaction is a recurring transaction.")),
                React.createElement("span", null, getDisplayAmount())))),
        isSplit && !isGrouped && !isPartOfGroup && (React.createElement("span", { className: "readonly" },
            React.createElement("span", { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, mouseEnterDelay: 750, size: "small", position: 'top center', trigger: React.createElement("span", { className: "split-icon" }) },
                    React.createElement("p", null, "This is a split transaction.")),
                React.createElement("span", { className: `${shouldDisplayAsGreen() ? 'color--green' : ''}` }, getDisplayAmount())))),
        currentState === State.Editing && (React.createElement(semantic_ui_react_1.Input, { type: "number", disabled: disabled, action: true, className: `editable-price width-100 ${showSignLabel ? 'has-sign-label' : ''} ${currencyOptions.length > 1 ? 'has-dropdown' : ''} ${className}` },
            showSignLabel && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement("div", { className: `editable-price-negative clickable ${shouldDisplayAsGreen(true) ? 'positive' : ''}`, onClick: () => {
                        const newValue = !displayAsNegative;
                        setDidValuesChange(true);
                        setDisplayAsNegative(newValue);
                        if (saveOnChange) {
                            onFinishEditing(currentAmount, currentCurrency || _user.primaryCurrency, newValue, true);
                        }
                    } }, displayAsNegative ? React.createElement("span", null, "-") : React.createElement("span", null, "+")) }, _user.settings['show_debits_as_negative']
                ? 'Use [-] for expenses and [+] for credits/income'
                : 'Use [+] for expenses and [-] for credits/income')),
            (suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) > 0 ? (React.createElement(Autosuggest, { suggestions: suggestions, getSuggestionValue: suggestion => {
                    return suggestion;
                }, renderSuggestion: suggestion => {
                    return (React.createElement("div", { className: "flex--space-between" },
                        React.createElement("span", { className: "label-style" },
                            suggestion.text,
                            ' ',
                            currentAmount == suggestion.amount &&
                                currentCurrency == suggestion.currency && (React.createElement(semantic_ui_react_1.Icon, { name: "check", color: "green" }))),
                        React.createElement("span", { className: "monospace" }, (0, format_1.toPrice)(suggestion.amount, suggestion.currency))));
                }, shouldRenderSuggestions: (value, reason) => {
                    return true;
                }, onSuggestionsFetchRequested: ({ value, reason }) => {
                    // if (
                    //   (reason == 'input-focused' && !!datalist) ||
                    //   reason == 'input-changed'
                    // ) {
                    //   getSuggestions(value)
                    // }
                }, onSuggestionsClearRequested: () => { }, inputProps: {
                    autoFocus: true,
                    placeholder: currencyOptions.length > 1
                        ? 0
                        : isCrypto
                            ? (0, format_1.toCrypto)(0, currentCurrency)
                            : (0, format_1.toPrice)(0, currentCurrency),
                    value: currentAmount || '',
                    onChange: (event, { newValue, method }) => {
                        if (method == 'click' || method == 'enter') {
                            onFinishEditing(newValue.amount, newValue.currency, undefined, true);
                        }
                        else if (method == 'type') {
                            setDidValuesChange(true);
                            setCurrentAmount(newValue);
                            if (saveOnChange) {
                                onFinishEditing(newValue, currentCurrency || _user.primaryCurrency, undefined, true);
                            }
                        }
                    },
                    onKeyPress: e => {
                        if (e.key == 'Enter') {
                            onFinishEditing(currentAmount, currentCurrency || _user.primaryCurrency, undefined, true);
                        }
                    },
                }, theme: {
                    container: `ui input p-input-${location} display--flex flex--column ${currencyOptions.length > 1 ? 'smaller-width' : ''}`,
                    containerOpen: 'react-autosuggest__container--open',
                    input: 'react-autosuggest__input',
                    inputOpen: 'react-autosuggest__input--open',
                    inputFocused: 'react-autosuggest__input--focused',
                    suggestionsContainer: 'react-autosuggest__suggestions-container',
                    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                    suggestionsList: 'react-autosuggest__suggestions-list',
                    suggestion: 'react-autosuggest__suggestion',
                    suggestionFirst: 'react-autosuggest__suggestion--first',
                    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                    sectionContainer: 'react-autosuggest__section-container',
                    sectionContainerFirst: 'react-autosuggest__section-container--first',
                    sectionTitle: 'react-autosuggest__section-title',
                } })) : (React.createElement(semantic_ui_react_1.Input, { id: identifier, error: showError, className: `${currencyOptions.length > 1 ? 'smaller-width' : ''} p-input-${location.split('-')[0]}`, autoFocus: location === 'inline-edit' || location === 'button' || autoFocus, placeholder: currencyOptions.length > 1
                    ? 0
                    : isCrypto
                        ? (0, format_1.toCrypto)(0, currentCurrency)
                        : (0, format_1.toPrice)(0, currentCurrency), onChange: e => {
                    setDidValuesChange(true);
                    setCurrentAmount(e.target.value);
                    if (saveOnChange) {
                        onFinishEditing(e.target.value, currentCurrency || _user.primaryCurrency, undefined, true);
                    }
                }, onFocus: e => {
                    const target = e.target;
                    setTimeout(function () {
                        target.select(); // Select all text on focus
                    }, 50);
                }, value: currentAmount, onBlur: e => {
                    // if (location === 'button') {
                    //   onFinishEditing(currentAmount, currentCurrency)
                    //   onEnter()
                    //   setCurrentState(State.Default)
                    // }
                }, onKeyPress: e => {
                    if (e.key == 'Enter' && location === 'inline-edit') {
                        // Save value
                        onFinishEditing(currentAmount, currentCurrency);
                        e.target.blur();
                    }
                    else if (e.key == 'Enter' && onEnter) {
                        onEnter();
                        if (location === 'button') {
                            setCurrentState(State.Default);
                        }
                    }
                    else if (e.key == 'Enter' && blurOnEnter) {
                        e.target.blur();
                        if (location === 'button') {
                            setCurrentState(State.Default);
                        }
                    }
                } })),
            currencyOptions.length > 1 && (React.createElement(semantic_ui_react_1.Dropdown, { button: true, disabled: disableCurrency, search: true, selectOnNavigation: false, options: currencyOptions, className: `p-dropdown-${location.split('-')[0]} currency-dropdown no-hover`, value: currentCurrency || _user.primaryCurrency, onChange: (e, { value }) => {
                    setDidValuesChange(true);
                    setCurrentCurrency(value);
                    if (saveOnChange ||
                        (e['key'] == 'Enter' && location == 'inline-edit')) {
                        onFinishEditing(currentAmount, value, undefined, true);
                    }
                } })))),
        React.createElement(semantic_ui_react_1.Confirm, { open: showEditCryptoConfirm, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                setCurrentCurrency(_user.primaryCurrency);
                setShowEditCryptoConfirm(false);
                setCurrentState(State.Editing);
            }), header: 'Confirm editing crypto value', confirmButton: 'Continue Editing', size: 'tiny', onCancel: () => {
                setShowEditCryptoConfirm(false);
            }, cancelButton: 'Cancel', content: `Note: historical balances for crypto accounts may only be updated to fiat currencies.` })));
};
function useOnClickOutside(ref, handler) {
    (0, react_1.useEffect)(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]); // ... passing it into this hook. // ... but to optimize you can wrap handler in useCallback before ... // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
}
exports.default = EditablePrice;
