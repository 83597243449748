"use strict";
/**
 *  AccountRow.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const LineItem_1 = require("../global/LineItem");
const AccountRow = ({ account, loading, openConnect, openRelink, openModal, groupBy, setCollapsed = () => { }, isCollapsed = false, isParent = false, openTable, }) => {
    var _a, _b, _c;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _openModal = () => {
        openModal(account.source == 'plaid' &&
            (account.status == 'inactive' ||
                account.status == 'deleting' ||
                account.status == 'syncing')
            ? 'LINKED_ACCOUNT_INACTIVE'
            : 'LINKED_ACCOUNT_VIEW');
    };
    const getHeaderName = source => {
        if (source == 'manual') {
            return 'Manually-managed accounts';
        }
        else if (source == 'plaid') {
            return 'Automatic bank syncing via Plaid';
        }
        else if (source == 'synced-crypto') {
            return 'Synced cryptocurrency accounts';
        }
    };
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `padded-row ${account.status == 'processing' ? '' : 'clickable'} ${isParent ? 'highlighted' : ''}` },
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "cell-metadata", onClick: () => {
                if (isParent) {
                    setCollapsed();
                }
                else if (account.status !== 'processing' && !isParent) {
                    _openModal();
                }
            }, colSpan: isParent ? 10 : 1 },
            React.createElement("div", { className: "flex--space-between" },
                React.createElement("div", { className: "ellipsis" },
                    isParent && (React.createElement(semantic_ui_react_1.Icon, { style: { width: 'auto', marginRight: '0.7rem' }, name: isCollapsed ? 'caret right' : 'caret down' })),
                    React.createElement("span", null,
                        isParent
                            ? groupBy == 'institution'
                                ? account.institution_name || 'No institution'
                                : groupBy == 'connection'
                                    ? account.item_id
                                        ? `Connection: ${account.institution_name}`
                                        : 'Manually-managed accounts'
                                    : groupBy == 'source'
                                        ? getHeaderName(account[groupBy])
                                        : (0, format_1.capitalize)(account[groupBy])
                            : account.display_name,
                        isParent && ` (${account.accounts.length})`)),
                React.createElement("div", null,
                    React.createElement("span", { className: `ml-05rem secondary no-wrap text-label label-${account.type} display--block` }, isParent
                        ? ''
                        : account.type === 'depository'
                            ? 'Cash'
                            : account.type === 'cryptocurrency'
                                ? 'Crypto'
                                : (0, format_1.capitalize)(account.type))))),
        !isParent && React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align", onClick: () => {
                if (account.status !== 'processing' && !isParent) {
                    _openModal();
                }
            } }, !isParent && (React.createElement("div", null, account.status == 'active' || account.status == 'relink' ? (React.createElement("div", { className: "cell-metadata" },
            React.createElement("span", { className: "no-wrap primary" },
                ((!account.verified_balance && account.source == 'zabo') ||
                    (account.source == 'synced-crypto' &&
                        !account.hasOwnProperty('to_base')) ||
                    (Number(account.balance) !== 0 &&
                        account.to_base == null)) && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", { className: "mr-05rem" },
                        React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "exclamation triangle", color: "orange" })) }, "This cryptocurrency is not yet verified, which means we can't accurately map it to a cryptocurrency to get the market value or conversion rate. We can still display it, but this balance will not affect your net worth or other totals.")),
                account.type == 'cryptocurrency'
                    ? (0, format_1.toCrypto)(account.balance, account.currency)
                    : (0, format_1.toPrice)(account.balance, account.currency)),
            !!account.to_base &&
                account.currency !== _user.primaryCurrency &&
                account.hasOwnProperty('to_base') &&
                account.to_base !== null && (React.createElement("span", { className: "no-wrap secondary display--block" },
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { color: "yellow", name: "exchange", fitted: true }), inverted: true, size: "small" },
                    "Converted at ",
                    (0, format_1.toPrice)(1, account.currency),
                    " =",
                    ' ',
                    (0, format_1.toPrice)(parseFloat(account.to_base) /
                        parseFloat(account.balance), _user.primaryCurrency, 0.0001)),
                ' ',
                React.createElement("span", { className: "darker" }, (0, format_1.toPrice)(account.to_base, _user.primaryCurrency)))))) : ('-')))),
        !isParent && React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        !isParent && (React.createElement(semantic_ui_react_1.Table.Cell, { onClick: () => {
                if (account.status !== 'processing' && !isParent) {
                    _openModal();
                }
            } }, account.status == 'inactive' ? ('-') : account.source == 'plaid' ? (React.createElement(semantic_ui_react_1.Popup, { disabled: account.source !== 'plaid', trigger: React.createElement("span", { className: "no-wrap secondary display--block" },
                (0, format_1.capitalize)(Moment().to(Moment.max([
                    ...(account.last_cursor_at
                        ? [Moment(account.last_cursor_at)]
                        : []),
                    ...(account.last_refresh
                        ? [Moment(account.last_refresh)]
                        : []),
                    ...(account.last_fetch
                        ? [Moment(account.last_fetch)]
                        : []),
                    ...(account.last_import
                        ? [Moment(account.last_import)]
                        : []),
                ]))),
                account.source == 'plaid' && (React.createElement(semantic_ui_react_1.Icon, { name: "info circle", className: "ml-05rem color--grey" }))) },
            account.update_type == 'user_present_required' && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" },
                React.createElement("p", null,
                    "You have perpetual one-time passcode (POTP) enabled for your accounts at ",
                    account.institution_name,
                    ". You will need to manually trigger an update in order to fetch fresh data. Click for more details."))),
            React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Last fetch from server', propertyValue: account.last_cursor_at ||
                    account.last_refresh ||
                    account.last_fetch ||
                    account.last_import
                    ? Moment.max([
                        // ...(account.last_cursor_at
                        //   ? [Moment(account.last_cursor_at)]
                        //   : []),
                        // ...(account.last_refresh
                        //   ? [Moment(account.last_refresh)]
                        //   : []),
                        ...(account.last_fetch
                            ? [Moment(account.last_fetch)]
                            : []),
                        // ...(account.last_import
                        //   ? [Moment(account.last_import)]
                        //   : []),
                    ]).fromNow()
                    : 'never', inPopup: true }),
            (account.type == 'cash' || account.type == 'credit') && (React.createElement(LineItem_1.default, { keyClass: "no-label", keyValue: 'Last transaction update', propertyValue: account.last_cursor_at
                    ? Moment(account.last_cursor_at).fromNow()
                    : account.last_import
                        ? Moment(account.last_import).fromNow()
                        : 'never', inPopup: true })))) : (React.createElement("span", { className: "no-wrap secondary display--block" }, (0, format_1.capitalize)(Moment().to(Moment.max([
            ...(account.balance_as_of
                ? [Moment(account.balance_as_of)]
                : []),
            ...(account.last_import
                ? [Moment(account.last_import)]
                : []),
            ...(account.updated_at ? [Moment(account.updated_at)] : []),
        ]))))))),
        !isParent && React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        !isParent && (React.createElement(semantic_ui_react_1.Table.Cell, null,
            React.createElement("div", null,
                React.createElement("div", { className: "cell-metadata no-wrap display--block", onClick: () => {
                        if (isParent)
                            return;
                        if (account.source == 'zabo' &&
                            [
                                'Coinbase',
                                'Coinbase Pro',
                                'Binance',
                                'Ethereum',
                                'Kraken',
                            ].indexOf(account.institution_name) > -1) {
                            openConnect(account.institution_name);
                        }
                        else if (account.source == 'plaid' &&
                            account.status == 'relink') {
                            openRelink(account.id);
                        }
                        else {
                            _openModal();
                        }
                    } },
                    React.createElement("span", { className: `primary ${account.status === 'relink'
                            ? 'color--red'
                            : ((_a = account.access_token) === null || _a === void 0 ? void 0 : _a.indexOf('development')) > -1
                                ? 'color--yellow'
                                : account.status === 'active'
                                    ? 'color--green'
                                    : 'color--grey'}` },
                        account.source == 'plaid' &&
                            ((_b = account.access_token) === null || _b === void 0 ? void 0 : _b.indexOf('development')) == -1 && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "cloud download", className: "mr-05rem", color: account.status == 'active'
                                    ? 'green'
                                    : account.status == 'relink'
                                        ? 'red'
                                        : 'grey' }), inverted: true, size: "small" },
                            account.type == 'credit' || account.type == 'depository'
                                ? 'Balance and transactions are'
                                : 'Balance is',
                            ' ',
                            "set up to automatically sync from your bank",
                            account.status == 'active'
                                ? '.'
                                : ' but may not be actively fetching.',
                            account.status == 'relink'
                                ? ' Click to relink this connection.'
                                : '')),
                        account.source == 'zabo' ? ([
                            'Coinbase',
                            'Coinbase Pro',
                            'Binance',
                            'Ethereum',
                            'Kraken',
                        ].indexOf(account.institution_name) > -1 ? (React.createElement("span", { className: "clickable color--red hover--bold" }, loading ? (React.createElement(semantic_ui_react_1.Loader, { active: true, size: "mini", inline: true })) : (React.createElement("div", { onClick: () => {
                                openConnect(account.institution_name);
                            } },
                            React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", color: "yellow", fitted: true }),
                                ' ',
                                "Action needed"),
                            React.createElement("span", { className: "no-wrap secondary display--block" }, "Click to migrate connection"))))) : (React.createElement("span", { className: "clickable hover--bold" }, loading ? (React.createElement(semantic_ui_react_1.Loader, { active: true, size: "mini", inline: true })) : (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "info circle", color: "grey", fitted: true }),
                            " Not supported"))))) : ((_c = account.access_token) === null || _c === void 0 ? void 0 : _c.indexOf('development')) > -1 ? (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", className: "mr-0", fitted: true, color: 'yellow' }),
                            ' ',
                            "Action Required")) : (React.createElement(React.Fragment, null,
                            (0, format_1.capitalize)(account.status),
                            account.status == 'closed' &&
                                account.closed_on &&
                                ` on ${Moment(account.closed_on).format(_user.getMonthDayYearFormat())}`))))))),
        !isParent && React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        !isParent && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                openTable(account);
            } },
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "search plus", className: "color--grey clickable dark-grey-on-hover" }), size: "tiny", content: "View transactions (pop-up)", inverted: true }))),
        !isParent && React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        !isParent && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align", onClick: () => {
                if (account.status !== 'processing') {
                    _openModal();
                }
            } }, account.status !== 'processing' && (React.createElement(semantic_ui_react_1.Icon, { name: "angle right", className: `clickable green-on-hover color--grey`, onClick: _openModal }))))));
};
exports.default = AccountRow;
