"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLeftToBudget = exports.calculateTotalBudgeted = exports.calculateTotalAvailable = exports.calculateBudgetSuggestions = exports.calculateDifference = exports.handleBudgetResponse = exports.getClosestValidRolloverDate = exports.areDatesAligned = void 0;
const checks_1 = require("@helpers/checks");
const format_1 = require("@helpers/format");
const Moment = require("moment");
const _ = require("lodash");
const areDatesAligned = ({ date, anchorDate, granularityValue, quantityValue, }) => {
    return (Moment(date).diff(Moment(anchorDate), granularityValue, true) %
        quantityValue ==
        0);
};
exports.areDatesAligned = areDatesAligned;
const getClosestValidRolloverDate = ({ date, anchorDate, granularityValue, quantityValue, formatOverride = null, }) => {
    const anchorMoment = Moment(anchorDate);
    const dateMoment = Moment(date);
    const diff = dateMoment.diff(anchorMoment, granularityValue);
    const closestValidDate = anchorMoment
        .clone()
        .add(Math.round(diff / quantityValue) * quantityValue, granularityValue);
    return closestValidDate.format(formatOverride || 'YYYY-MM-DD');
};
exports.getClosestValidRolloverDate = getClosestValidRolloverDate;
const handleBudgetResponse = (_showToast, results) => {
    if (results.data.success == 0) {
        _showToast({
            message: `No budget was set.`,
            type: 'warning',
        });
    }
    else if (results.data.errors.length == 0) {
        _showToast({
            message: `Successfully set ${results.data.success} budget${results.data.success == 1 ? '' : 's'}.`,
            type: 'success',
        });
    }
    else {
        _showToast({
            message: `Successfully set ${results.data.success} budget${results.data.success == 1 ? '' : 's'}. ${results.data.errors.length} budget${results.data.errors.length == 1 ? '' : 's'} could not be set: ${results.data.errors.join(', ')}`,
            type: 'success',
        });
    }
};
exports.handleBudgetResponse = handleBudgetResponse;
const calculateDifference = (budget, round, isIncome) => {
    if (!(0, checks_1.isNull)(budget === null || budget === void 0 ? void 0 : budget.budget_to_base) &&
        ((budget === null || budget === void 0 ? void 0 : budget.spending_to_base) || (budget === null || budget === void 0 ? void 0 : budget.num_transactions) > 0)) {
        let a, b;
        if (isIncome) {
            a = budget.spending_to_base * -1;
            b = budget.budget_to_base;
        }
        else {
            a = budget.spending_to_base;
            b = budget.budget_to_base;
        }
        return (0, format_1.roundTo)(a, round) - (0, format_1.roundTo)(b, round);
    }
    else {
        return null;
    }
};
exports.calculateDifference = calculateDifference;
const calculateBudgetSuggestions = (allOccurrences, currentOccurrence, includeTypes = ['spend', 'budget', 'average-3', 'balance', 'current-spend'], properties) => {
    var _a;
    const suggestions = [];
    if (includeTypes.includes('left-to-budget')) {
        if (properties.left_to_budget > 0) {
            suggestions.push({
                type: 'left-to-budget',
                text: 'Left To Budget',
                amount: properties.left_to_budget + ((currentOccurrence === null || currentOccurrence === void 0 ? void 0 : currentOccurrence.budgeted) || 0),
            });
        }
    }
    if (includeTypes.includes('balance')) {
        if (currentOccurrence.available < 0) {
            suggestions.push({
                type: 'balance',
                text: 'Zero out available',
                amount: ((currentOccurrence === null || currentOccurrence === void 0 ? void 0 : currentOccurrence.budgeted) || 0) + currentOccurrence.available * -1,
            });
        }
    }
    if (includeTypes.includes('fixed')) {
        suggestions.push({
            type: 'fixed',
            text: 'Fixed amount',
            amount: properties.auto_budget.amount,
        });
    }
    if (includeTypes.includes('fill')) {
        const amount = properties.auto_budget.amount +
            (currentOccurrence.budgeted || 0) -
            (currentOccurrence.available || 0);
        if (!!amount) {
            suggestions.push({
                type: 'fill',
                text: `Fill to ${(0, format_1.toPrice)(properties.auto_budget.amount, properties.auto_budget.currency)}`,
                amount,
            });
        }
    }
    if (includeTypes.includes('current-spend')) {
        if (currentOccurrence.available < 0) {
            suggestions.push({
                type: 'current-spend',
                text: `This period's ${properties.category.is_income ? 'activity' : 'spend'}`,
                amount: currentOccurrence.activity + currentOccurrence.recurring_activity,
            });
        }
    }
    if (includeTypes.includes('spend')) {
        const lastPeriod = allOccurrences[_.findIndex(allOccurrences, o => o.start_date == currentOccurrence.start_date) - 1] || {};
        const amount = !!lastPeriod
            ? lastPeriod.activity + lastPeriod.recurring_activity
            : null;
        if (!!amount) {
            suggestions.push({
                type: 'spend',
                text: `Last period's ${properties.category.is_income ? 'activity' : 'spend'}`,
                amount: amount * (properties.category.is_income ? -1 : 1),
            });
        }
    }
    if (includeTypes.includes('budget')) {
        const amount = ((_a = (allOccurrences[_.findIndex(allOccurrences, o => o.start_date == currentOccurrence.start_date) - 1] || {})) === null || _a === void 0 ? void 0 : _a.budgeted) || null;
        if (!!amount) {
            suggestions.push({
                type: 'budget',
                text: `Last period's ${properties.category.is_income ? 'expected' : 'budgeted'}`,
                amount,
            });
        }
    }
    if (includeTypes.includes('average-3')) {
        const currentIndex = _.findIndex(allOccurrences, o => o.start_date === currentOccurrence.start_date);
        const amount = _.range(1, 4) // Iterate over the last 3 occurrences
            .map(offset => {
            var _a, _b;
            return (((_a = allOccurrences[currentIndex - offset]) === null || _a === void 0 ? void 0 : _a.activity) || 0) +
                (((_b = allOccurrences[currentIndex - offset]) === null || _b === void 0 ? void 0 : _b.recurring_activity) || 0);
        })
            .reduce((sum, activity) => sum + activity, 0) / 3;
        if (!!amount) {
            suggestions.push({
                type: 'average-3',
                text: `Avg ${properties.category.is_income ? 'activity' : 'spend'} last 3 periods`,
                amount: amount * (properties.category.is_income ? -1 : 1),
            });
        }
    }
    if (includeTypes.length == 1) {
        return suggestions[0];
    }
    else {
        return suggestions;
    }
};
exports.calculateBudgetSuggestions = calculateBudgetSuggestions;
const calculateTotalAvailable = budgets => {
    return budgets === null || budgets === void 0 ? void 0 : budgets.filter(o => !o.properties.category.is_group).reduce((acc, budget) => {
        return (acc +
            (budget.properties.budget_settings.rollover_option == 'same category'
                ? budget.totals.rollover_balance || 0
                : 0) +
            budget.totals.period_balance);
    }, 0);
};
exports.calculateTotalAvailable = calculateTotalAvailable;
const calculateTotalBudgeted = budgets => {
    return budgets === null || budgets === void 0 ? void 0 : budgets.reduce((acc, budget) => {
        if (budget.properties.historical) {
            // this makes no sense
            return acc;
        }
        if (budget.properties.category.is_child) {
            // Find the group category
            const parentIndex = _.findIndex(budgets, b => {
                return b.properties.category.id == budget.properties.category.group_id;
            });
            // Group category calculations should have come before, so its budgeted value should be set
            if (parentIndex > -1 && !!budgets[parentIndex].totals.budgeted) {
                return acc; // Don't add to it
            }
        }
        return acc + budget.totals.budgeted;
    }, 0);
};
exports.calculateTotalBudgeted = calculateTotalBudgeted;
const getLeftToBudget = budgets => {
    if (!budgets || !budgets.categories)
        return null;
    return (Math.round(((0, exports.calculateTotalAvailable)(budgets === null || budgets === void 0 ? void 0 : budgets.categories.filter(o => o.properties.category.is_income)) *
        -1 +
        (budgets['rollover_pool'] || 0) -
        (0, exports.calculateTotalBudgeted)(budgets === null || budgets === void 0 ? void 0 : budgets.categories.filter(o => !o.properties.category.is_income))) *
        100) / 100);
};
exports.getLeftToBudget = getLeftToBudget;
