"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const Moment = require("moment");
const EditableDate_1 = require("@components/elements/EditableDate");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const UserProvider_1 = require("@/providers/UserProvider");
const PeriodPicker = ({ showArrows = false, disabled = false, monthMinimum = false, monthPicker = false, firstValue = null, className = '', values = [], useSelectedDate = false, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [open, setOpen] = (0, react_1.useState)(false);
    const [preset, setPreset] = (0, react_1.useState)(firstValue || 'Last 6 months');
    const [buttonText, setButtonText] = (0, react_1.useState)(firstValue || 'Last 6 months');
    const _startDate = (0, react_1.useRef)(Moment().format('YYYY-MM-DD'));
    const _endDate = (0, react_1.useRef)(Moment().format('YYYY-MM-DD'));
    const PRESET_DATES = monthMinimum
        ? [...values, 'Last 12 months']
        : [
            'This month',
            'This year',
            'Month to date',
            'Year to date',
            'Last 7 days',
            'Last 30 days',
            'Last month',
            'Last 3 months',
            'Last 6 months',
            'Last 12 months',
            'Last year',
        ];
    (0, react_1.useEffect)(() => {
        const listener = event => {
            var _a;
            if ((_a = document.getElementById('period-picker')) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                return;
            }
            setOpen(false);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (firstValue) {
            applyCurrent({ date_range: firstValue });
        }
    }, [firstValue]);
    const applyCurrent = set => {
        if (set['date_range']) {
            setPreset(set['date_range']);
            if (set['date_range'] == 'This month') {
                _startDate.current = Moment()
                    .startOf('month')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'This year') {
                _startDate.current = Moment()
                    .startOf('year')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .endOf('year')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Month to date') {
                _startDate.current = Moment()
                    .startOf('month')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment().format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Year to date') {
                _startDate.current = Moment()
                    .startOf('year')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment().format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 7 days') {
                _startDate.current = Moment()
                    .subtract(6, 'days')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment().format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 14 days') {
                _startDate.current = Moment()
                    .subtract(13, 'days')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment().format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 30 days') {
                _startDate.current = Moment()
                    .subtract(29, 'days')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment().format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last month') {
                _startDate.current = Moment()
                    .subtract(1, 'months')
                    .startOf('month')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .subtract(1, 'months')
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 3 months') {
                _startDate.current = Moment()
                    .startOf('month')
                    .subtract(2, 'months')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 6 months') {
                _startDate.current = Moment()
                    .startOf('month')
                    .subtract(5, 'months')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last 12 months') {
                _startDate.current = Moment()
                    .startOf('month')
                    .subtract(11, 'months')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range'] == 'Last year') {
                _startDate.current = Moment()
                    .subtract(1, 'year')
                    .startOf('year')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .subtract(1, 'year')
                    .endOf('year')
                    .format('YYYY-MM-DD');
            }
            else if (set['date_range']) {
                _startDate.current = Moment()
                    .year(set['date_range'])
                    .startOf('year')
                    .format('YYYY-MM-DD');
                _endDate.current = Moment()
                    .year(set['date_range'])
                    .endOf('year')
                    .format('YYYY-MM-DD');
            }
            setButtonText(`${set['date_range']}: ${Moment(_startDate.current).format(_user.getMonthDayYearFormat())} to ${Moment(_endDate.current).format(_user.getMonthDayYearFormat())}`);
        }
        else if (set['end_date'] && set['start_date']) {
            setPreset('Custom');
            _endDate.current = set['end_date'];
            _startDate.current = set['start_date'];
            setButtonText(`${Moment(set['start_date']).format(_user.getMonthDayYearFormat())} to ${Moment(set['end_date']).format(_user.getMonthDayYearFormat())}`);
        }
        else if (set['start_date']) {
            setPreset('Custom');
            _startDate.current = set['start_date'];
            _endDate.current = Moment().format('YYYY-MM-DD');
            setButtonText(`${Moment(set['start_date']).format(_user.getMonthDayYearFormat())} to now`);
        }
    };
    (0, react_1.useEffect)(() => {
        const opts = qs.parse(history.location.search);
        if (opts.opts) {
            // Analyze
            const datasets = opts['opts'].split(',');
            datasets.forEach((data, index) => {
                const set = qs.parse(data);
                applyCurrent(set);
            });
        }
        else {
            // Overview
            applyCurrent(opts);
        }
    }, [history.location]);
    const changePeriod = (presetOverride = null) => {
        const _preset = presetOverride || preset;
        // Construct new date object
        const dateObj = {};
        if (_preset == 'Custom') {
            if (_startDate.current) {
                // End date can be null, in which case it should default to today
                dateObj['start_date'] = _startDate.current;
                dateObj['end_date'] =
                    _endDate.current ||
                        (useSelectedDate
                            ? Moment().format('YYYY-MM-DD')
                            : Moment()
                                .endOf('month')
                                .format('YYYY-MM-DD'));
            }
            else {
                dateObj['start_date'] = Moment()
                    .startOf('month')
                    .subtract(6, 'months')
                    .format('YYYY-MM-DD');
                dateObj['end_date'] =
                    _endDate.current ||
                        Moment()
                            .endOf('month')
                            .format('YYYY-MM-DD');
            }
            const days = Moment(dateObj['end_date'], 'YYYY-MM-DD').diff(Moment(dateObj['start_date'], 'YYYY-MM-DD'), 'days');
            if (days <= 10) {
                dateObj['time_granularity'] = 'day';
            }
            else {
                dateObj['time_granularity'] = 'month';
            }
        }
        else {
            // setButtonText(_preset)
            dateObj['date_range'] = _preset;
            if (_preset == 'Last 7 days') {
                dateObj['time_granularity'] = 'day';
            }
            else if (_preset == 'Last 30 days') {
                dateObj['time_granularity'] = 'week';
            }
            else {
                dateObj['time_granularity'] = 'month';
            }
        }
        // Add to existing query
        const opts = qs.parse(history.location.search);
        if (history.location.pathname.indexOf('analyze') > -1) {
            const datasets = opts['opts'].split(',');
            const final = datasets.map((data, index) => {
                const set = qs.parse(data);
                if (_preset == 'Custom') {
                    delete set['date_range'];
                }
                else {
                    delete set['start_date'];
                    delete set['end_date'];
                }
                return qs.stringify(Object.assign(Object.assign({}, set), dateObj));
            });
            history.push({
                path: '/analyze',
                search: qs.stringify({
                    opts: final,
                }, { arrayFormat: 'comma' }),
            });
        }
        else if (history.location.pathname.indexOf('overview') > -1) {
            delete dateObj['time_granularity'];
            history.push({
                path: '/overview',
                search: qs.stringify(dateObj),
            });
        }
        else if (history.location.pathname.indexOf('stats') > -1 ||
            history.location.pathname.indexOf('trends') > -1) {
            delete dateObj['time_granularity'];
            history.push({
                path: '/stats',
                search: qs.stringify(dateObj),
            });
        }
        else if (history.location.pathname.indexOf('budget') > -1) {
            delete dateObj['time_granularity'];
            history.push({
                search: qs.stringify(dateObj),
            });
        }
        setOpen(false);
    };
    return (React.createElement(semantic_ui_react_1.Popup, { on: 'click', basic: true, style: { marginTop: '4px' }, open: open && !disabled, position: "bottom left", className: "no-padding period-picker", trigger: showArrows &&
            (preset == 'This month' ||
                preset == 'Month to date' ||
                preset == 'Last month' ||
                preset == 'Custom') ? (
        // &&
        // Moment(_startDate.current).month() ==
        //   Moment(_endDate.current).month()
        React.createElement("div", { className: `display--flex dropdown-mimic` },
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("div", { className: "clickable left-arrow", onClick: () => {
                        // Is start date the first of something and end date the last day of something?
                        if (Moment(_startDate.current).date() == 1 &&
                            Moment(_endDate.current).date() ==
                                Moment(_endDate.current).daysInMonth()) {
                            // Get the difference in months
                            const diffMonths = Moment(_endDate.current).diff(Moment(_startDate.current), 'months');
                            _startDate.current = Moment(_startDate.current)
                                .startOf('month')
                                .subtract(diffMonths + 1, 'month')
                                .format('YYYY-MM-DD');
                            _endDate.current = Moment(_endDate.current)
                                .subtract(diffMonths + 1, 'month')
                                .endOf('month')
                                .format('YYYY-MM-DD');
                        }
                        else {
                            // Get the difference in days
                            const diffDays = Moment(_endDate.current).diff(Moment(_startDate.current), 'days') + 1;
                            _startDate.current = Moment(_startDate.current)
                                .subtract(diffDays, 'days')
                                .format('YYYY-MM-DD');
                            _endDate.current = Moment(_endDate.current)
                                .subtract(diffDays, 'days')
                                .format('YYYY-MM-DD');
                        }
                        changePeriod('Custom');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "caret left" })), mouseEnterDelay: 1000, size: "mini", inverted: true }, "Previous month"),
            React.createElement("div", { className: `main-dropdown ${disabled ? 'disabled' : ''} ${className}`, onClick: () => {
                    setOpen(!open);
                } },
                React.createElement("span", null,
                    disabled ? (React.createElement(semantic_ui_react_1.Icon, { loading: true, name: "spinner", style: { marginTop: '4px' } })) : (React.createElement(semantic_ui_react_1.Icon, { name: "calendar alternate outline" })),
                    ' ',
                    buttonText)),
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("div", { className: "clickable right-arrow", onClick: () => {
                        // Is start date the first of something and end date the last day of something?
                        if (Moment(_startDate.current).date() == 1 &&
                            Moment(_endDate.current).date() ==
                                Moment(_endDate.current).daysInMonth()) {
                            // Get the difference in months
                            const diffMonths = Moment(_endDate.current).diff(Moment(_startDate.current), 'months');
                            console.log(diffMonths);
                            _startDate.current = Moment(_startDate.current)
                                .startOf('month')
                                .add(diffMonths + 1, 'month')
                                .format('YYYY-MM-DD');
                            _endDate.current = Moment(_endDate.current)
                                .add(diffMonths + 1, 'month')
                                .endOf('month')
                                .format('YYYY-MM-DD');
                        }
                        else {
                            // Get the difference in days
                            const diffDays = Moment(_endDate.current).diff(Moment(_startDate.current), 'days') + 1;
                            _startDate.current = Moment(_startDate.current)
                                .add(diffDays, 'days')
                                .format('YYYY-MM-DD');
                            _endDate.current = Moment(_endDate.current)
                                .add(diffDays, 'days')
                                .format('YYYY-MM-DD');
                        }
                        changePeriod('Custom');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "caret right" })), inverted: true, size: "mini", mouseEnterDelay: 1000 }, "Next month"))) : (React.createElement("div", { className: `dropdown-mimic mr-05rem ${disabled ? 'disabled' : ''} ${className}`, onClick: () => {
                setOpen(!open);
            } },
            React.createElement("div", { className: "main-dropdown" },
                disabled ? (React.createElement(semantic_ui_react_1.Icon, { loading: true, name: "spinner", style: { marginTop: '4px' } })) : (React.createElement(semantic_ui_react_1.Icon, { name: "calendar alternate outline" })),
                ' ',
                buttonText))) },
        React.createElement("div", { id: "period-picker" },
            React.createElement("div", { className: "period-presets" }, PRESET_DATES.map(range => {
                return (React.createElement("div", { key: `preset-${range}`, className: `flex--space-between-flex-start period-option ${preset == range ? 'selected' : ''}`, onClick: () => {
                        setPreset(range);
                        if (range !== 'Custom') {
                            _startDate.current = null;
                            _endDate.current = null;
                            changePeriod(range);
                        }
                    } },
                    React.createElement("span", null, range),
                    preset == range && React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" })));
            })),
            React.createElement("div", { className: `flex--space-between-flex-start period-option custom ${preset == 'Custom' ? 'selected' : ''}` },
                React.createElement("span", null, "Custom"),
                preset == 'Custom' && React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "checkmark" })),
            React.createElement(semantic_ui_react_1.Form, { className: "padding-1rem" },
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0 custom-picker" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "From"),
                        React.createElement(EditableDate_1.default, { identifier: "from-query", firstValue: preset == 'Custom' ? _startDate.current : undefined, state: 'Editing', yearPicker: true, monthPicker: monthPicker, location: 'modal', format: monthPicker ? 'month_year' : 'month_day_year', onSave: start_date => {
                                _startDate.current = start_date;
                            } })),
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "To"),
                        React.createElement(EditableDate_1.default, { identifier: "to-query", firstValue: preset == 'Custom' ? _endDate.current : undefined, placeholder: "now", state: 'Editing', yearPicker: true, monthPicker: monthPicker, location: 'modal', format: monthPicker ? 'month_year' : 'month_day_year', onSave: end_date => {
                                if (end_date) {
                                    if (useSelectedDate) {
                                        _endDate.current = Moment(end_date).format('YYYY-MM-DD');
                                    }
                                    else {
                                        _endDate.current = Moment(end_date)
                                            .endOf('month')
                                            .format('YYYY-MM-DD');
                                    }
                                }
                                else {
                                    _endDate.current = null;
                                }
                            } }))),
                _startDate.current &&
                    ((!!_endDate.current &&
                        Moment(_startDate.current).isBefore(Moment(_endDate.current))) ||
                        _endDate.current == null) && (React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem mb-05rem", onClick: () => {
                        changePeriod('Custom');
                    }, size: "mini", fluid: true }, "Apply Changes")),
                _startDate.current &&
                    _endDate.current &&
                    Moment(_startDate.current).isAfter(Moment(_endDate.current)) && (React.createElement("span", { className: "color--red mt-05rem" }, "Start date must be before end date!"))))));
};
exports.default = PeriodPicker;
