"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const checks_1 = require("@helpers/checks");
const budgets_1 = require("@actions/budgets");
const StatusBar_1 = require("./StatusBar");
const react_1 = require("react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const BudgetSummary = ({ start_date, end_date, displayCurrency, showAsPercentage, budgetColors, setTriggerRefresh, triggerRefresh, showTimeProgress, flipSign, allowRecurringCategories, }) => {
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [expenses, setExpenses] = (0, react_1.useState)([]);
    const [expensesUI, setExpensesUI] = (0, react_1.useState)([]);
    const [excludedExpensesUI, setExcludedExpensesUI] = (0, react_1.useState)([]);
    const [expensesTotal, setExpensesTotal] = (0, react_1.useState)(0);
    const [uncategorizedExpensesTotal, setUncategorizedExpensesTotal] = (0, react_1.useState)(0);
    const [uncategorizedIncomeTotal, setUncategorizedIncomeTotal] = (0, react_1.useState)(0);
    const [income, setIncome] = (0, react_1.useState)([]);
    const [incomeUI, setIncomeUI] = (0, react_1.useState)([]);
    const [excludedIncomeUI, setExcludedIncomeUI] = (0, react_1.useState)([]);
    const [incomeTotal, setIncomeTotal] = (0, react_1.useState)(0);
    const [recurring, setRecurring] = (0, react_1.useState)(null);
    const [uncategorizedExpenses, setUncategorizedExpenses] = (0, react_1.useState)(null);
    const [uncategorizedIncome, setUncategorizedIncome] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const fetchBudgetSummary = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        try {
            const results = yield (0, budgets_1.getBudget)({
                start_date,
                end_date,
                currency: displayCurrency,
            });
            const history = results.transformed;
            const budgets = [...(_a = history === null || history === void 0 ? void 0 : history.data) === null || _a === void 0 ? void 0 : _a.inflow, ...(_b = history === null || history === void 0 ? void 0 : history.data) === null || _b === void 0 ? void 0 : _b.outflow].map(budget => {
                var _a, _b, _c, _d;
                return {
                    category_id: budget.category_id,
                    category_name: _categories.getName(budget.category_id),
                    exclude_from_budget: budget.exclude_from_budget,
                    exclude_from_totals: budget.exclude_from_totals,
                    group_id: budget.group_id,
                    is_group: budget.is_group,
                    is_income: budget.is_income,
                    num_transactions: ((_a = budget.data[start_date]) === null || _a === void 0 ? void 0 : _a.num_transactions) || 0,
                    budgeted: ((_b = budget.data[start_date]) === null || _b === void 0 ? void 0 : _b.budget_to_base) || null,
                    sum: ((_c = budget.data[start_date]) === null || _c === void 0 ? void 0 : _c.spending_to_base) +
                        ((_d = budget.data[start_date]) === null || _d === void 0 ? void 0 : _d.recurring_actual_to_base) || null,
                };
            });
            if (!history.error) {
                // Sort results.summary by category name alphabetically
                setIncome(budgets.filter(o => o.category_id && o.is_income));
                setExpenses(budgets.filter(o => o.category_id && !o.is_income));
                setExpensesTotal((_c = history === null || history === void 0 ? void 0 : history.totals) === null || _c === void 0 ? void 0 : _c.expenses);
                setIncomeTotal((_d = history === null || history === void 0 ? void 0 : history.totals) === null || _d === void 0 ? void 0 : _d.income);
                setUncategorizedExpensesTotal((_e = history === null || history === void 0 ? void 0 : history.totals) === null || _e === void 0 ? void 0 : _e.uncategorized_expenses);
                setUncategorizedIncomeTotal((_f = history === null || history === void 0 ? void 0 : history.totals) === null || _f === void 0 ? void 0 : _f.uncategorized_income);
                setUncategorizedExpenses(budgets.find(o => o.category_id == null && !o.is_income));
                setUncategorizedIncome(budgets.find(o => o.category_id == null && o.is_income));
                setRecurring({
                    budgeted_amount: null,
                    category_id: -2,
                    category_name: 'Recurring',
                    exclude_from_budget: true,
                    expense: (_g = history === null || history === void 0 ? void 0 : history.totals) === null || _g === void 0 ? void 0 : _g.actual_recurring_expenses,
                    income: (_h = history === null || history === void 0 ? void 0 : history.totals) === null || _h === void 0 ? void 0 : _h.actual_recurring_income,
                });
                setIsLoading(false);
            }
        }
        catch (e) {
            console.log('Error loading budget summary', e);
        }
    });
    (0, react_1.useEffect)(() => {
        if (triggerRefresh > 1) {
            fetchBudgetSummary();
        }
    }, [triggerRefresh]);
    (0, react_1.useEffect)(() => {
        if (!expenses || expenses.length == 0) {
            setExpensesUI([]);
            setExcludedExpensesUI([]);
            setIsLoading(false);
            return;
        }
        try {
            const excludedCategories = [];
            const _expenses = expenses.map((budget, index) => {
                if (!budget.sum) {
                    return null;
                }
                if (!budget.exclude_from_totals) {
                    return (React.createElement(StatusBar_1.default, { key: `budget-summary-expense-${index}`, numerator: budget.sum, denominator: budget.budgeted, isGroup: budget.is_group, inGroup: budget.group_id, isIncome: budget.is_income, label: budget.category_name, showTimeProgress: showTimeProgress, currency: displayCurrency, budgetColors: budgetColors, showBar: !budget.exclude_from_budget && budget.budgeted !== null, showPercentage: !budget.exclude_from_budget && budget.budgeted !== null, showAsPercentage: showAsPercentage, link: { category: budget.category_id }, flipSign: flipSign }));
                }
                else {
                    excludedCategories.push(React.createElement(StatusBar_1.default, { key: `budget-summary-expense-${index}`, numerator: budget.sum, denominator: budget.budgeted, isGroup: budget.is_group, inGroup: budget.group_id, isIncome: budget.is_income, label: budget.category_name, showTimeProgress: showTimeProgress, currency: displayCurrency, budgetColors: budgetColors, showBar: !budget.exclude_from_budget && budget.budgeted !== null, showPercentage: !budget.exclude_from_budget && budget.budgeted !== null, showAsPercentage: false, link: { category: budget.category_id }, flipSign: flipSign, isExcluded: true }));
                    return null;
                }
            });
            setExpensesUI(_expenses.filter(o => !!o));
            setExcludedExpensesUI(excludedCategories);
        }
        catch (e) {
            console.log('Error generating budget rows', e);
        }
    }, [expenses, budgetColors, showTimeProgress, flipSign, showAsPercentage]);
    (0, react_1.useEffect)(() => {
        if (!income || income.length == 0) {
            setIncomeUI([]);
            setIsLoading(false);
            return;
        }
        try {
            const excludedCategories = [];
            const _income = income.map((budget, index) => {
                if (!budget.sum) {
                    return null;
                }
                if (!budget.exclude_from_totals) {
                    return (React.createElement(StatusBar_1.default, { key: `budget-summary-income-${index}`, numerator: budget.sum, denominator: budget.budgeted, isGroup: budget.is_group, inGroup: budget.group_id, isIncome: true, label: budget.category_name, showTimeProgress: showTimeProgress, currency: displayCurrency, budgetColors: budgetColors, showBar: !budget.exclude_from_budget && budget.budgeted !== null, showPercentage: !budget.exclude_from_budget && budget.budgeted !== null, showAsPercentage: showAsPercentage, link: { category: budget.category_id }, flipSign: flipSign }));
                }
                else {
                    excludedCategories.push(React.createElement(StatusBar_1.default, { key: `budget-summary-income-${index}`, numerator: budget.sum, denominator: budget.budgeted, isGroup: budget.is_group, inGroup: budget.group_id, isIncome: true, label: budget.category_name, showTimeProgress: showTimeProgress, currency: displayCurrency, budgetColors: budgetColors, showBar: !budget.exclude_from_budget && budget.budgeted !== null, showPercentage: !budget.exclude_from_budget && budget.budgeted !== null, showAsPercentage: showAsPercentage, link: { category: budget.category_id }, flipSign: flipSign, isExcluded: true }));
                    return null;
                }
            });
            setIncomeUI(_income.filter(o => !!o));
            setExcludedIncomeUI(excludedCategories);
        }
        catch (e) {
            console.log('Error generating budget rows', e);
        }
    }, [income, budgetColors, showTimeProgress, flipSign, showAsPercentage]);
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }))),
        (excludedIncomeUI.length > 0 ||
            incomeUI.length > 0 ||
            (uncategorizedIncome === null || uncategorizedIncome === void 0 ? void 0 : uncategorizedIncome.sum) > 0 ||
            (!allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.income))) &&
            !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("p", { className: "label-style mb-05rem center-align" }, "Income"),
            incomeUI,
            !allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.income) ? (React.createElement(StatusBar_1.default, { key: `budget-summary-recurring-income`, numerator: recurring.income, denominator: null, label: 'Recurring Income', isIncome: true, showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, noMarginBottom: (uncategorizedIncome === null || uncategorizedIncome === void 0 ? void 0 : uncategorizedIncome.sum) == 0, link: { recurring: 'any' }, flipSign: flipSign })) : (React.createElement(React.Fragment, null)),
            !!(uncategorizedIncome === null || uncategorizedIncome === void 0 ? void 0 : uncategorizedIncome.sum) && (React.createElement(StatusBar_1.default, { key: `budget-summary-uncategorized`, numerator: uncategorizedIncome.sum, denominator: uncategorizedIncome.budgeted, label: 'Uncategorized', isIncome: true, showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, link: { uncategorized: true }, flipSign: flipSign, showWarning: true, noMarginBottom: true })),
            !(0, checks_1.isNull)(incomeTotal) &&
                (incomeUI.length > 1 ||
                    (incomeUI.length == 1 &&
                        !allowRecurringCategories &&
                        (recurring === null || recurring === void 0 ? void 0 : recurring.income))) ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "divider" }),
                React.createElement(StatusBar_1.default, { key: `budget-summary-total`, numerator: incomeTotal +
                        ((recurring === null || recurring === void 0 ? void 0 : recurring.income) || 0) +
                        uncategorizedIncomeTotal, denominator: null, label: 'Income Total', isIncome: true, showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, showAsPercentage: showAsPercentage, link: null, flipSign: flipSign, noMarginBottom: true }))) : (React.createElement(React.Fragment, null)),
            excludedIncomeUI.length > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "divider" }),
                excludedIncomeUI)) : (React.createElement(React.Fragment, null)))) : (React.createElement(React.Fragment, null)),
        (excludedExpensesUI.length > 0 ||
            expensesUI.length > 0 ||
            !!(uncategorizedExpenses === null || uncategorizedExpenses === void 0 ? void 0 : uncategorizedExpenses.sum) ||
            (!allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.expense))) &&
            !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement("p", { className: "label-style mb-05rem center-align" }, "Expenses"),
            expensesUI.length > 0 ||
                (!allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.expense)) ? (React.createElement(React.Fragment, null,
                expensesUI,
                !allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.expense) ? (React.createElement(StatusBar_1.default, { key: `budget-summary-recurring-expense`, numerator: recurring.expense, denominator: null, label: 'Recurring Expenses', showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, noMarginBottom: (uncategorizedExpenses === null || uncategorizedExpenses === void 0 ? void 0 : uncategorizedExpenses.sum) == 0, link: { recurring: 'any' }, flipSign: flipSign })) : (React.createElement(React.Fragment, null)))) : null,
            !!(uncategorizedExpenses === null || uncategorizedExpenses === void 0 ? void 0 : uncategorizedExpenses.sum) && (React.createElement(StatusBar_1.default, { key: `budget-summary-uncategorized`, numerator: uncategorizedExpenses.sum, denominator: uncategorizedExpenses.budgeted, label: 'Uncategorized', showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, link: { uncategorized: true }, flipSign: flipSign, showWarning: true, noMarginBottom: true })),
            !(0, checks_1.isNull)(expensesTotal) &&
                expensesUI.length > 0 &&
                (allowRecurringCategories || (recurring === null || recurring === void 0 ? void 0 : recurring.expense)) ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: "divider" }),
                React.createElement(StatusBar_1.default, { key: `budget-summary-total`, numerator: expensesTotal +
                        ((recurring === null || recurring === void 0 ? void 0 : recurring.expense) || 0) +
                        uncategorizedExpensesTotal, denominator: null, label: `Expenses Total`, showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, showPercentage: false, showAsPercentage: showAsPercentage, link: null, flipSign: flipSign, noMarginBottom: true }))) : (React.createElement(React.Fragment, null)),
            excludedExpensesUI.length > 0 && (React.createElement(React.Fragment, null,
                !(0, checks_1.isNull)(expensesTotal) &&
                    (expensesUI.length > 1 ||
                        (!allowRecurringCategories && (recurring === null || recurring === void 0 ? void 0 : recurring.expense))) ? (React.createElement("p", { className: "divider" })) : (React.createElement(React.Fragment, null)),
                excludedExpensesUI)))) : (React.createElement(React.Fragment, null)),
        (expensesUI.length > 0 ||
            incomeUI.length > 0 ||
            uncategorizedExpensesTotal ||
            uncategorizedIncomeTotal ||
            ((recurring === null || recurring === void 0 ? void 0 : recurring.income) && (recurring === null || recurring === void 0 ? void 0 : recurring.expense))) &&
            !isLoading ? (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(StatusBar_1.default, { key: `budget-summary-total`, numerator: (incomeTotal || 0) +
                    (expensesTotal || 0) +
                    (uncategorizedExpensesTotal || 0) +
                    (uncategorizedIncomeTotal || 0) +
                    ((recurring === null || recurring === void 0 ? void 0 : recurring.income) || 0) +
                    ((recurring === null || recurring === void 0 ? void 0 : recurring.expense) || 0), denominator: null, label: 'Net Income', showTimeProgress: showTimeProgress, currency: displayCurrency, showBar: false, noMarginBottom: true, showPercentage: false, showAsPercentage: false, link: null, flipSign: flipSign }))) : (React.createElement(React.Fragment, null)),
        expensesUI.length === 0 && incomeUI.length === 0 && !isLoading && (React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(react_router_dom_1.Link, { to: { pathname: `/categories` } },
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }),
                " Set up categories")))));
};
exports.default = BudgetSummary;
