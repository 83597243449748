"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  DeleteSyncedCryptoModal.tsx
 */
const _ = require("lodash");
const React = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_1 = require("react");
const crypto_1 = require("@actions/crypto");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const DeleteSyncedCryptoModal = ({ data, useModal, utils, setVisibility, switchView, }) => {
    var _a, _b;
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [step, setStep] = (0, react_1.useState)(1);
    const [removeOne, setRemoveOne] = (0, react_1.useState)(null);
    const [keepHistory, setKeepHistory] = (0, react_1.useState)(null);
    const [siblings, setSiblings] = (0, react_1.useState)([]);
    // Merging
    const [selected, setSelected] = (0, react_1.useState)(null);
    const [overrideSrc, setOverrideSrc] = (0, react_1.useState)(null);
    const cryptoById = _.groupBy(_assets.syncedCrypto, 'id');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const _siblings = _assets.getSyncedCryptoSiblings(data.account.id);
        setSiblings(_siblings);
        if (_siblings.length == 1) {
            setRemoveOne(false);
            setStep(2);
        }
        else {
            setStep(1);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('LINKED_ACCOUNT_VIEW');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Account Details"),
                React.createElement("span", { className: "header-text-large" }, "Remove Crypto Account"))),
            middleButton: (step > 1 && siblings.length > 1) ||
                (step > 2 && siblings.length == 1) ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step == 4 && (!keepHistory || removeOne)) {
                        setStep(2);
                    }
                    else {
                        setStep(step - 1);
                    }
                }) }, "Back")) : (React.createElement(React.Fragment, null)),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: (removeOne === null && step == 1) ||
                    (keepHistory == null && step == 2) ||
                    ((selected == null || overrideSrc == null) && step == 3), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step < 4) {
                        if (step == 2 && (!keepHistory || removeOne)) {
                            setStep(4);
                        }
                        else {
                            setStep(step + 1);
                        }
                    }
                    else {
                        setIsLoading(true);
                        let results = null;
                        if (removeOne) {
                            // Remove only one, update the exclusion array
                            results = yield utils._process(crypto_1.updateSynced)(data.account.id, {
                                excluded_currencies: [data.account.currency],
                                keep_history: keepHistory,
                            });
                        }
                        else {
                            // Remove entire connection
                            results = yield utils._process(crypto_1.removeSynced)(data.account.id, {
                                keep_history: keepHistory,
                                dest_crypto_account_id: selected ? Number(selected) : null,
                                override_src: overrideSrc,
                            });
                        }
                        if (!results.error) {
                            _assets.fetchAssets();
                            setIsLoading(false);
                            setVisibility(false);
                            utils._showToast({
                                message: 'Successfully removed cryptocurrency account!',
                                type: 'success',
                            });
                        }
                        return;
                    }
                }) }, step == 4 ? 'Save changes' : 'Next')),
        });
        return () => {
            useModal({
                header: null,
                rightButton: null,
                leftButton: null,
                middleButton: null,
            });
        };
    }, [
        step,
        data,
        siblings,
        removeOne,
        keepHistory,
        isLoading,
        selected,
        overrideSrc,
    ]);
    return step == 1 ? (React.createElement(React.Fragment, null,
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setRemoveOne(true);
            }), className: `link-recurring-row ${removeOne == true ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("span", null,
                    "I want to stop syncing",
                    ' ',
                    React.createElement("b", null, data.account.display_name || data.account.name),
                    " balance only")),
            removeOne ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setRemoveOne(false);
            }), className: `link-recurring-row ${removeOne == false ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("span", null,
                    "I want to remove this ",
                    data.account.institution_name,
                    " connection, along with all accounts:"),
                React.createElement("ul", { className: "mt-05rem mb-0" }, siblings.map((account, index) => {
                    return (React.createElement("li", { key: `siblings-${index}` },
                        React.createElement("b", null, account.display_name || account.name)));
                }))),
            removeOne == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))) : step == 2 ? (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "There may be balance history associated with",
            ' ',
            removeOne
                ? data.account.display_name || data.account.name
                : data.account.institution_name,
            ' ',
            "for calculating your net worth over time."),
        React.createElement("p", null, "Would you like to keep the balance history on this account, or remove it completely?"),
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                var _c;
                if (removeOne ||
                    ((_c = _assets.syncedCrypto.filter(o => o.id !== data.account.id &&
                        o.institution_name == data.account.institution_name)) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                    setKeepHistory(true);
                }
            }), className: `link-recurring-row 
            ${removeOne ||
                ((_a = _assets.syncedCrypto.filter(o => o.id !== data.account.id &&
                    o.institution_name == data.account.institution_name)) === null || _a === void 0 ? void 0 : _a.length) > 0
                ? ''
                : 'disabled'}
          ${keepHistory == true ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("p", null,
                    "I want to keep",
                    ' ',
                    React.createElement("b", null, removeOne
                        ? data.account.display_name || data.account.name
                        : data.account.institution_name),
                    "'s balance history."),
                !removeOne &&
                    ((_b = _assets.syncedCrypto.filter(o => o.id !== data.account.id &&
                        o.institution_name == data.account.institution_name)) === null || _b === void 0 ? void 0 : _b.length) == 0 && (React.createElement("p", null,
                    "(Not supported. You need another active",
                    ' ',
                    data.account.institution_name,
                    " connection to merge this connection's data."))),
            keepHistory ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
        React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setKeepHistory(false);
            }), className: `link-recurring-row ${keepHistory == false ? 'selected' : ''}` },
            React.createElement("div", null,
                React.createElement("span", null,
                    "I want to remove",
                    ' ',
                    React.createElement("b", null, removeOne
                        ? data.account.display_name || data.account.name
                        : data.account.institution_name),
                    "'s balance history.")),
            keepHistory == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))) : step == 3 ? (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "You may preserve the balance history by merging with another synced crypto account. Select the ",
            data.account.institution_name,
            " connection you want to merge balance history with:"),
        Object.keys(cryptoById).map((synced_crypto_id, index) => {
            if (synced_crypto_id == data.account.id) {
                return null;
            }
            return (React.createElement("div", { key: `synced_crypto_${index}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setSelected(synced_crypto_id);
                }), className: `link-recurring-row ${selected == synced_crypto_id ? 'selected' : ''}` },
                React.createElement("div", null,
                    React.createElement("span", null,
                        cryptoById[synced_crypto_id][0].institution_name,
                        ":",
                        ' ',
                        cryptoById[synced_crypto_id].length,
                        " coins found (",
                        cryptoById[synced_crypto_id]
                            .slice(0, 10)
                            .map(o => o.currency.toUpperCase())
                            .join(', '),
                        cryptoById[synced_crypto_id].length > 10 ? ', ...' : '',
                        " linked on",
                        ' ',
                        Moment(cryptoById[synced_crypto_id][0].created_at).format(_user.getFormat('month_day_year')),
                        ")")),
                selected == synced_crypto_id ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
        }),
        selected && (React.createElement(React.Fragment, null,
            React.createElement("p", null, "Where possible, we will merge this connection's balance history with the connection selected above. In case of a conflict, which balance history should be save?"),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setOverrideSrc(true);
                }), className: `link-recurring-row ${overrideSrc ? 'selected' : ''}` },
                React.createElement("div", null,
                    React.createElement("span", null, "Choose the current connection's balance history")),
                overrideSrc ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))),
            React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setOverrideSrc(false);
                }), className: `link-recurring-row ${overrideSrc == false ? 'selected' : ''}` },
                React.createElement("div", null,
                    React.createElement("span", null, "Choose the above selected connection's balance history")),
                overrideSrc == false ? (React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))))))) : step == 4 ? (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Please confirm the following operations:"),
        React.createElement("div", { className: `not-clickable link-recurring-row selected` },
            React.createElement("div", null, siblings.length > 1 && !removeOne ? (React.createElement("span", null,
                "Remove all account(s) under ",
                data.account.institution_name,
                ", including:",
                React.createElement("ul", { className: "mt-05rem mb-0" }, siblings.map((account, index) => {
                    return (React.createElement("li", { key: `siblings-${index}` },
                        React.createElement("b", null, account.display_name || account.name)));
                })))) : (React.createElement("span", null,
                "Stop syncing",
                ' ',
                siblings.length > 1 && !removeOne ? 'all accounts with' : '',
                React.createElement("b", null, removeOne
                    ? data.account.display_name || data.account.name
                    : data.account.institution_name)))),
            React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
        React.createElement("div", { className: `not-clickable link-recurring-row selected` },
            React.createElement("div", null, keepHistory ? (React.createElement("span", null,
                "Keep",
                ' ',
                React.createElement("b", null, removeOne
                    ? data.account.display_name || data.account.name
                    : data.account.institution_name),
                ' ',
                "balance history",
                removeOne
                    ? ''
                    : ` and on conflict, choose ${overrideSrc
                        ? 'the current connection'
                        : 'the above selected connection'}`)) : (React.createElement("span", null,
                "Remove",
                ' ',
                React.createElement("b", null, removeOne
                    ? data.account.display_name || data.account.name
                    : data.account.institution_name),
                ' ',
                "balance history"))),
            React.createElement(semantic_ui_react_1.Icon, { name: "check circle" })),
        React.createElement("p", { className: "mt-1rem" }, "Note: This operation may take a few minutes depending on how much data we have imported for your accounts."))) : (React.createElement(React.Fragment, null));
};
exports.default = DeleteSyncedCryptoModal;
