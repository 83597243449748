"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_2 = require("react");
const UserProvider_1 = require("@/providers/UserProvider");
const Moment = require("moment");
const TaskCard_1 = require("@components/TaskCard/TaskCard");
const format_1 = require("@/helpers/format");
const BudgetTaskCard = ({ leftToBudget }) => {
    const _user = (0, react_2.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [taskDone, setTaskDone] = (0, react_1.useState)(false);
    const [title, setTitle] = (0, react_1.useState)(null);
    const [subtitle, setSubtitle] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (leftToBudget > 0) {
            setTaskDone(false);
            setTitle(`Left to budget: ${(0, format_1.toPrice)(leftToBudget, _user.primaryCurrency)}`);
            setSubtitle(null);
        }
        else if (leftToBudget < 0) {
            setTaskDone(false);
            setTitle(`Overbudgeted by ${(0, format_1.toPrice)(leftToBudget * -1, _user.primaryCurrency)}`);
            setSubtitle(null);
        }
        else {
            // Focus/prompt on next month's budget
            setTaskDone(true);
            setTitle(`Balanced for this period!`);
            setSubtitle(null);
        }
    }, [leftToBudget]);
    return (React.createElement(TaskCard_1.default, { isLoading: typeof leftToBudget == 'undefined', type: "budget", className: taskDone ? 'task-done' : '', onClick: () => {
            history.push(`/budget/${Moment().format('YYYY/MM')}`);
        }, title: title || `Set ${Moment().format('MMMM')} budget`, subtitle: subtitle, hideQuestionMark: true }));
};
exports.default = BudgetTaskCard;
