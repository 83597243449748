"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const _ = require("lodash");
const Moment = require("moment");
const tinycolor = require("tinycolor2");
const react_router_dom_1 = require("react-router-dom");
const format_1 = require("@helpers/format");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const SpendingBreakdown = ({ barSetting, // 'relative' or 'full'
allIncome, allExpenses, incomeTotal, expenseTotal, showExcludedIncome, showExcludedExpenses, totalRemainingRecurringExpense, totalRemainingRecurringIncome, colors, displayType, budgetDisplay, monthProgress, keepCategoryGroups, sort, updateSort, showProjected, currentPeriod, startDate, endDate, openTable, }) => {
    var _a;
    const [rowsUI, setRowsUI] = (0, react_1.useState)([]);
    const [incomeUI, setIncomeUI] = (0, react_1.useState)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [collapsed, setCollapsed] = (0, react_1.useState)(((_a = localStorage
        .getItem('_lm_overview_breakdown_collapsed_groups')) === null || _a === void 0 ? void 0 : _a.split(',').map(o => parseInt(o))) || []);
    (0, react_1.useEffect)(() => {
        const mapFn = (category, index) => {
            if (displayType == 'budget' &&
                // Uncategorized
                (category.category_id == null ||
                    // There's no budget set
                    (category.budgeted === null && !category.exclude_from_budget))) {
                return getExcluded({
                    excluded: false,
                    barColumn: category.category_id == null || category.category_id < 0 ? (React.createElement("span", { className: "excluded" }, "Not budgetable")) : (React.createElement(React.Fragment, null,
                        "No budget set.",
                        ' ',
                        React.createElement(react_router_dom_1.Link, { to: {
                                pathname: `/budget`,
                                search: `?category=${category.category_id}`,
                            }, className: `link clickable` }, "Set one now."))),
                    spent: category.spent + category.recurring_spent,
                    recurring_spent: category.recurring_spent,
                    recurring_remaining: category.recurring_remaining,
                    is_income: category.is_income,
                    category_id: category.category_id,
                    is_group: category.is_group,
                    group_id: category.group_id,
                });
            }
            else if (category[displayType == 'budget'
                ? 'exclude_from_budget'
                : 'exclude_from_totals']) {
                return getExcluded({
                    barColumn: (React.createElement("span", { className: "excluded" },
                        "Excluded from ",
                        displayType == 'budget' ? 'budget' : 'totals',
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                            "This category is excluded from",
                            ' ',
                            displayType == 'budget'
                                ? 'your budget'
                                : 'the totals calculation',
                            ". You can change this setting from the",
                            ' ',
                            React.createElement(react_router_dom_1.Link, { to: "/categories", className: "link clickable" }, "Categories page.")))),
                    spent: category.spent + category.recurring_spent,
                    recurring_spent: category.recurring_spent,
                    recurring_remaining: category.recurring_remaining,
                    is_income: category.is_income,
                    category_id: category.category_id,
                    is_group: category.is_group,
                    group_id: category.group_id,
                });
            }
            const getPercentage = () => {
                // There's only getting percentage for spent
                const numerator = category.spent +
                    category.recurring_spent +
                    category.recurring_remaining;
                const base = ((numerator * (category.is_income ? -1 : 1)) / getDenominator()) * 100;
                if (base == 0 || base < 0) {
                    return '0%';
                }
                if (base > 0 && base < 1) {
                    return '<1%';
                }
                else if (!isFinite(base)) {
                    return `∞%`;
                }
                else {
                    return `${base.toFixed(0)}%`;
                }
            };
            const getAmountLeft = () => {
                return (category.available *
                    // category.budgeted -
                    // (category.spent + category.recurring_remaining) *
                    (category.is_income ? -1 : 1));
            };
            const MIN_WIDTH = 1.1;
            const getWidth = type => {
                let numerator = null;
                if (type == 'recurring_spent') {
                    numerator = category.recurring_spent * (category.is_income ? -1 : 1);
                }
                else if (type == 'spent') {
                    numerator = category.spent * (category.is_income ? -1 : 1);
                }
                else if (type == 'recurring_remaining') {
                    numerator =
                        category.recurring_remaining * (category.is_income ? -1 : 1);
                }
                if (numerator == 0) {
                    return 0;
                }
                else {
                    return Math.min(Math.max(MIN_WIDTH, (numerator / getDenominator()) * 100), 100);
                }
            };
            const getDenominator = () => {
                let results = null;
                if (displayType == 'spend') {
                    if (category.is_income) {
                        if (barSetting == 'relative') {
                            results =
                                incomeTotal +
                                    (showProjected ? totalRemainingRecurringIncome : 0);
                        }
                        else {
                            results =
                                category.spent +
                                    category.recurring_spent +
                                    (showProjected ? category.recurring_remaining : 0);
                        }
                    }
                    else {
                        if (barSetting == 'relative') {
                            results =
                                expenseTotal +
                                    (showProjected ? totalRemainingRecurringExpense : 0);
                        }
                        else {
                            results =
                                category.spent +
                                    category.recurring_spent +
                                    (showProjected ? category.recurring_remaining : 0);
                        }
                    }
                    return results * (category.is_income ? -1 : 1);
                }
                else {
                    // Budget
                    return category.budgeted;
                }
            };
            const getColor = () => {
                return category.is_income
                    ? (0, format_1.reverseArray)(colors)[index % colors.length]
                    : colors[index % colors.length];
            };
            const recurringSpentWidth = getWidth('recurring_spent');
            const spentWidth = getWidth('spent');
            const recurringRemainingWidth = getWidth('recurring_remaining');
            return (React.createElement(semantic_ui_react_1.Table.Row, { id: `spending-breakdown-${category.category_id}`, key: `spending-breakdown-${category.category_id}`, onMouseOver: () => {
                    document.querySelectorAll('.bar-section').forEach(el => {
                        el.classList.remove('highlighted-bar');
                    });
                    try {
                        document
                            .getElementById(`horizontal-bar-${category.category_id}`)
                            .classList.add('highlighted-bar');
                    }
                    catch (e) { }
                }, onMouseLeave: () => {
                    document.querySelectorAll('.highlighted-bar').forEach(el => {
                        el.classList.remove('highlighted-bar');
                    });
                } },
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "spending-category-name" },
                    React.createElement("div", { className: "display--flex" },
                        category.is_group && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, className: "clickable", name: collapsed.indexOf(category.category_id) == -1
                                ? 'caret down'
                                : 'caret right', onClick: () => {
                                if (collapsed.indexOf(category.category_id) == -1) {
                                    // Add to collapsed
                                    setCollapsed([...collapsed, category.category_id]);
                                    localStorage.setItem('_lm_overview_breakdown_collapsed_groups', [...collapsed, category.category_id].join(','));
                                }
                                else {
                                    // Remove from collapsed
                                    setCollapsed(collapsed.filter(o => o !== category.category_id));
                                    localStorage.setItem('_lm_overview_breakdown_collapsed_groups', collapsed
                                        .filter(o => o !== category.category_id)
                                        .join(','));
                                }
                            } })),
                        React.createElement("span", { className: `ellipsis ${category.is_group ? 'ml-05rem' : ''} mr-1rem` },
                            category.group_id && keepCategoryGroups && (React.createElement("span", { className: "hierarchy-line-icon-5" })),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 200, trigger: React.createElement("span", { className: "link clickable", onClick: () => {
                                        openTable(Object.assign({ start_date: startDate, end_date: endDate, time: 'custom', match: 'all' }, (category.category_id == null
                                            ? { uncategorized: 'true' }
                                            : category.category_id > 0
                                                ? { category: category.category_id }
                                                : {
                                                    recurring: 'any',
                                                })));
                                    } }, _categories.getName(category.category_id)) }, "View transactions (pop-up)")))),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-side-padding" },
                    React.createElement("div", { className: "status-bar-background" },
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top left", trigger: React.createElement("div", { className: `status-bar ${spentWidth > 0 || recurringRemainingWidth > 0
                                    ? 'prepended'
                                    : ''}`, style: {
                                    background: `repeating-linear-gradient(
                    45deg,
                    ${tinycolor(getColor()).darken(10)},
                    ${tinycolor(getColor()).darken(10)} 5px,
                    ${tinycolor(getColor())} 5px,
                    ${tinycolor(getColor())} 10px
                  )`,
                                    width: Math.max(0, recurringSpentWidth) + '%',
                                } }) },
                            "You have ",
                            category.is_income ? 'earned' : 'spent',
                            ' ',
                            (0, format_1.toPrice)(category.recurring_spent * (category.is_income ? -1 : 1), _user.primaryCurrency),
                            ' ',
                            category.is_income ? 'from' : 'towards',
                            " recurring",
                            ' ',
                            category.is_income ? 'income' : 'expenses',
                            _user.settings['allow_recurring_categories'] &&
                                ` in ${_categories.getName(category.category_id)}`,
                            "."),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top left", trigger: React.createElement("div", { className: `status-bar ${recurringSpentWidth > 0
                                    ? recurringRemainingWidth > 0
                                        ? 'middle'
                                        : 'appended'
                                    : recurringRemainingWidth > 0
                                        ? 'prepended'
                                        : ''}`, style: {
                                    left: recurringSpentWidth + '%',
                                    backgroundColor: getColor(),
                                    width: Math.max(0, spentWidth) + '%',
                                } }) },
                            "You have ",
                            category.is_income ? 'earned' : 'spent',
                            ' ',
                            (0, format_1.toPrice)(category.spent * (category.is_income ? -1 : 1), _user.primaryCurrency),
                            ' ',
                            "in ",
                            _categories.getName(category.category_id),
                            !!category.recurring_spent &&
                                _user.settings['allow_recurring_categories'] &&
                                ` excluding recurring 
                ${category.is_income ? 'income' : 'expenses'}`,
                            "."),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top left", trigger: React.createElement("div", { className: `status-bar ${recurringSpentWidth > 0 || spentWidth > 0
                                    ? 'appended'
                                    : ''}`, style: {
                                    left: spentWidth + recurringSpentWidth + '%',
                                    background: `repeating-linear-gradient(
                    135deg,
                    ${tinycolor(getColor()).lighten(30)},
                    ${tinycolor(getColor()).lighten(30)} 5px,
                    ${tinycolor(getColor()).lighten(20)} 5px,
                    ${tinycolor(getColor()).lighten(20)} 10px
                  )`,
                                    width: Math.max(0, Math.min(recurringRemainingWidth, 100 - spentWidth - recurringSpentWidth)) + '%',
                                } }) },
                            "You are projected to ",
                            category.is_income ? 'earn' : 'spend',
                            ' ',
                            (0, format_1.toPrice)(category.recurring_remaining * (category.is_income ? -1 : 1), _user.primaryCurrency),
                            ' ',
                            "more",
                            ' ',
                            !!_user.settings['allow_recurring_categories'] &&
                                `in ${_categories.getName(category.category_id)}`,
                            ' ',
                            category.is_income ? 'from' : 'towards',
                            " recurring",
                            ' ',
                            category.is_income ? 'income' : 'expenses',
                            "."),
                        displayType == 'budget' &&
                            currentPeriod == Moment().format('YYYY/MM') && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "mini", position: "bottom center", trigger: React.createElement("div", { className: "today-line", style: { left: (0, format_1.toPercentage)(monthProgress) } }) },
                            "We are currently ",
                            (0, format_1.toPercentage)(monthProgress, 0),
                            " of the way through the month!")))),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align monospace no-wrap" }, category.spent != 0 || category.recurring_spent != 0 ? (React.createElement("span", null, (0, format_1.toPrice)((category.spent + category.recurring_spent) *
                    (category.is_income ? -1 : 1), _user.primaryCurrency))) : ('-')),
                showProjected && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align monospace" }, category.recurring_remaining != 0 ||
                    category.recurring_spent !== 0 ? (React.createElement("span", null, (0, format_1.toPrice)((category.spent +
                    category.recurring_spent +
                    category.recurring_remaining) *
                    (category.is_income ? -1 : 1), _user.primaryCurrency))) : ('-'))),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: `right-align monospace ${displayType == 'budget'
                        ? !category.is_income &&
                            category.budgeted <
                                (category.spent +
                                    category.recurring_spent +
                                    category.recurring_remaining) *
                                    (category.is_income ? -1 : 1)
                            ? 'color--red'
                            : 'color--green'
                        : ''}` },
                    React.createElement(semantic_ui_react_1.Popup, { disabled: displayType == 'spend' || budgetDisplay == 'percentage', trigger: React.createElement("span", { className: "no-wrap" }, displayType == 'spend' || budgetDisplay == 'percentage'
                            ? getPercentage()
                            : (0, format_1.toPrice)(getAmountLeft(), _user.primaryCurrency)), inverted: true, position: "top right", size: "small" }, !category.is_income
                        ? getAmountLeft() < 0
                            ? `You are over budget in ${_categories.getName(category.category_id)} by ${(0, format_1.toPrice)(getAmountLeft() * -1, _user.primaryCurrency)}. You budgeted ${(0, format_1.toPrice)(category.budgeted, _user.primaryCurrency)} for this
                    month ${category.recurring_remaining != 0
                                ? `and are projected to spend another ${(0, format_1.toPrice)(category.recurring_remaining, _user.primaryCurrency)} in recurring expenses`
                                : `and you've already spent ${(0, format_1.toPrice)(category.spent + category.recurring_spent, _user.primaryCurrency)}`}.`
                            : `You have ${(0, format_1.toPrice)(getAmountLeft(), _user.primaryCurrency)} available to spend for ${_categories.getName(category.category_id)}${category.rollover_option == 'same category'
                                ? ', including any rollovers'
                                : ''}. This period, you budgeted ${(0, format_1.toPrice)(category.budgeted, _user.primaryCurrency)} ${category.recurring_remaining == 0
                                ? `and you've already spent ${(0, format_1.toPrice)(category.spent + category.recurring_spent, _user.primaryCurrency)}`
                                : `and you're projected to spend ${(0, format_1.toPrice)(category.recurring_remaining)} more this month in recurring expenses`}.`
                        : ''))));
        };
        const expenses = allExpenses
            .filter(o => !(o.category_id == null &&
            !o.budgeted &&
            !o.spent &&
            !o.recurring_spent &&
            !o.recurring_remaining))
            .map((expense, index) => {
            var _a;
            if (!showExcludedExpenses) {
                if (displayType == 'budget' && expense['exclude_from_budget']) {
                    return null;
                }
                else if (displayType == 'spend' && expense['exclude_from_totals']) {
                    return null;
                }
            }
            const results = [];
            results.push(mapFn(expense, index));
            if (collapsed.indexOf(expense.category_id) == -1 &&
                keepCategoryGroups) {
                (_a = expense.children) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                    const aTotalSpent = a.spent + a.recurring_spent;
                    const bTotalSpent = b.spent + b.recurring_spent;
                    if (sort == 'descending' || sort == 'descending_percentage') {
                        return bTotalSpent - aTotalSpent;
                    }
                    else if (sort == 'ascending' ||
                        sort == 'ascending_percentage') {
                        return aTotalSpent - bTotalSpent;
                    }
                    else if (sort == 'ascending_projected') {
                        return (aTotalSpent +
                            a.recurring_remaining -
                            (bTotalSpent + b.recurring_remaining));
                    }
                    else if (sort == 'descending_projected') {
                        return (bTotalSpent +
                            b.recurring_remaining -
                            (aTotalSpent + a.recurring_remaining));
                    }
                    else if (sort == 'alphabetical') {
                        return _categories
                            .getName(a.category_id)
                            .localeCompare(_categories.getName(b.category_id));
                    }
                }).forEach(child => {
                    results.push(mapFn(child, index));
                });
            }
            return results;
        })
            .filter(o => !!o);
        const income = allIncome
            .filter(o => !(o.category_id == null &&
            !o.budgeted &&
            !o.spent &&
            !o.recurring_spent &&
            !o.recurring_remaining))
            .map((income, index) => {
            var _a;
            if (!showExcludedIncome) {
                if (displayType == 'budget' && income['exclude_from_budget']) {
                    return null;
                }
                else if (displayType == 'spend' && income['exclude_from_totals']) {
                    return null;
                }
            }
            const results = [];
            results.push(mapFn(income, index));
            if (collapsed.indexOf(income.category_id) == -1 && keepCategoryGroups) {
                (_a = income.children) === null || _a === void 0 ? void 0 : _a.forEach(child => {
                    results.push(mapFn(child, index));
                });
            }
            return results;
        })
            .filter(o => !!o);
        setRowsUI(_.flatten(expenses).filter(o => !!o));
        setIncomeUI(_.flatten(income).filter(o => !!o));
    }, [
        barSetting,
        allExpenses,
        allIncome,
        showExcludedIncome,
        showExcludedExpenses,
        incomeTotal,
        expenseTotal,
        totalRemainingRecurringExpense,
        totalRemainingRecurringIncome,
        displayType,
        budgetDisplay,
        currentPeriod,
        collapsed,
        showProjected,
        keepCategoryGroups,
        sort,
    ]);
    const getHeader = text => {
        return (React.createElement(semantic_ui_react_1.Table.Header, null,
            React.createElement(semantic_ui_react_1.Table.Row, null,
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: `no-wrap spending-category-name clickable ${sort == 'alphabetical' ? 'selected-sort' : ''}`, onClick: () => {
                        if (sort == 'alphabetical') {
                            updateSort('reverse_alphabetical');
                        }
                        else {
                            updateSort('alphabetical');
                        }
                    } },
                    sort == 'alphabetical' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle up", fitted: true, color: "grey" })),
                    sort == 'reverse_alphabetical' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle down", fitted: true, color: "grey" })),
                    ' ',
                    text.toLowerCase() == 'total earned' ? 'Income' : 'Expenses'),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap spending-bar center-align" }, displayType == 'budget' ? 'Budget Progress' : ''),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: `no-wrap spending-number right-align clickable ${sort == 'ascending' || sort == 'descending' ? 'selected-sort' : ''}`, onClick: () => {
                        if (sort == 'ascending') {
                            updateSort('descending');
                        }
                        else {
                            updateSort('ascending');
                        }
                    } },
                    sort == 'ascending' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle up", fitted: true, color: "grey" })),
                    sort == 'descending' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle down", fitted: true, color: "grey" })),
                    ' ',
                    text == 'Total earned' ? (React.createElement("span", null,
                        "Total ",
                        React.createElement("br", null),
                        "earned")) : (React.createElement("span", null,
                        "Total ",
                        React.createElement("br", null),
                        "spent"))),
                showProjected && (React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: `no-wrap spending-number right-align clickable ${sort == 'ascending_projected' || sort == 'descending_projected'
                        ? 'selected-sort'
                        : ''}`, onClick: () => {
                        if (sort == 'ascending_projected') {
                            updateSort('descending_projected');
                        }
                        else {
                            updateSort('ascending_projected');
                        }
                    } },
                    sort == 'ascending_projected' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle up", fitted: true, color: "grey" })),
                    sort == 'descending_projected' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle down", fitted: true, color: "grey" })),
                    ' ',
                    text == 'Total earned' ? (React.createElement(semantic_ui_react_1.Popup, { position: "top right", mouseEnterDelay: 500, trigger: React.createElement("span", null,
                            "Proj. ",
                            React.createElement("br", null),
                            "earn"), inverted: true, size: "small" }, "This is how much you're projected to earn based on how much you've earned so far and your remaining recurring income.")) : (React.createElement(semantic_ui_react_1.Popup, { position: "top right", mouseEnterDelay: 500, trigger: React.createElement("span", null,
                            "Proj. ",
                            React.createElement("br", null),
                            "spend"), inverted: true, size: "small" }, "This is how much you're projected to spend based on how much you've spent so far and your remaining recurring expenses.")))),
                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: `no-wrap spending-number clickable ${sort == 'ascending_percentage' || sort == 'descending_percentage'
                        ? 'selected-sort'
                        : ''}`, onClick: () => {
                        if (sort == 'ascending_percentage') {
                            updateSort('descending_percentage');
                        }
                        else {
                            updateSort('ascending_percentage');
                        }
                    } },
                    sort == 'ascending_percentage' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle up", fitted: true, color: "grey" })),
                    sort == 'descending_percentage' && (React.createElement(semantic_ui_react_1.Icon, { name: "triangle down", fitted: true, color: "grey" })),
                    ' ',
                    displayType == 'spend' && (React.createElement(React.Fragment, null,
                        "% of ",
                        React.createElement("br", null),
                        " total")),
                    displayType == 'budget' && budgetDisplay == 'percentage' && (React.createElement(React.Fragment, null,
                        "% of ",
                        React.createElement("br", null),
                        " budget")),
                    displayType == 'budget' &&
                        budgetDisplay == 'amount' &&
                        (text.toLowerCase() == 'total earned' ? (React.createElement(React.Fragment, null, "Available")) : (React.createElement(semantic_ui_react_1.Popup, { position: "top right", mouseEnterDelay: 500, trigger: React.createElement("span", null, "Available"), inverted: true, size: "small" }, "This is the difference between your budgeted amount and your projected spending. In other words, this is how much you can safely spend in each category to stay within budget.")))))));
    };
    const getExcluded = ({ excluded = true, barColumn, spent, recurring_spent, recurring_remaining, is_income, category_id, is_group, group_id, }) => {
        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `table-row-${category_id}`, className: excluded ? 'excluded' : '', id: `spending-breakdown-${category_id}`, onMouseOver: () => {
                document.querySelectorAll('.bar-section').forEach(el => {
                    el.classList.remove('highlighted-bar');
                });
                try {
                    document
                        .getElementById(`horizontal-bar-${category_id}`)
                        .classList.add('highlighted-bar');
                }
                catch (e) { }
            }, onMouseLeave: () => {
                document.querySelectorAll('.highlighted-bar').forEach(el => {
                    el.classList.remove('highlighted-bar');
                });
            } },
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "spending-category-name" },
                React.createElement("div", { className: "display--flex" },
                    is_group && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, className: "clickable", name: collapsed.indexOf(category_id) == -1
                            ? 'caret down'
                            : 'caret right', onClick: () => {
                            if (collapsed.indexOf(category_id) == -1) {
                                // Add to collapsed
                                setCollapsed([...collapsed, category_id]);
                                localStorage.setItem('_lm_overview_breakdown_collapsed_groups', [...collapsed, category_id].join(','));
                            }
                            else {
                                // Remove from collapsed
                                setCollapsed(collapsed.filter(o => o !== category_id));
                                localStorage.setItem('_lm_overview_breakdown_collapsed_groups', collapsed.filter(o => o !== category_id).join(','));
                            }
                        } })),
                    React.createElement("span", { className: `ellipsis ${is_group ? 'ml-05rem' : ''}` },
                        group_id && keepCategoryGroups && (React.createElement("span", { className: "hierarchy-line-icon-5" })),
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 200, trigger: React.createElement("span", { className: "link clickable", onClick: () => {
                                    openTable(Object.assign({ start_date: startDate, end_date: endDate, match: 'all', time: 'custom' }, (category_id == null
                                        ? { uncategorized: true }
                                        : { category: category_id })));
                                } }, _categories.getName(category_id)) }, "View transactions (pop-up)")))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" }, barColumn),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align monospace" }, spent != 0 ? (React.createElement("span", null, (0, format_1.toPrice)(spent * (is_income ? -1 : 1), _user.primaryCurrency))) : ('-')),
            showProjected && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align monospace" }, recurring_remaining != 0 || recurring_spent != 0 ? (React.createElement("span", null, (0, format_1.toPrice)((spent + recurring_remaining) * (is_income ? -1 : 1), _user.primaryCurrency))) : ('-'))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align monospace" }, "-")));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Table, { basic: "very", unstackable: true, className: "spending-breakdown-table no-border" },
            getHeader('Total earned'),
            React.createElement(semantic_ui_react_1.Table.Body, { className: "mt-0.5rem" },
                incomeUI.length == 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row" },
                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: showProjected ? 5 : 4, className: "center-align" },
                        React.createElement("span", { className: "excluded" },
                            "Get started by adding ",
                            React.createElement(react_router_dom_1.Link, { to: "/categories" }, "categories"),
                            ' ',
                            "or",
                            ' ',
                            React.createElement(react_router_dom_1.Link, { to: `/transactions/${currentPeriod}` }, "transactions"),
                            ".")))),
                incomeUI),
            getHeader('Total spent'),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                rowsUI.length == 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row" },
                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: showProjected ? 5 : 4, className: "center-align" },
                        React.createElement("span", { className: "excluded" },
                            "Get started by adding ",
                            React.createElement(react_router_dom_1.Link, { to: "/categories" }, "categories"),
                            ' ',
                            "or",
                            ' ',
                            React.createElement(react_router_dom_1.Link, { to: `/transactions/${currentPeriod}` }, "transactions"),
                            ".")))),
                rowsUI,
                React.createElement(semantic_ui_react_1.Table.Row, { style: { height: '10px' } })))));
};
exports.default = SpendingBreakdown;
