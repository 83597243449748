"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const format_1 = require("@/helpers/format");
const custom_budgets_1 = require("@/actions/custom_budgets");
const EditRow = ({ auto_budget, budget_settings, category, showModal, fetch, _showToast, }) => {
    var _a, _b;
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [rolloverValue, setRolloverValue] = (0, react_1.useState)(budget_settings.rollover_option);
    const [oneClickValue, setOneClickValue] = (0, react_1.useState)(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type);
    (0, react_1.useEffect)(() => {
        setRolloverValue(budget_settings.rollover_option);
        setOneClickValue(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type);
    }, [auto_budget, budget_settings]);
    const ROLLOVER_VALUES = [
        {
            key: 'available-funds',
            text: budget_settings.rollover_start_date
                ? `General pool starting ${Moment(budget_settings.rollover_start_date).format(_user.getFormat('month_day_year'))}`
                : 'Rollover to general pool',
            value: 'available funds',
        },
        category.is_income
            ? null
            : {
                key: 'same-category',
                text: budget_settings.rollover_start_date
                    ? `Same category starting ${Moment(budget_settings.rollover_start_date).format(_user.getFormat('month_day_year'))}`
                    : 'Rollover to same category',
                value: 'same category',
            },
        {
            key: 'do-nothing',
            text: 'Do nothing',
            value: null,
        },
    ].filter(o => !!o);
    const ONE_CLICK_BUDGETING_VALUES = [
        {
            key: 'fixed',
            text: `Set fixed amount${(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fixed' || (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fill'
                ? `: ${(0, format_1.toPrice)(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.amount, auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.currency)}`
                : ''}`,
            value: 'fixed',
        },
        category.is_income
            ? null
            : {
                key: 'fill',
                text: `Fill 'Available' ${(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fixed' || (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fill'
                    ? `to ${(0, format_1.toPrice)(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.amount, auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.currency)}`
                    : 'up to a certain amount'}`,
                value: 'fill',
            },
        {
            key: 'spend',
            text: `Set to previous period's ${category.is_income ? 'activity' : 'spend'}`,
            value: 'spend',
        },
        {
            key: 'budget',
            text: `Set to previous period's ${category.is_income ? 'expected' : 'budget'}`,
            value: 'budget',
        },
        {
            key: 'average-3',
            text: `Set to average ${category.is_income ? 'activity' : 'spend'} of last 3 periods`,
            value: 'average-3',
        },
        {
            key: 'nothing',
            text: 'Do nothing',
            value: 'nothing',
        },
    ].filter(o => !!o);
    const updateBudget = (updateObj) => __awaiter(void 0, void 0, void 0, function* () {
        yield (0, custom_budgets_1.createCustomBudget)([
            Object.assign({ filterType: 'category', filterValue: category.id }, updateObj),
        ]);
        yield fetch();
        _showToast({
            type: 'success',
            message: `Budget settings for ${_categories.getName(category.id)} updated successfully`,
        });
    });
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: category.is_group ? '' : 'clickable', onClick: () => {
            showModal();
        } },
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell", colSpan: category.is_group ? 7 : !category.is_child ? 3 : 1, style: category.is_child
                ? {
                    width: '10px',
                    borderRight: '1px solid #f8b500',
                    backgroundColor: '#fcfcfc',
                    borderTop: 0,
                }
                : {} },
            React.createElement("div", { className: "flex--space-between" }, category.is_group || !category.is_child
                ? _categories.getName(category.id)
                : '')),
        category.is_child && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" },
                React.createElement("div", { className: "flex--space-between" }, category.is_child && _categories.getName(category.id))))),
        category.is_group ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable" },
                React.createElement(semantic_ui_react_1.Dropdown, { value: rolloverValue, text: (_a = ROLLOVER_VALUES.find(o => o.value == rolloverValue)) === null || _a === void 0 ? void 0 : _a.text, fluid: true, className: `category-dropdown p-category-inline standalone-inline-dropdown`, options: ROLLOVER_VALUES, onChange: (e, obj) => __awaiter(void 0, void 0, void 0, function* () {
                        setRolloverValue(obj.value);
                        updateBudget({
                            rolloverOption: obj.value,
                        });
                    }) })))),
        category.is_group ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable" },
                React.createElement(semantic_ui_react_1.Dropdown, { value: oneClickValue, text: (_b = ONE_CLICK_BUDGETING_VALUES.find(o => o.value == oneClickValue)) === null || _b === void 0 ? void 0 : _b.text, fluid: true, className: `category-dropdown p-category-inline standalone-inline-dropdown`, options: ONE_CLICK_BUDGETING_VALUES, onChange: (e, obj) => __awaiter(void 0, void 0, void 0, function* () {
                        if (obj.value == 'fixed' || obj.value == 'fill') {
                            showModal({
                                selected_auto_budget_type: obj.value,
                            });
                        }
                        else {
                            setOneClickValue(obj.value);
                            updateBudget({
                                autoBudgetOption: obj.value,
                            });
                        }
                    }) })))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable" },
            React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey` }))));
};
exports.default = EditRow;
