"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const auth_1 = require("@actions/auth");
const semantic_ui_react_1 = require("semantic-ui-react");
const AuthProvider_1 = require("@providers/AuthProvider");
const TIMEOUT_SECONDS = 60;
const TwoFactor = ({}) => {
    let history = (0, react_router_dom_1.useHistory)();
    const _auth = (0, react_1.useContext)(AuthProvider_1.AuthContext);
    const [error, setError] = (0, react_1.useState)('');
    const [message, setMessage] = (0, react_1.useState)('');
    const [code, setCode] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [remember, setRemember] = (0, react_1.useState)(false);
    const [method, setMethod] = (0, react_1.useState)(null);
    const [useBackup, setUseBackup] = (0, react_1.useState)(false);
    const [useEmail, setUseEmail] = (0, react_1.useState)(false);
    const errorInterval = (0, react_1.useRef)(null);
    const [timer, setTimer] = (0, react_1.useState)(TIMEOUT_SECONDS);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = `Two-Factor Authentication - Lunch Money`;
        const opts = qs.parse(history.location.search);
        if (opts && opts.method) {
            setMethod(opts.method);
        }
        else {
            window.location.href = '/login';
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (!timer) {
            clearInterval(errorInterval.current);
            return;
        }
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        errorInterval.current = intervalId;
        return () => clearInterval(errorInterval.current);
    }, [timer]);
    const resend = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setError(null);
        setMessage(null);
        const results = yield (0, auth_1.resendTotp)();
        if (!results.error) {
            setIsLoading(false);
            setMessage('Resent text message with a new code.');
            setTimer(TIMEOUT_SECONDS);
        }
        else {
            setIsLoading(true);
            setError(results.error);
        }
    });
    const resendViaEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        setUseEmail(true);
        setIsLoading(true);
        setError(null);
        setMessage(null);
        const results = yield (0, auth_1.resendTotpViaEmail)();
        if (!results.error) {
            setIsLoading(false);
            setMessage(`We've sent you an email with a new code.`);
            setTimer(TIMEOUT_SECONDS);
        }
        else {
            setIsLoading(true);
            setError(results.error.message);
        }
    });
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setError('');
        setMessage('');
        let results = null;
        if (useBackup) {
            results = yield (0, auth_1.verifyBackup)({ code });
        }
        else {
            results = yield (0, auth_1.verifyTotp)({
                code,
                test: false,
                type: useEmail ? 'email' : method,
                remember,
            });
        }
        if (!results.data) {
            setError('Code is incorrect. Please try again.');
            setIsLoading(false);
        }
        else {
            setError('');
            setMessage('Code is correct! Please wait while we log you in...');
            setIsLoading(false);
            // Make sure AuthProvider knows we're logged in now
            yield _auth.ping();
            const query = qs.parse(location.search);
            if (query['redirect']) {
                history.push(decodeURIComponent(query['redirect']));
            }
            else {
                history.push('/overview');
            }
        }
    });
    return (React.createElement("div", { className: "g-logged-out" },
        React.createElement("div", { className: "g-logged-out-container" },
            React.createElement("div", { className: `logged-out-content` },
                React.createElement(semantic_ui_react_1.Container, null,
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo" }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h1", className: "center-align" }, useBackup ? 'Use Back-up Code' : 'Two-Factor Enabled'),
                    useBackup ? (React.createElement("p", { className: "center-align" }, "Enter one of your generated back-up codes to gain access to your account.")) : method === 'sms' ? (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "center-align" }, "We sent you a text message with a code. It will expire in 5 minutes!"),
                        React.createElement("p", { className: "center-align" },
                            React.createElement("b", null, "Didn't receive a code?"),
                            " We're having intermittent trouble with our SMS provider. In the meantime, you may opt for email-based verification or use a back-up code. Don't have access to either? Reach out to us at",
                            React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "support@lunchmoney.app"),
                            ' ',
                            "and we'll be more than happy to assist!"))) : (React.createElement("p", { className: "center-align" }, "Please check your authenticator app.")),
                    React.createElement(semantic_ui_react_1.Form, { className: "logged-out-form" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Input, { autoFocus: true, value: code, onChange: e => {
                                    setCode(e.target.value.replace(/\s/g, ''));
                                }, fluid: true, label: useBackup
                                    ? 'Enter your back-up code'
                                    : 'Enter your security code' })),
                        !useBackup && (React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Form.Checkbox, { toggle: true, checked: remember, onChange: (e, { checked }) => {
                                    setRemember(checked);
                                }, label: "Remember my device for 30 days" }))),
                        error && React.createElement("p", { className: `error mb-1rem` }, error),
                        message && (React.createElement("p", { className: "center-align mt-0 mb-1rem" }, message)),
                        React.createElement(semantic_ui_react_1.Button, { disabled: !code || code.length < 1, type: "submit", className: "mt-0", loading: isLoading, onClick: submit }, "Verify"),
                        method === 'sms' && !useBackup && (React.createElement(semantic_ui_react_1.Button, { basic: true, loading: isLoading, color: "orange", type: "submit", className: "mt-05rem", onClick: resendViaEmail }, "Send me a code via email")),
                        React.createElement(semantic_ui_react_1.Button, { size: "small", onClick: () => {
                                setError(null);
                                setMessage(null);
                                setUseBackup(!useBackup);
                            }, className: "mt-05rem", basic: true, color: "orange" }, useBackup ? 'Go back' : 'Use backup code instead')),
                    React.createElement("p", { className: "register" },
                        "Need help?",
                        ' ',
                        React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "Contact support.")))))));
};
exports.default = TwoFactor;
