"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const notification_settings_1 = require("@actions/notification_settings");
const format_1 = require("@helpers/format");
const Notifications = ({ _showToast }) => {
    var _a, _b, _c;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [settings, setSettings] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, notification_settings_1.getNotificationSettings)();
            const settingsObj = {};
            results.forEach(setting => {
                settingsObj[setting.template_name] = {
                    email: setting.email,
                };
            });
            setSettings(settingsObj);
        });
        fetch();
    }, []);
    const update = (setting, value) => __awaiter(void 0, void 0, void 0, function* () {
        _user.updateSetting(setting, value);
        _showToast({ message: 'Successfully saved setting', type: 'success' });
    });
    return (React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem mt-1rem" },
        React.createElement(semantic_ui_react_1.Card, null,
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Email Notifications" }),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: typeof ((_a = settings['task-reminder']) === null || _a === void 0 ? void 0 : _a.email) === 'undefined'
                                ? true
                                : settings['task-reminder'].email, label: "Send me a bi-monthly digest of my status", onChange: (e_1, _d) => __awaiter(void 0, [e_1, _d], void 0, function* (e, { checked }) {
                                yield (0, notification_settings_1.updateNotificationSetting)({
                                    template_name: 'task-reminder',
                                    email: checked,
                                });
                                setSettings(Object.assign(Object.assign({}, settings), { ['task-reminder']: Object.assign(Object.assign({}, settings['task-reminder']), { email: checked }) }));
                                _showToast({
                                    message: 'Successfully saved settings',
                                    type: 'success',
                                });
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: typeof ((_b = settings['unreviewed-digest']) === null || _b === void 0 ? void 0 : _b.email) === 'undefined'
                                ? true
                                : settings['unreviewed-digest'].email, label: "Send me a weekly digest of unreviewed transactions", onChange: (e_2, _e) => __awaiter(void 0, [e_2, _e], void 0, function* (e, { checked }) {
                                yield (0, notification_settings_1.updateNotificationSetting)({
                                    template_name: 'unreviewed-digest',
                                    email: checked,
                                });
                                setSettings(Object.assign(Object.assign({}, settings), { ['unreviewed-digest']: Object.assign(Object.assign({}, settings['unreviewed-digest']), { email: checked }) }));
                                _showToast({
                                    message: 'Successfully saved settings',
                                    type: 'success',
                                });
                            }) }))),
                ((_c = settings['unreviewed-digest']) === null || _c === void 0 ? void 0 : _c.email) && (React.createElement(semantic_ui_react_1.Form.Group, { className: "ml-4rem", widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", placeholder: "Select day of the week", selection: true, value: _user.settings['unreviewed_digest_day'], fluid: true, onChange: (e_3, _f) => __awaiter(void 0, [e_3, _f], void 0, function* (e, { value }) {
                                update('unreviewed_digest_day', value);
                            }), options: [
                                'monday',
                                'tuesday',
                                'wednesday',
                                'thursday',
                                'friday',
                                'saturday',
                                'sunday',
                            ].map(day => {
                                return {
                                    key: day,
                                    value: day,
                                    text: `every ${(0, format_1.capitalize)(day)}`,
                                };
                            }) })))),
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    "For transaction-specific notifications, visit the",
                    ' ',
                    React.createElement(react_router_dom_1.Link, { className: "link", to: { pathname: '/rules', search: '?filter=email' } }, "Rules page"),
                    ".")))));
};
exports.default = Notifications;
