"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const _ = require("lodash");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const HorizontalBar_1 = require("@/components/Overview/HorizontalBar");
const SpendingBreakdown_1 = require("@/components/Overview/SpendingBreakdown");
const dates_1 = require("@helpers/dates");
const UserProvider_1 = require("@providers/UserProvider");
const overview_1 = require("@helpers/overview");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const colors = [
    '#3BD182',
    '#FED745',
    '#f95d6a',
    '#5071EA',
    '#C7D635',
    '#DB7171',
    '#4DCECD',
    //
    '#40d6bb',
    '#F389AE',
    '#845EC2',
    '#2AAFB5',
    '#e8467c',
    '#2C73D2',
    '#ff7c43',
    '#D571C7',
    '#189E8A',
];
const SpendingBreakdownContainer = ({ summary, isLoading, startDate, endDate, openTable, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    // Settings
    const [showAllCategories, setShowAllCategories] = (0, react_1.useState)((_a = _user.settings['monthly_overview_show_all_categories']) !== null && _a !== void 0 ? _a : true);
    const [displayType, setDisplayType] = (0, react_1.useState)((_b = _user.settings['monthly_overview_display_type']) !== null && _b !== void 0 ? _b : 'spend');
    const [barSetting, setBarSetting] = (0, react_1.useState)((_c = _user.settings['monthly_overview_bar_setting']) !== null && _c !== void 0 ? _c : 'relative');
    const [sort, setSort] = (0, react_1.useState)((_d = _user.settings['monthly_overview_sort']) !== null && _d !== void 0 ? _d : 'descending_percentage');
    const [seenWalkthrough, setSeenWalkthrough] = (0, react_1.useState)((_e = _user.settings['monthly_overview_walkthrough_seen']) !== null && _e !== void 0 ? _e : true);
    const [showExcludedExpenses, setShowExcludedExpenses] = (0, react_1.useState)((_f = _user.settings['monthly_overview_show_excluded_expenses']) !== null && _f !== void 0 ? _f : false);
    const [showExcludedIncome, setShowExcludedIncome] = (0, react_1.useState)((_g = _user.settings['monthly_overview_show_excluded_income']) !== null && _g !== void 0 ? _g : false);
    const [keepCategoryGroups, setKeepCategoryGroups] = (0, react_1.useState)((_h = _user.settings['monthly_overview_keep_category_groups']) !== null && _h !== void 0 ? _h : true);
    const [budgetDisplay, setBudgetDisplay] = (0, react_1.useState)((_j = _user.settings['monthly_overview_budget_display']) !== null && _j !== void 0 ? _j : 'amount');
    const [showProjected, setShowProjected] = (0, react_1.useState)((_k = _user.settings['monthly_overview_show_projected']) !== null && _k !== void 0 ? _k : true);
    // For budget
    const [monthProgress, setMonthProgress] = (0, react_1.useState)(1);
    // Arrays of categories
    const [allIncome, setAllIncome] = (0, react_1.useState)([]);
    const [allExpenses, setAllExpenses] = (0, react_1.useState)([]);
    // Totals
    const [expenseTotal, setExpenseTotal] = (0, react_1.useState)(0);
    const [incomeTotal, setIncomeTotal] = (0, react_1.useState)(0);
    const [remainingIncome, setRemainingIncome] = (0, react_1.useState)(0);
    const [remainingExpense, setRemainingExpense] = (0, react_1.useState)(0);
    // status
    const [doneLoading, setDoneLoading] = (0, react_1.useState)(false);
    // current period
    const [currentPeriod, setCurrentPeriod] = (0, react_1.useState)(Moment(startDate).format('YYYY/MM'));
    const [fetchingNew, setFetchingNew] = (0, react_1.useState)(false);
    // Update user settings on server and provider
    const updateSetting = (key, value) => __awaiter(void 0, void 0, void 0, function* () {
        yield _user.updateSetting(key, value);
    });
    (0, react_1.useEffect)(() => {
        setCurrentPeriod(Moment(startDate).format('YYYY/MM'));
    }, [startDate]);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        if (!summary || !((_a = summary === null || summary === void 0 ? void 0 : summary.categories) === null || _a === void 0 ? void 0 : _a.length)) {
            // Hasn't loaded yet
            return;
        }
        const transformProperties = o => {
            return {
                archived: o.properties.category.archived,
                category_id: o.properties.category.id,
                category_name: _categories.getName(o.properties.category.id),
                exclude_from_budget: o.properties.category.exclude_from_budget,
                exclude_from_totals: o.properties.category.exclude_from_totals,
                group_id: o.properties.category.group_id,
                is_group: o.properties.category.is_group,
                is_income: o.properties.category.is_income,
                order: o.properties.category.order,
                // config?
                // totals
                rollover_balance: o.totals.rollover_balance,
                available: o.totals.available,
                budgeted: o.totals.budgeted,
                spent: o.totals.activity,
                recurring_spent: o.totals.recurring_activity,
                recurring_remaining: o.totals.recurring_remaining || 0,
            };
        };
        const _filtered = (_b = summary === null || summary === void 0 ? void 0 : summary.categories.map(o => {
            return Object.assign({}, transformProperties(o));
        })) === null || _b === void 0 ? void 0 : _b.filter(o => {
            if (showAllCategories) {
                return true;
            }
            else {
                return (!!o['spent'] ||
                    !!o['recurring_spent'] ||
                    (showProjected && !!o['recurring_remaining']));
            }
        });
        const _summary = _filtered === null || _filtered === void 0 ? void 0 : _filtered.map(o => {
            if (keepCategoryGroups) {
                if (!!o.group_id) {
                    // If we're keeping category groups, then remove the ones who are children since
                    // they will be accounted for under the category group
                    return null;
                }
                return Object.assign(Object.assign({}, (o.is_group
                    ? {
                        children: _filtered.filter(a => a.group_id == o.category_id),
                    }
                    : {})), o);
            }
            else if (o.is_group) {
                // Not keeping category groups, so just remove them
                return null;
            }
            return o;
        }).filter(o => !!o);
        let __allIncome = [
            ...(!!((_c = summary.totals.inflow) === null || _c === void 0 ? void 0 : _c.uncategorized)
                ? [
                    {
                        archived: false,
                        available: null,
                        budgeted: null,
                        category_id: null,
                        category_name: 'Uncategorized',
                        config: null,
                        exclude_from_budget: false,
                        exclude_from_totals: false,
                        group_id: null,
                        is_group: false,
                        is_income: true,
                        order: 0,
                        recurring_remaining: 0,
                        recurring_spent: summary.totals.inflow.uncategorized_recurring || null,
                        spent: (_d = summary.totals.inflow) === null || _d === void 0 ? void 0 : _d.uncategorized,
                    },
                ]
                : []),
            ..._summary === null || _summary === void 0 ? void 0 : _summary.filter(o => o.is_income),
        ];
        let __allExpenses = [
            ...(!!((_e = summary.totals.outflow) === null || _e === void 0 ? void 0 : _e.uncategorized)
                ? [
                    {
                        archived: false,
                        available: null,
                        budgeted: null,
                        category_id: null,
                        category_name: 'Uncategorized',
                        config: null,
                        exclude_from_budget: false,
                        exclude_from_totals: false,
                        group_id: null,
                        is_group: false,
                        is_income: false,
                        order: 0,
                        recurring_remaining: 0,
                        recurring_spent: summary.totals.outflow.uncategorized_recurring || null,
                        spent: (_f = summary.totals.outflow) === null || _f === void 0 ? void 0 : _f.uncategorized,
                    },
                ]
                : []),
            ..._summary === null || _summary === void 0 ? void 0 : _summary.filter(o => !o.is_income),
        ];
        if (!_user.settings['allow_recurring_categories']) {
            // Include recurring into income / expenses
            __allIncome.push({
                archived: false,
                budgeted: null,
                category_id: -2,
                category_name: 'Recurring',
                exclude_from_budget: false,
                exclude_from_totals: false,
                group_id: null,
                is_group: false,
                order: 0,
                config: null,
                is_income: true,
                available: null,
                spent: 0,
                recurring_spent: (_h = (_g = summary === null || summary === void 0 ? void 0 : summary.totals) === null || _g === void 0 ? void 0 : _g.inflow) === null || _h === void 0 ? void 0 : _h.recurring_activity,
                recurring_remaining: (_k = (_j = summary === null || summary === void 0 ? void 0 : summary.totals) === null || _j === void 0 ? void 0 : _j.inflow) === null || _k === void 0 ? void 0 : _k.recurring_remaining,
            });
            __allExpenses.push({
                archived: false,
                budgeted: null,
                category_id: -3,
                category_name: 'Recurring',
                exclude_from_budget: false,
                exclude_from_totals: false,
                group_id: null,
                is_group: false,
                order: 0,
                config: null,
                is_income: false,
                available: null,
                spent: 0,
                recurring_spent: (_m = (_l = summary === null || summary === void 0 ? void 0 : summary.totals) === null || _l === void 0 ? void 0 : _l.outflow) === null || _m === void 0 ? void 0 : _m.recurring_activity,
                recurring_remaining: (_p = (_o = summary === null || summary === void 0 ? void 0 : summary.totals) === null || _o === void 0 ? void 0 : _o.outflow) === null || _p === void 0 ? void 0 : _p.recurring_remaining,
            });
        }
        if (!showProjected) {
            // Set all recurring_remaining to 0
            __allIncome = _.cloneDeep(__allIncome).map(o => {
                o['recurring_remaining'] = 0;
                return o;
            });
            __allExpenses = _.cloneDeep(__allExpenses).map(o => {
                o['recurring_remaining'] = 0;
                return o;
            });
        }
        const sorted = (0, overview_1.sortRows)({
            displayType,
            budgetDisplay,
            sort,
            allIncome: __allIncome,
            allExpenses: __allExpenses,
        });
        // After sorting, need to reverse __allIncome since everything is backwards
        __allIncome = sorted._allIncome.reverse();
        __allExpenses = sorted._allExpenses;
        setIncomeTotal(((_q = summary.totals.inflow) === null || _q === void 0 ? void 0 : _q.activity) +
            ((_r = summary.totals.inflow) === null || _r === void 0 ? void 0 : _r.recurring_activity) +
            ((_s = summary.totals.inflow) === null || _s === void 0 ? void 0 : _s.uncategorized));
        setExpenseTotal(((_t = summary.totals.outflow) === null || _t === void 0 ? void 0 : _t.activity) +
            ((_u = summary.totals.outflow) === null || _u === void 0 ? void 0 : _u.recurring_activity) +
            ((_v = summary.totals.outflow) === null || _v === void 0 ? void 0 : _v.uncategorized));
        setRemainingIncome(((_w = summary.totals.inflow) === null || _w === void 0 ? void 0 : _w.recurring_remaining) || 0);
        setRemainingExpense(((_x = summary.totals.outflow) === null || _x === void 0 ? void 0 : _x.recurring_remaining) || 0);
        setAllIncome(__allIncome);
        setAllExpenses(__allExpenses);
        setDoneLoading(true);
    }, [
        summary,
        sort,
        displayType,
        budgetDisplay,
        showAllCategories,
        keepCategoryGroups,
        showExcludedIncome,
        showExcludedExpenses,
        showProjected,
    ]);
    (0, react_1.useEffect)(() => {
        setMonthProgress((0, dates_1.getMonthProgress)());
    }, []);
    return doneLoading && !isLoading ? (React.createElement(semantic_ui_react_1.Card, null,
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Card.Header, null,
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("span", null),
                    React.createElement("span", null,
                        displayType == 'budget' ? 'Budget' : 'Spending',
                        " Breakdown"),
                    React.createElement("div", null,
                        fetchingNew ? (React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny", style: {
                                marginTop: '-2px',
                                marginRight: '1px',
                                height: '14px',
                            } })) : (React.createElement(semantic_ui_react_1.Popup, { wide: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "cog", color: "grey", className: "clickable" }), content: React.createElement("div", null,
                                React.createElement("div", { className: "mb-05rem" },
                                    "Show against",
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: displayType, onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_display_type', value);
                                            setDisplayType(value);
                                        }, options: [
                                            {
                                                key: 'budget',
                                                value: 'budget',
                                                text: 'budget',
                                            },
                                            {
                                                key: 'spend',
                                                value: 'spend',
                                                text: 'total spend',
                                            },
                                        ] })),
                                React.createElement("div", { className: "mb-05rem" },
                                    "Show bars",
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: barSetting, onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_bar_setting', value);
                                            setBarSetting(value);
                                        }, options: [
                                            {
                                                key: 'full',
                                                value: 'full',
                                                text: 'fully expanded',
                                            },
                                            {
                                                key: 'relative',
                                                value: 'relative',
                                                text: 'relative to each other',
                                            },
                                        ] })),
                                React.createElement("p", { className: "divider" }),
                                React.createElement("div", { className: "mb-05rem" },
                                    "Display",
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem inline-breakdown-dropdown", inline: true, defaultValue: showAllCategories, onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_show_all_categories', value);
                                            setShowAllCategories(value);
                                        }, options: [
                                            {
                                                key: 'show all',
                                                value: true,
                                                text: 'all categories',
                                            },
                                            {
                                                key: 'show only with spend',
                                                value: false,
                                                text: 'only categories with spend',
                                            },
                                        ] })),
                                React.createElement("div", { className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: showExcludedExpenses ? 'show' : 'no_show', onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_show_excluded_expenses', value === 'show' ? true : false);
                                            setShowExcludedExpenses(value === 'show' ? true : false);
                                        }, options: [
                                            {
                                                key: 'show',
                                                value: 'show',
                                                text: 'Show',
                                            },
                                            {
                                                key: 'no_show',
                                                value: 'no_show',
                                                text: "Don't show",
                                            },
                                        ] }),
                                    ' ',
                                    "excluded expense categories",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                        React.createElement("p", null,
                                            "Some categories are marked as",
                                            ' ',
                                            displayType == 'spend' ? (React.createElement("b", null, "exclude from totals")) : (React.createElement("b", null, "exclude from budget")),
                                            ". Choose whether or not to include these categories as part of this view. You can update these settings from the",
                                            ' ',
                                            React.createElement(react_router_dom_1.Link, { className: "link", to: "/categories" }, "Categories"),
                                            ' ',
                                            "page."))),
                                React.createElement("div", { className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: showExcludedIncome ? 'show' : 'no_show', onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_show_excluded_income', value === 'show' ? true : false);
                                            setShowExcludedIncome(value === 'show' ? true : false);
                                        }, options: [
                                            {
                                                key: 'show_income',
                                                value: 'show',
                                                text: 'Show',
                                            },
                                            {
                                                key: 'no_show_income',
                                                value: 'no_show',
                                                text: "Don't show",
                                            },
                                        ] }),
                                    ' ',
                                    "excluded income categories",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                        React.createElement("p", null,
                                            "Some categories are marked as",
                                            ' ',
                                            displayType == 'spend' ? (React.createElement("b", null, "exclude from totals")) : (React.createElement("b", null, "exclude from budget")),
                                            ". Choose whether or not to include these categories as part of this view. You can update these settings from the",
                                            ' ',
                                            React.createElement(react_router_dom_1.Link, { className: "link", to: "/categories" }, "Categories"),
                                            ' ',
                                            "page."))),
                                React.createElement("div", { className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: showProjected ? 'show' : 'hide', onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_show_projected', value === 'show' ? true : false);
                                            setShowProjected(value === 'show');
                                        }, options: [
                                            {
                                                key: 'show',
                                                value: 'show',
                                                text: 'Show',
                                            },
                                            {
                                                key: 'hide',
                                                value: 'hide',
                                                text: 'Hide',
                                            },
                                        ] }),
                                    ' ',
                                    "projected spending and earnings",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                        React.createElement("p", null,
                                            "Show projected spending by including recurring expenses and income that have not yet occurred. Set up recurring items from the",
                                            React.createElement(react_router_dom_1.Link, { className: "link", to: "/recurring" }, "Recurring Items"),
                                            ' ',
                                            "or the",
                                            ' ',
                                            React.createElement(react_router_dom_1.Link, { className: "link", to: "/transactions" }, "Transactions"),
                                            "page."))),
                                React.createElement("div", null,
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: keepCategoryGroups ? 'keep' : 'ungroup', onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_keep_category_groups', value === 'keep' ? true : false);
                                            setKeepCategoryGroups(value === 'keep' ? true : false);
                                        }, options: [
                                            {
                                                key: 'keep_groups',
                                                value: 'keep',
                                                text: 'Show',
                                            },
                                            {
                                                key: 'ungroup_groups',
                                                value: 'ungroup',
                                                text: 'Hide',
                                            },
                                        ] }),
                                    ' ',
                                    "category groups"),
                                displayType == 'budget' && (React.createElement("div", { className: "mt-05rem" },
                                    "Show",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem inline-breakdown-dropdown", inline: true, defaultValue: budgetDisplay, onChange: (e, { value }) => {
                                            updateSetting('monthly_overview_budget_display', value);
                                            setBudgetDisplay(value);
                                        }, options: [
                                            {
                                                key: 'percentage',
                                                value: 'percentage',
                                                text: 'percentage spent',
                                            },
                                            {
                                                key: 'amount',
                                                value: 'amount',
                                                text: 'amount left',
                                            },
                                        ] }),
                                    ' ',
                                    "of budget"))), on: "click", position: "bottom center" })),
                        React.createElement(semantic_ui_react_1.Popup, { open: !seenWalkthrough, position: "right center", className: "walkthrough", trigger: React.createElement("div", null) },
                            React.createElement("div", { className: "flex--space-between-flex-start" },
                                React.createElement("b", null, "\u2728 Monthly Breakdown"),
                                React.createElement(semantic_ui_react_1.Icon, { className: "clickable", name: "close", onClick: () => {
                                        updateSetting('monthly_overview_walkthrough_seen', true);
                                        setSeenWalkthrough(false);
                                    } })),
                            React.createElement("div", { className: "border-top" },
                                React.createElement("p", null, "Easily see how your monthly spending or budget is going this month at a glance."),
                                React.createElement("p", null, "Customize your monthly summary breakdown view by clicking here!"))))))),
        React.createElement(semantic_ui_react_1.Card.Content, { className: "breakdown-scroll-container" },
            React.createElement(HorizontalBar_1.default, { totals: summary === null || summary === void 0 ? void 0 : summary.totals, allIncome: allIncome, allExpenses: allExpenses, totalRemainingRecurringExpense: remainingExpense, totalRemainingRecurringIncome: remainingIncome, colors: colors, incomeTotal: incomeTotal, expenseTotal: expenseTotal, barSetting: barSetting, startDate: startDate, endDate: endDate, openTable: query => {
                    openTable(query);
                } }),
            React.createElement(SpendingBreakdown_1.default, { barSetting: barSetting, allIncome: allIncome, allExpenses: allExpenses, incomeTotal: incomeTotal, expenseTotal: expenseTotal, showExcludedIncome: showExcludedIncome, showExcludedExpenses: showExcludedExpenses, totalRemainingRecurringExpense: remainingExpense, totalRemainingRecurringIncome: remainingIncome, colors: colors, showProjected: showProjected, displayType: displayType, budgetDisplay: budgetDisplay, monthProgress: monthProgress, keepCategoryGroups: keepCategoryGroups, currentPeriod: currentPeriod, sort: sort, updateSort: newSort => {
                    updateSetting('monthly_overview_sort', newSort);
                    setSort(newSort);
                }, startDate: startDate, endDate: endDate, openTable: query => {
                    openTable(query);
                } })))) : (React.createElement(semantic_ui_react_1.Card, null,
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Card.Header, null, "Monthly Breakdown")),
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" }))));
};
exports.default = SpendingBreakdownContainer;
