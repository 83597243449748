"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const budgets_1 = require("@/actions/budgets");
const ClearAllBudgetsBtn = ({ budgets, _showToast, _process, callback }) => {
    const [showClearConfirm, setShowClearConfirm] = (0, react_1.useState)(false);
    const [showButton, setShowButton] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setShowButton((budgets === null || budgets === void 0 ? void 0 : budgets.categories.map(budget => {
            var _a;
            return (((_a = budget.occurrences.filter(o => !o.historical && !!o.budgeted)) === null || _a === void 0 ? void 0 : _a.length) > 0);
        }).filter(o => o == true).length) > 0);
    }, [budgets]);
    return (React.createElement(React.Fragment, null,
        showButton && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", color: "orange", onClick: () => {
                    setShowClearConfirm(true);
                }, className: "mb-05rem width-100" },
                React.createElement(semantic_ui_react_1.Icon, { name: "trash" }),
                React.createElement("span", { className: "position-top-1-5px" }, "Clear All Budgets")), inverted: true, size: "small", position: "left center" }, "Click to delete all the budgeted values from the current period")),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showClearConfirm, closeOnEscape: false, closeOnDimmerClick: false, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Clear this period's budgets"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null, "Are you sure you want to reset all the budgets of this period's category budgets?"),
                    React.createElement("p", null,
                        React.createElement("b", null, "Note: this is a nuclear option and cannot be reversed.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowClearConfirm(false);
                    }, negative: true }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const toDelete = [];
                        budgets === null || budgets === void 0 ? void 0 : budgets.categories.forEach(budget => {
                            budget.occurrences
                                .filter(o => !o.historical)
                                .forEach(o => {
                                toDelete.push({
                                    start_date: o.start_date,
                                    category_id: budget.properties.category.id,
                                });
                            });
                        });
                        yield _process(budgets_1.removeBudgets)(toDelete);
                        setShowClearConfirm(false);
                        _showToast({
                            type: 'success',
                            message: 'Budget cleared successfully',
                        });
                        yield callback();
                    }) }, "Confirm")))));
};
exports.default = ClearAllBudgetsBtn;
