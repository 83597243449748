"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortRows = void 0;
const sortRows = ({ displayType, budgetDisplay, sort, allIncome, allExpenses, }) => {
    let _allIncome = allIncome;
    let _allExpenses = allExpenses;
    // Total Earned / Total Spent
    if (sort == 'descending') {
        // Apply sort
        const sortFn = (a, b) => {
            const nameA = a.spent + a.recurring_spent || 0;
            const nameB = b.spent + b.recurring_spent || 0;
            if (nameA && nameB) {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
            }
            else if (nameA && !nameB) {
                return -1;
            }
            else if (!nameA && nameB) {
                return 1;
            }
            else {
                return a.recurring_remaining > b.recurring_remaining ? -1 : 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (sort == 'ascending') {
        const sortFn = (a, b) => {
            const nameA = a.spent + a.recurring_spent;
            const nameB = b.spent + b.recurring_spent;
            if (nameA && nameB) {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            }
            else if (nameA && !nameB) {
                return 1;
            }
            else if (!nameA && nameB) {
                return -1;
            }
            else {
                return a.recurring_remaining > b.recurring_remaining ? 1 : -1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (
    // Proj. Earned / Proj. Spent
    sort == 'descending_projected' ||
        (sort == 'descending_percentage' && displayType == 'spend')) {
        const sortFn = (a, b) => {
            if (displayType == 'spend' && a.exclude_from_totals) {
                return 1;
            }
            if (displayType == 'spend' && b.exclude_from_totals) {
                return -1;
            }
            const nameA = a.spent + a.recurring_spent + a.recurring_remaining;
            const nameB = b.spent + b.recurring_spent + b.recurring_remaining;
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (sort == 'ascending_projected' ||
        (sort == 'ascending_percentage' && displayType == 'spend')) {
        const sortFn = (a, b) => {
            if (displayType == 'spend' && a.exclude_from_totals) {
                return -1;
            }
            if (displayType == 'spend' && b.exclude_from_totals) {
                return 1;
            }
            const nameA = a.spent + a.recurring_spent + a.recurring_remaining;
            const nameB = b.spent + b.recurring_spent + b.recurring_remaining;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if ((sort == 'ascending_percentage' || sort == 'descending_percentage') &&
        displayType == 'budget' &&
        budgetDisplay == 'amount') {
        const sortFn = (a, b) => {
            if (!a.budgeted) {
                return 1;
            }
            else if (!b.budgeted) {
                return -1;
            }
            const nameA = a.budgeted -
                (a.spent + a.recurring_spent + a.recurring_remaining) *
                    (a.is_income ? -1 : 1);
            const nameB = b.budgeted -
                (b.spent + b.recurring_spent + b.recurring_remaining) *
                    (b.is_income ? -1 : 1);
            if ((sort == 'ascending_percentage' && nameA < nameB) ||
                (sort == 'descending_percentage' && nameA > nameB)) {
                return -1;
            }
            if ((sort == 'ascending_percentage' && nameA > nameB) ||
                (sort == 'descending_percentage' && nameA < nameB)) {
                return 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (sort == 'ascending_percentage' &&
        displayType == 'budget' &&
        budgetDisplay == 'percentage') {
        const sortFn = (a, b) => {
            if (!a.budgeted) {
                if (!b.budgeted) {
                    if (a.spent + a.recurring_spent < b.spent + b.recurring_spent) {
                        return -1;
                    }
                    if (a.spent + a.recurring_spent > b.spent + b.recurring_spent) {
                        return 1;
                    }
                }
                return -1;
            }
            if (!b.budgeted) {
                if (!a.budgeted) {
                    if (a.spent + a.recurring_spent < b.spent + b.recurring_spent) {
                        return 1;
                    }
                    if (a.spent + a.recurring_spent > b.spent + b.recurring_spent) {
                        return -1;
                    }
                }
                return 1;
            }
            const nameA = (a.spent + a.recurring_spent) / a.budgeted;
            const nameB = (b.spent + b.recurring_spent) / b.budgeted;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (sort == 'descending_percentage' &&
        displayType == 'budget' &&
        budgetDisplay == 'percentage') {
        const sortFn = (a, b) => {
            if (!a.budgeted) {
                if (!b.budgeted) {
                    if (a.spent + a.recurring_spent < b.spent + b.recurring_spent) {
                        return 1;
                    }
                    if (a.spent + a.recurring_spent > b.spent + b.recurring_spent) {
                        return -1;
                    }
                }
                return 1;
            }
            if (!b.budgeted) {
                if (!a.budgeted) {
                    if (a.spent + a.recurring_spent < b.spent + b.recurring_spent) {
                        return -1;
                    }
                    if (a.spent + a.recurring_spent > b.spent + b.recurring_spent) {
                        return 1;
                    }
                }
                return -1;
            }
            const nameA = (a.spent + a.recurring_spent) / a.budgeted;
            const nameB = (b.spent + b.recurring_spent) / b.budgeted;
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }
            return 0;
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
    }
    else if (sort.indexOf('alphabetical') > -1) {
        const sortFn = (a, b) => {
            var _a, _b;
            const nameA = ((_a = a.category_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) || ''; // ignore upper and lowercase
            const nameB = ((_b = b.category_name) === null || _b === void 0 ? void 0 : _b.toLowerCase().trim()) || ''; // ignore upper and lowercase
            return nameA.localeCompare(nameB);
        };
        _allIncome = _allIncome.sort(sortFn);
        _allExpenses = _allExpenses.sort(sortFn);
        if (sort == 'reverse_alphabetical') {
            _allIncome = _allIncome.reverse();
            _allExpenses = _allExpenses.reverse();
        }
    }
    return { _allIncome, _allExpenses };
};
exports.sortRows = sortRows;
