"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyBackup = exports.getLastGeneratedTime = exports.generateBackupCodes = exports.getTotpStatus = exports.resendTotpViaEmail = exports.resendTotp = exports.revokeTotp = exports.verifyTotp = exports.setupTotp = exports.resetPassword = exports.initResetPassword = exports.updatePassword = exports.confirmEmail = exports.waitlist = exports.register = exports.logout = exports.login = exports.isAuthenticatedPage = void 0;
const common_1 = require("./common");
const isAuthenticatedPage = () => {
    let onAuthPage = true;
    const unauthPaths = [
        'login',
        'register',
        'refer',
        'reset',
        'auth/2fa',
        'confirm',
        'trial_extension',
        'accept',
        'logout',
        'unsubscribe',
    ];
    unauthPaths.forEach(path => {
        if (window.location.pathname.indexOf(path) === 1) {
            onAuthPage = false;
        }
    });
    return onAuthPage;
};
exports.isAuthenticatedPage = isAuthenticatedPage;
/**
 * Routes
 */
const login = (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    const results = yield (0, common_1.post)(`auth/login`, { username, password });
    if (!results.data) {
        // Login failed
        return null;
    }
    else {
        // Cookies should be set
        return results.data;
    }
});
exports.login = login;
const logout = (force) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`auth/logout`, { force });
});
exports.logout = logout;
const register = (body) => __awaiter(void 0, void 0, void 0, function* () {
    const results = yield (0, common_1.post)(`auth/register`, body);
    return results;
});
exports.register = register;
const waitlist = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`auth/waitlist`, body);
});
exports.waitlist = waitlist;
const confirmEmail = (confirm_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`auth/confirm/${confirm_id}`, {});
});
exports.confirmEmail = confirmEmail;
const updatePassword = (old_password, new_password) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`auth/password`, { old_password, new_password });
});
exports.updatePassword = updatePassword;
const initResetPassword = (email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`auth/password/reset`, { email });
});
exports.initResetPassword = initResetPassword;
const resetPassword = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`auth/password/reset`, body);
});
exports.resetPassword = resetPassword;
const setupTotp = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`totp/setup`, body);
});
exports.setupTotp = setupTotp;
const verifyTotp = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`totp/verify`, opts);
});
exports.verifyTotp = verifyTotp;
const revokeTotp = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`totp/revoke`, {});
});
exports.revokeTotp = revokeTotp;
const resendTotp = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`totp/resend`, {});
});
exports.resendTotp = resendTotp;
const resendTotpViaEmail = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`totp/resend_via_email`, {});
});
exports.resendTotpViaEmail = resendTotpViaEmail;
const getTotpStatus = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`totp/status`, {});
});
exports.getTotpStatus = getTotpStatus;
const generateBackupCodes = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`backup_codes`);
});
exports.generateBackupCodes = generateBackupCodes;
const getLastGeneratedTime = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`backup_codes`);
});
exports.getLastGeneratedTime = getLastGeneratedTime;
const verifyBackup = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`backup_codes/verify`, body);
});
exports.verifyBackup = verifyBackup;
