"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const EditablePrice_1 = require("../elements/EditablePrice");
const format_1 = require("@/helpers/format");
const _ = require("lodash");
const react_router_dom_1 = require("react-router-dom");
const budget_1 = require("@/helpers/budget");
const Row = ({ occurrences, category, recurring, auto_budget, budget_settings, totals, leftToBudget, onSave, showModal, showTransactions, periodStart, periodEnd, openTransferBudgetPopup, getTransferBudgetPopup, onHideOccurrences, hideOccurrences, collapse, setCollapse, hasGeneralPoolRollover, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [currentOccurrences, setCurrentOccurrences] = (0, react_1.useState)(occurrences.filter(o => !o.historical));
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setCurrentOccurrences(occurrences.filter(o => !o.historical));
    }, [occurrences]);
    const getIncomeRolloverPopup = () => {
        if (!category.is_group &&
            ((category.is_income &&
                budget_settings.rollover_option == 'same category') ||
                (category.is_income &&
                    budget_settings.rollover_option == null &&
                    hasGeneralPoolRollover))) {
            return (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red", className: "ml-05rem" }), inverted: true, size: "small" },
                category.is_income &&
                    budget_settings.rollover_option == 'same category' && (React.createElement("p", null, "It is not recommended to set income category rollover to same category. Please update to rollover to general pool!")),
                category.is_income &&
                    budget_settings.rollover_option == null &&
                    hasGeneralPoolRollover && (React.createElement("p", null, "If any categories will be rolling over into general pool, we highly recommend setting income categories to also roll into general pool. If not, then the calculations may not be accurate."))));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    const getRecurringPopup = () => {
        return (React.createElement(semantic_ui_react_1.Popup, { hoverable: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) },
            React.createElement("p", null, "Be sure to pad your budget based on your expected recurring items this period:"),
            recurring.map((o, index) => {
                return (React.createElement(React.Fragment, { key: `recurring=${index}` },
                    React.createElement("div", { className: `line-item` },
                        React.createElement("div", { className: "" }, o.payee),
                        React.createElement("div", { className: `underline` }),
                        React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(o.amount, o.currency))),
                    [
                        ...o.transactions_within_range.map(o => {
                            return { date: o.date, status: 'matched' };
                        }),
                        ...o.missing_dates_within_range.map(o => {
                            return {
                                date: o,
                                status: Moment().isSameOrBefore(Moment(o))
                                    ? 'waiting'
                                    : 'missing',
                            };
                        }),
                        ,
                    ]
                        .sort((a, b) => {
                        return Moment(a.date).isBefore(b.date) ? 1 : -1;
                    })
                        .map((o, index) => (React.createElement("div", { className: `line-item ml-1rem`, key: `line-item-${index}` },
                        React.createElement("div", { className: "" }, Moment(o.date).format(_user.getFormat('month_day'))),
                        React.createElement("div", { className: `underline` }),
                        React.createElement("div", { className: `property monospace` },
                            o.status == 'matched' && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "check circle", color: "green" })),
                            o.status == 'waiting' && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "wait", className: "color--grey" })),
                            o.status == 'missing' && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "warning circle", color: "yellow" }))))))));
            }),
            React.createElement("div", { className: `line-item` },
                React.createElement("div", { className: "font--bold" }, "Total Recurring Expected"),
                React.createElement("div", { className: `underline` }),
                React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(totals.recurring_expected * (category.is_income ? -1 : 1), _user.primaryCurrency))),
            React.createElement("div", { className: `line-item` },
                React.createElement("div", { className: "font--bold" }, "Total Remaining"),
                React.createElement("div", { className: `underline` }),
                React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(totals.recurring_remaining, _user.primaryCurrency))),
            React.createElement(semantic_ui_react_1.Button, { size: "mini", fluid: true, onClick: () => {
                    history.push({
                        pathname: `/recurring/${Moment(periodStart).format('YYYY/MM')}`,
                    });
                }, className: `mt-1rem` }, "View recurring expenses")));
    };
    const getLastThreeCells = ({ occurrenceIndex }) => {
        var _a;
        const occurrence = currentOccurrences[occurrenceIndex];
        if (!occurrence) {
            return null;
        }
        const budgetedValue = category.is_group
            ? totals.budgeted
            : occurrence.budgeted * (category.is_income ? -1 : 1);
        const activityValue = category.is_group
            ? totals.activity + totals.recurring_activity
            : occurrence.activity + occurrence.recurring_activity;
        const available = category.is_group
            ? totals['available']
            : occurrence['available'];
        const suggestion = (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) !== 'nothing' && !occurrence.budgeted_amount
            ? (0, budget_1.calculateBudgetSuggestions)(occurrences, occurrence, [auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type], {
                left_to_budget: leftToBudget,
                auto_budget,
                budget_settings,
                category,
            })
            : null;
        // Find index in occurrences
        const index = _.findIndex(occurrences, {
            start_date: occurrence.start_date,
        });
        let historicalValues = [];
        if (index > 0) {
            for (let i = 0; i <= index; i++) {
                historicalValues.push({
                    text: i == index ? 'This Period' : null,
                    start_date: occurrences[i].start_date,
                    end_date: occurrences[i].end_date,
                    value: occurrences[i].activity + occurrences[i].recurring_activity,
                });
            }
            if (_.every(historicalValues, { value: 0 })) {
                // Reset historical values
                historicalValues = [];
            }
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `right-align table-cell-input ${category.is_group ? 'padded-cell' : 'editable'}` }, category.is_group ? (React.createElement("div", { className: "flex--end" }, (0, format_1.toPrice)(budgetedValue, _user.primaryCurrency, undefined, undefined, React.createElement(React.Fragment, null)))) : (React.createElement(EditablePrice_1.default, { className: 'right-align', identifier: `budget-period`, displayNegativeSign: true, amount: occurrence.budgeted_amount, currency: occurrence.budgeted_currency, state: 'Default', nullValue: !suggestion
                    ? 'Set budget'
                    : `Set ${(0, format_1.toPrice)(suggestion === null || suggestion === void 0 ? void 0 : suggestion.amount, _user.primaryCurrency)}` || 'Set budget', showLoading: isLoading, showIcon: !!(suggestion === null || suggestion === void 0 ? void 0 : suggestion.amount), iconTooltip: `Click to set budget according to your auto-budget settings`, iconOnClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield onSave([
                        {
                            amount: suggestion === null || suggestion === void 0 ? void 0 : suggestion.amount,
                            currency: _user.primaryCurrency,
                            category_id: category.id,
                            start_date: occurrence.start_date,
                        },
                    ]);
                    setIsLoading(false);
                }), allowEmpty: true, location: 'inline-edit', onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield onSave([
                        {
                            amount,
                            currency,
                            category_id: category.id,
                            start_date: occurrence.start_date,
                        },
                    ]);
                    setIsLoading(false);
                }), suggestions: [
                    ...(0, budget_1.calculateBudgetSuggestions)(occurrences, occurrence, [
                        (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) || null,
                        category.is_income ? null : 'left-to-budget',
                        category.is_income ? null : 'balance',
                        category.is_income ? null : 'current-spend',
                        'spend',
                        'budget',
                        'average-3',
                    ], {
                        auto_budget,
                        budget_settings,
                        left_to_budget: leftToBudget,
                        category,
                    }).map(o => {
                        return Object.assign(Object.assign({}, o), { currency: _user.primaryCurrency });
                    }),
                    // Only show clear budget if there is a set budget
                    ...(!!occurrence.budgeted_amount
                        ? [
                            {
                                text: 'Clear',
                                amount: 0,
                                currency: _user.primaryCurrency,
                            },
                        ]
                        : []),
                ] }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align table-cell-amounts` },
                React.createElement("div", { className: "flex--space-between" },
                    !!activityValue || ((_a = occurrence === null || occurrence === void 0 ? void 0 : occurrence.transactions) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement("div", { className: "flex--start", style: {
                            position: 'relative',
                            top: '-2px',
                        } },
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover", onClick: () => {
                                    showTransactions({
                                        start_date: occurrence.start_date,
                                        end_date: occurrence.end_date,
                                        category: category.id,
                                    });
                                } }) }, "View transactions (pop-up)"))) : (React.createElement("div", null)),
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("div", { className: `display--flex ${(historicalValues === null || historicalValues === void 0 ? void 0 : historicalValues.length) == 0 ? '' : 'underline-text'}` }, (0, format_1.toPrice)(activityValue * (category.is_income ? -1 : 1), _user.primaryCurrency)), hoverable: true, disabled: (historicalValues === null || historicalValues === void 0 ? void 0 : historicalValues.length) == 0, size: "small" },
                        React.createElement("div", { style: { minWidth: '220px' } },
                            React.createElement("div", { className: "label-style underlined mb-05rem" },
                                "Historical Activity for ",
                                _categories.getName(category.id)),
                            historicalValues.map((o, i) => {
                                return (React.createElement("div", { key: `historical-${i}`, className: "flex--space-between-flex-center mt-05rem" },
                                    React.createElement("div", { className: "display--flex" },
                                        React.createElement("div", { className: "monospace no-wrap" }, o.text ||
                                            Moment(o.start_date).format(_user.getFormat(budget_settings.granularity == 'year'
                                                ? 'month_day_year'
                                                : 'month_day'))),
                                        o.value && i !== historicalValues.length - 1 ? (React.createElement("div", { className: " ml-05rem mr-05rem" },
                                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover", onClick: () => {
                                                        showTransactions({
                                                            start_date: o.start_date,
                                                            end_date: o.end_date,
                                                            category: category.id,
                                                        });
                                                    } }) }, "View transactions (pop-up)"))) : (React.createElement(React.Fragment, null))),
                                    React.createElement("div", { className: "monospace flex--align-center" },
                                        i > 0 && !!historicalValues[i - 1].value && (React.createElement(semantic_ui_react_1.Label, { basic: true, color: o.value > historicalValues[i - 1].value
                                                ? 'red'
                                                : 'green', className: "custom-budget-label ml-05rem" },
                                            React.createElement(semantic_ui_react_1.Icon, { color: o.value > historicalValues[i - 1].value
                                                    ? 'red'
                                                    : 'green', name: o.value > historicalValues[i - 1].value
                                                    ? 'caret up'
                                                    : 'caret down' }),
                                            (0, format_1.toPercentage)((o.value - historicalValues[i - 1].value) /
                                                historicalValues[i - 1].value, 1))),
                                        React.createElement("div", { className: "ml-05rem no-wrap" }, (0, format_1.toPrice)(o.value, _user.primaryCurrency)))));
                            }))))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align table-cell-amounts` }, React.createElement("div", { className: "flex--space-between-flex-start hover-toggle" },
                React.createElement("div", { className: "display--flex", style: { marginTop: '2px' } },
                    (!!occurrence.rollover_budgeted ||
                        !!occurrence.rollover_activity) &&
                        budget_settings.rollover_option == 'same category' &&
                        Moment(budget_settings.rollover_start_date).isSameOrBefore(Moment(occurrence.start_date)) && (React.createElement(semantic_ui_react_1.Icon, { name: "envelope open outline", className: `${openTransferBudgetPopup.category_id === category.id &&
                            openTransferBudgetPopup.start_date ===
                                occurrence.start_date
                            ? 'display--none'
                            : 'hide-on-hover'} clickable` })),
                    !category.is_group &&
                        getTransferBudgetPopup({
                            category_id: category.id,
                            category_group_id: category.group_id,
                            start_date: occurrence.start_date,
                            rollover_option: budget_settings.rollover_option,
                            available: available,
                            activity: activityValue,
                        })),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, disabled: !available || category.is_group, size: "small", trigger: React.createElement("div", { className: `${
                        // !!occurrence.rollover_balance &&
                        !category.is_group && !!available //&&
                            ? // budget_settings.rollover_option == 'same category'
                                'underline-text'
                            : ''} ${(available < 0 && !category.is_income) ||
                            (available > 0 && category.is_income)
                            ? 'color--red'
                            : (available < 0 && category.is_income) ||
                                (available > 0 && !category.is_income)
                                ? 'color--green'
                                : ''}` }, (0, format_1.toPrice)(available * (category.is_income ? -1 : 1), _user.primaryCurrency)) },
                    !!budget_settings.rollover_option ? (React.createElement("p", null,
                        "This amount will rollover to",
                        ' ',
                        budget_settings.rollover_option == 'available funds'
                            ? 'the general pool'
                            : 'the same category',
                        ' ',
                        "next period, according to your settings.")) : (React.createElement("p", null, "This amount will not be accounted for next period, according to your settings. To roll it over to the next period, adjust this category's rollover options.")),
                    budget_settings.rollover_option !== 'same category' ||
                        (!occurrence.rollover_balance && (React.createElement("p", null,
                            (0, format_1.toPrice)(occurrence.rollover_balance, _user.primaryCurrency),
                            ' ',
                            "rolled over in ",
                            _categories.getName(category.id),
                            " since",
                            ' ',
                            Moment(budget_settings.rollover_start_date).format(_user.getFormat('month_day_year')),
                            (!!budgetedValue || !!activityValue) &&
                                `, ${[
                                    !!budgetedValue
                                        ? `plus this period's budget (${(0, format_1.toPrice)(budgetedValue, _user.primaryCurrency)})`
                                        : null,
                                    !!activityValue
                                        ? `minus this period's activity (${(0, format_1.toPrice)(activityValue, _user.primaryCurrency)})`
                                        : null,
                                ]
                                    .filter(o => !!o)
                                    .join(' and ')}.`)))))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" })));
    };
    return (React.createElement(React.Fragment, { key: `budget-row-${category.id}` }, !category.is_child || !collapse[category.group_id] ? (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell", colSpan: category.is_group || !category.is_child ? 3 : 1, style: category.is_child
                    ? {
                        width: '10px',
                        borderRight: '1px solid #f8b500',
                        backgroundColor: document.body.classList.contains('dark')
                            ? '#303030'
                            : '#fcfcfc',
                        borderTop: 0,
                    }
                    : {} },
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("div", null,
                        category.is_group ? (React.createElement("div", { onClick: () => {
                                const newValue = Object.assign(Object.assign({}, collapse), { [category.id]: !collapse[category.id] });
                                localStorage.setItem(`_lm_budgets_collapsed_obj_${_user.accountId}`, JSON.stringify(newValue));
                                setCollapse(newValue);
                            }, className: "clickable" },
                            React.createElement(semantic_ui_react_1.Icon, { name: collapse[category.id] ? 'caret right' : 'caret down', className: "mr-1rem " }),
                            _categories.getName(category.id))) : !category.is_child ? (React.createElement("span", null, _categories.getName(category.id))) : (React.createElement(React.Fragment, null)),
                        category.archived &&
                            Moment(periodStart).isSameOrAfter(Moment(category.archived_on)) && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Label, { className: "ml-05rem", size: "tiny", color: 'grey' }, "Archived"), inverted: true, size: "small" }, "This category has been archived, but contains activity and/or a budgeted value for this period.")),
                        !category.is_child && currentOccurrences[0].notes && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", className: "ml-05rem" }) }, currentOccurrences[0].notes)),
                        !category.is_child && getIncomeRolloverPopup()),
                    React.createElement("div", { className: "flex--space-between" },
                        React.createElement("div", { className: "display--flex" },
                            !category.is_child &&
                                !!totals.recurring_expected &&
                                !category.is_group &&
                                getRecurringPopup(),
                            ' ')))),
            category.is_child && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" },
                    React.createElement("div", { className: "flex--space-between-flex-start" },
                        React.createElement("div", null,
                            _categories.getName(category.id),
                            currentOccurrences[0].notes && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", className: "ml-05rem" }) }, currentOccurrences[0].notes)),
                            getIncomeRolloverPopup()),
                        React.createElement("div", { className: "display--flex" }, !!totals.recurring_expected && getRecurringPopup()))))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            getLastThreeCells({
                occurrenceIndex: 0,
            }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `center-align ${category.is_group ? '' : 'clickable'}`, onClick: () => {
                    if (!category.is_group) {
                        showModal(true);
                    }
                } }, !category.is_group && (React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey` })))))) : (React.createElement(React.Fragment, null))));
};
exports.default = Row;
