"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringProvider = exports.RecurringContext = void 0;
const React = require("react");
const react_1 = require("react");
const recurring_items_1 = require("@/actions/recurring_items");
const format_1 = require("@helpers/format");
const Moment = require("moment");
const UserProvider_1 = require("@/providers/UserProvider");
const _ = require("lodash");
const semantic_ui_react_1 = require("semantic-ui-react");
const RecurringContext = (0, react_1.createContext)({
    isReady: false,
    fetchItems: () => { },
    getUnmatched: () => {
        return [];
    },
    getById: id => {
        return null;
    },
    mapById: {},
    allItems: [],
    dropdownOptions: [],
});
exports.RecurringContext = RecurringContext;
const RecurringProvider = props => {
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [allItems, setAllItems] = (0, react_1.useState)([]);
    const [dropdownOptions, setDropdownOptions] = (0, react_1.useState)([]);
    const hasFetchedRecurring = (0, react_1.useRef)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _startDate = (0, react_1.useRef)(Moment()
        .startOf('month')
        .format('YYYY-MM-DD')); // default as null
    const _endDate = (0, react_1.useRef)(Moment()
        .endOf('month')
        .format('YYYY-MM-DD')); // default as null
    const getById = id => {
        return allItems.find(o => o.id == id) || null;
    };
    const getUnmatched = (start_date, end_date) => __awaiter(void 0, void 0, void 0, function* () {
        // Only get if start_date is outside of _startDate and _endDate
        if (
        // If start_date and end_date is equal or within _startDate and _endDate using Moment
        Moment(start_date).isSameOrAfter(_startDate.current) &&
            Moment(end_date).isSameOrBefore(_endDate.current)) {
            // Use allItems but filter for missing_dates_within_range between start_date and end_date
            return allItems
                .map(o => {
                return Object.assign(Object.assign({}, o), { missing_dates_within_range: o.missing_dates_within_range.filter(d => Moment(d).isSameOrAfter(start_date) &&
                        Moment(d).isBefore(end_date)) });
            })
                .filter(o => o.missing_dates_within_range.length > 0);
        }
        else {
            const results = yield (0, recurring_items_1.getRecurringItems)({
                start_date,
                end_date,
            });
            return results.filter(o => { var _a; return ((_a = o.missing_dates_within_range) === null || _a === void 0 ? void 0 : _a.length) > 0; });
        }
    });
    const fetchItems = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (start_date = null, end_date = null) {
        hasFetchedRecurring.current = true;
        const results = yield (0, recurring_items_1.getRecurringItems)({
            start_date,
            end_date,
        });
        // Store all items
        setAllItems(results);
        // Compute Recurring dropdown items
        setDropdownOptions(results
            .filter(recurring => {
            return recurring.type == 'cleared';
        })
            .map(recurring => {
            return {
                key: `recurring-${recurring.id}`,
                value: recurring.id,
                text: `${recurring.payee}: ${(0, format_1.toPrice)(recurring.amount, recurring.currency)}`,
                content: (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "mb-0" },
                        recurring.payee,
                        ":",
                        ' ',
                        (0, format_1.toPrice)(recurring.amount, recurring.currency)),
                    recurring.description && (React.createElement("p", { className: "font-size--smaller mb-0" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", className: "mr-05rem" }),
                        ' ',
                        recurring.description)),
                    React.createElement("p", { className: "font-size--smaller mb-0" },
                        recurring.cadence == 'custom'
                            ? `Every ${recurring.quantity} ${recurring.granularity}${recurring.quantity > 1 ? 's' : ''}`
                            : (0, format_1.capitalize)(recurring.cadence),
                        ' ',
                        recurring.start_date &&
                            !recurring.end_date &&
                            `from ${Moment(recurring.start_date).format('MMM D, YYYY')}`,
                        !recurring.start_date &&
                            recurring.end_date &&
                            `until ${Moment(recurring.end_date).format('MMM D, YYYY')}`,
                        recurring.start_date &&
                            recurring.end_date &&
                            `from ${Moment(recurring.start_date).format('MMM D, YYYY')} to ${Moment(recurring.end_date).format('MMM D, YYYY')}`))),
            };
        })
            .sort(function (a, b) {
            return a['text']
                .trim()
                .toLowerCase()
                .localeCompare(b['text'].toLowerCase().trim());
        }));
        setIsReady(true);
        console.log('[Recurring Provider] Done fetching recurring items.');
    });
    (0, react_1.useEffect)(() => {
        console.log('[Recurring Provider] Loaded.');
    }, []);
    (0, react_1.useEffect)(() => {
        if (_user.hasFetchedUser &&
            _user.hasFetchedSettings &&
            _user.hasFetchedAccountSettings &&
            !isReady &&
            !hasFetchedRecurring.current) {
            fetchItems();
        }
    }, [_user]);
    return (React.createElement(RecurringContext.Provider, { value: {
            isReady,
            fetchItems,
            getUnmatched,
            getById,
            mapById: _.groupBy(allItems, 'id'),
            allItems,
            dropdownOptions,
        } }, props.children));
};
exports.RecurringProvider = RecurringProvider;
