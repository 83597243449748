"use strict";
/**
 *  TransactionRow.tsx
 *  A row in the transaction table.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const TransactionTag_1 = require("@components/elements/TransactionTag");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const transaction_tag_1 = require("@actions/transaction_tag");
const transactions_1 = require("@actions/transactions");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const format_1 = require("@/helpers/format");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const LineItem_1 = require("../global/LineItem");
const SplitPreview_1 = require("./SplitPreview");
const TransactionRow = ({ persistentHighlight = false, 
/** Handler for saving in-line edits */
onSave = (a, b) => { }, 
/** Handler for showing edit modal */
dismissRecurring = (a, b) => { }, 
/** Handler to open modal for editing */
onOpenModal = (a, b) => { }, 
/** Transaction */
showYear = false, transaction, 
// For budget view
preventModal = false, onBulkSelect = () => { }, showReviewed = true, showCheckbox = false, readOnly = false, hideCategory = false, hideTags = false, tagView = 'Show all', syncSelect = null, hiddenCols = [], showBulkSelect = true, handleTagUpdate = obj => { }, utils = { _process: null, _showToast: null, amendTransactions: null }, }) => {
    var _a, _b, _c, _d, _e, _f;
    let _scroll = 0;
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const [openDeletePending, setOpenDeletePending] = (0, react_1.useState)({});
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [bulkSelect, setBulkSelect] = (0, react_1.useState)(!!(_transactions === null || _transactions === void 0 ? void 0 : _transactions.selectMultiple));
    const [isCleared, setIsCleared] = (0, react_1.useState)(transaction.status === 'cleared');
    const [isChecked, setIsChecked] = (0, react_1.useState)(transaction.to_import);
    const [isSelected, setIsSelected] = (0, react_1.useState)((_a = _transactions.bulkSelected) === null || _a === void 0 ? void 0 : _a.current[transaction.id]);
    const [tagsUI, setTagsUI] = (0, react_1.useState)(null);
    const [openTags, setOpenTags] = (0, react_1.useState)(false);
    const [currentTags, setCurrentTags] = (0, react_1.useState)([]);
    const [currentTransaction, setCurrentTransaction] = (0, react_1.useState)(transaction);
    const IS_RECURRING = transaction.recurring_id != null && transaction.recurring_type == 'cleared';
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [highlight, setHighlight] = (0, react_1.useState)(false);
    const update = (id, obj) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        yield onSave(id, obj);
        setIsSaving(false);
        if (!showCheckbox) {
            // Don't highlight if it's in CSV import
            // When it's done saving, highlight.
            setHighlight(true);
            setTimeout(() => {
                // Remove
                setHighlight(false);
            }, 3000);
        }
    });
    (0, react_1.useEffect)(() => {
        var _a;
        if (syncSelect) {
            // Force sync select status from deselect/select all
            setIsSelected((_a = _transactions.bulkSelected) === null || _a === void 0 ? void 0 : _a.current[transaction.id]);
        }
    }, [syncSelect]);
    (0, react_1.useEffect)(() => {
        if (!_.isEqual(transaction, currentTransaction)) {
            setCurrentTransaction(transaction);
            setIsCleared(transaction.status === 'cleared');
            setIsChecked(transaction.to_import);
        }
        if (transaction._highlight) {
            // Set from amendTransactions
            // When it's done saving, highlight.
            setHighlight(true);
            setTimeout(() => {
                // Remove
                setHighlight(false);
            }, 5000);
        }
    }, [transaction]);
    (0, react_1.useEffect)(() => {
        if (hideTags)
            return;
        if (currentTransaction.tags && currentTransaction.tags.length > 0) {
            const sortedTags = currentTransaction.tags.sort(function (a, b) {
                var _a, _b;
                // Might not be able to sort if it comes in as [1,2,3]
                return (_a = a.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toLowerCase());
            });
            if (tagView == 'Show all') {
                setTagsUI(sortedTags.map((tag, index) => {
                    return (React.createElement(TransactionTag_1.default, { readOnly: readOnly, key: `${tag.id}-tag-${currentTransaction.id}-${index}`, transactionId: currentTransaction.id, tag: tag }));
                }));
            }
            else {
                // Need to build so that the first tag is visible and the rest is
                // behind a tooltip
                const structure = [];
                if (sortedTags[0]) {
                    structure.push(React.createElement(TransactionTag_1.default, { readOnly: readOnly, key: `main-tag-${currentTransaction.id}`, transactionId: currentTransaction.id, tag: sortedTags[0] }));
                }
                if (sortedTags.length > 1) {
                    structure.push(React.createElement(semantic_ui_react_1.Popup, { key: `popup-${currentTransaction.id}-extra`, trigger: React.createElement("span", { className: "clickable transaction-tag short" },
                            "+",
                            sortedTags.length - 1) }, sortedTags
                        .map((tag, index) => {
                        if (index == 0)
                            return;
                        return (React.createElement(TransactionTag_1.default, { readOnly: readOnly, className: 'in-popup', key: `hidden-tags-${tag.id}-${currentTransaction.id}-${index}`, transactionId: currentTransaction.id, tag: tag }));
                    })
                        .filter(o => !!o)));
                }
                setTagsUI(React.createElement("div", { className: "flex--align-center-on-mobile" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "tags", className: "show-on-mobile mr-05rem" }),
                    React.createElement("div", { className: "display--flex" }, structure)));
            }
        }
        else {
            if (!readOnly) {
                setTagsUI(React.createElement(semantic_ui_react_1.Popup, { className: "width-250", position: 'bottom right', open: openTags, onOpen: () => { }, trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, className: openTags ? '' : 'show-on-hover', color: "yellow", size: "tiny", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            _scroll = window.scrollY;
                            document.getElementsByTagName('body')[0].style.overflow =
                                'hidden';
                            if (openTags && currentTags && currentTags.length > 0) {
                                // Submit
                                const results = yield (0, transaction_tag_1.overwriteTransactionTags)(currentTransaction.id, currentTags);
                                if (!results.error) {
                                    setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { tags: results.data }));
                                    handleTagUpdate(Object.assign(Object.assign({}, currentTransaction), { tags: results.data }));
                                }
                            }
                            setOpenTags(!openTags);
                            setTimeout(() => {
                                document.getElementsByTagName('body')[0].style.overflow =
                                    'visible';
                                window.scroll(0, _scroll);
                            }, 10);
                        }) }, "Add") },
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", className: "mb-05rem", style: { paddingTop: '7px', paddingBottom: '5px' } }, "Done"),
                    React.createElement(EditableTags_1.default, { className: "smaller darker-bg", onChange: value => {
                            setCurrentTags(value);
                        }, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                            // Save on close dropdown
                            if (openTags && currentTags && currentTags.length > 0) {
                                // Submit
                                const results = yield (0, transaction_tag_1.overwriteTransactionTags)(currentTransaction.id, currentTags);
                                if (!results.error) {
                                    setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { tags: results.data }));
                                    handleTagUpdate(Object.assign(Object.assign({}, currentTransaction), { tags: results.data }));
                                }
                            }
                            setOpenTags(!openTags);
                        }), defaultOpen: true })));
            }
        }
    }, [readOnly, hideTags, openTags, tagView, currentTags, currentTransaction]);
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: `transaction-row ${isSelected ? 'selected-row' : ''}${highlight && !isSelected ? 'updated' : ''} ${persistentHighlight ? 'highlight' : ''} ${!isCleared || (showCheckbox && !isChecked) ? 'uncleared' : ''}
      ${isCleared ? 'cleared' : ''} ${!currentTransaction.is_pending && IS_RECURRING
            ? `recurring ${preventModal ? '' : 'clickable'} `
            : ''} ${currentTransaction.status === 'delete_pending' ? 'delete-pending' : ''} ${currentTransaction.is_pending ? 'pending no-hover-tr' : ''} ${isSaving ? 'saving' : ''} ${currentTransaction.is_pending ? 'clickable' : ''}`, onClick: () => {
            if (preventModal) {
                return;
            }
        }, onMouseEnter: () => {
            if (!bulkSelect && currentTransaction.status === 'delete_pending') {
                setOpenDeletePending(Object.assign(Object.assign({}, openDeletePending), { [currentTransaction.id]: true }));
            }
        }, onMouseLeave: () => {
            if (!bulkSelect && currentTransaction.status === 'delete_pending') {
                setOpenDeletePending(Object.assign(Object.assign({}, openDeletePending), { [currentTransaction.id]: false }));
            }
        } },
        showBulkSelect && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable hide-on-mobile-td", onClick: () => {
                    document.getSelection().removeAllRanges();
                    const tx = currentTransaction;
                    if (isSelected) {
                        delete _transactions.bulkSelected.current[tx.id];
                        setIsSelected(false);
                    }
                    else {
                        // Selecting
                        _transactions.bulkSelected.current[tx.id] = tx;
                        setIsSelected(true);
                    }
                    onBulkSelect();
                } }, currentTransaction.is_pending ? (React.createElement(React.Fragment, null)) : (React.createElement("span", { className: `bulk-checkbox ${isSelected ? 'checked' : 'empty'}` }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" },
                React.createElement("div", { className: "mobile-curtain", onClick: () => {
                        onOpenModal('DETAILS_VIEW', currentTransaction);
                    } }),
                React.createElement("div", { className: "review-curtain", onClick: () => {
                        if (currentTransaction.status === 'cleared') {
                            update(currentTransaction.id, {
                                status: 'uncleared',
                            });
                            setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { status: 'uncleared' }));
                            setIsCleared(false);
                        }
                        else {
                            update(currentTransaction.id, {
                                status: 'cleared',
                            });
                            setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { status: 'cleared' }));
                            setIsCleared(true);
                        }
                    } })))),
        showCheckbox && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '8px',
                } },
                React.createElement(semantic_ui_react_1.Checkbox, { checked: isChecked, onChange: (e, { checked }) => {
                        setIsChecked(checked);
                        update(currentTransaction.id, {
                            to_import: checked,
                        });
                    }, toggle: true })),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: isSaving || readOnly || currentTransaction.is_pending
                ? 'expandable'
                : 'editable', onClick: () => {
                if (preventModal) {
                    return;
                }
                else if (readOnly || currentTransaction.is_pending) {
                    onOpenModal('DETAILS_VIEW', currentTransaction);
                }
            } },
            React.createElement("div", { className: "flex--align-center-on-mobile" },
                React.createElement(semantic_ui_react_1.Icon, { name: "calendar alternate outline", className: "show-on-mobile" }),
                React.createElement(EditableDate_1.default, { identifier: `transaction-date-${currentTransaction.id}`, firstValue: currentTransaction.date, location: 'inline', autoFocus: true, format: showYear ? 'month_day_year' : 'month_day', state: isSaving || readOnly || currentTransaction.is_pending
                        ? 'ReadOnly'
                        : 'Default', onSave: date => {
                        if (currentTransaction.date !== date) {
                            update(currentTransaction.id, {
                                date,
                            });
                            setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { date }));
                        }
                    } }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        !hideCategory && (React.createElement(semantic_ui_react_1.Table.Cell, { className: !currentTransaction.is_pending &&
                !readOnly &&
                currentTransaction.recurring_type !== 'cleared'
                ? 'editable'
                : 'expandable', onClick: () => {
                if (preventModal) {
                    return;
                }
                else if (IS_RECURRING) {
                    onOpenModal('RECURRING_VIEW', currentTransaction);
                }
            } },
            currentTransaction.group_id && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement("span", { className: "suggested-label clickable", onClick: () => {
                        onOpenModal('GROUP_VIEW', currentTransaction);
                    } }, "Grouped transaction") },
                React.createElement("p", null, "This transaction is part of a group and inherits from the grouped transaction's category. Click here to edit this transaction group."))),
            !currentTransaction.group_id &&
                IS_RECURRING &&
                !_user.settings['allow_recurring_categories'] && (React.createElement("span", { className: "suggested-label" },
                "Recurring ",
                currentTransaction.is_income ? 'Income' : 'Expense')),
            !currentTransaction.group_id &&
                (!IS_RECURRING || _user.settings['allow_recurring_categories']) && (React.createElement("div", { className: "flex--align-center-on-mobile" },
                React.createElement(semantic_ui_react_1.Icon, { name: "archive", className: "show-on-mobile" }),
                React.createElement(EditableCategory_1.default, { identifier: `transaction-category-${currentTransaction.id}`, firstValue: currentTransaction.category_id, autoFocus: true, location: 'inline', state: readOnly || currentTransaction.recurring_type == 'cleared'
                        ? 'ReadOnly'
                        : 'Default', showUncategorized: true, onSave: category_id => {
                        if (!readOnly) {
                            if (category_id !== currentTransaction.category_id) {
                                update(currentTransaction.id, {
                                    category_id,
                                });
                            }
                            setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { category_id }));
                            return true;
                        }
                    } }))))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: !readOnly && !currentTransaction.is_pending && !IS_RECURRING
                ? 'editable'
                : 'expandable', onClick: () => {
                if (preventModal) {
                    return;
                }
                else if (IS_RECURRING) {
                    onOpenModal('RECURRING_VIEW', currentTransaction);
                }
            } },
            React.createElement("div", { className: "flex--align-center-on-mobile" },
                React.createElement(semantic_ui_react_1.Icon, { name: "map pin", className: "show-on-mobile" }),
                React.createElement(EditableString_1.default, { identifier: `transaction-payee-${currentTransaction.id}`, firstValue: currentTransaction.display_name || currentTransaction.payee, location: 'inline', autoFocus: true, autosuggest: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, emptyText: 'Add a payee', state: IS_RECURRING || readOnly ? 'ReadOnly' : 'Default', onSave: payee => {
                        if (payee !== currentTransaction.payee) {
                            setIsSaving(true);
                            update(currentTransaction.id, {
                                payee,
                            });
                            // Auto save
                            _transactions.addToMerchantNames(payee);
                        }
                    } }))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: `number ${!isSaving &&
                (currentTransaction.parent_id ||
                    currentTransaction.is_group ||
                    (currentTransaction.plaid_account_id &&
                        _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                        !((_b = _assets.getPlaidAccount(currentTransaction.plaid_account_id)) === null || _b === void 0 ? void 0 : _b.treat_as_manual)))
                ? `${preventModal ? '' : 'clickable'} `
                : ''} ${!readOnly &&
                !currentTransaction.parent_id &&
                !currentTransaction.is_group &&
                (!currentTransaction.plaid_account_id ||
                    (_assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                        _assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual']))
                ? 'editable'
                : 'expandable'}`, onClick: () => {
                if (preventModal) {
                    return;
                }
                else if (!isSaving && currentTransaction.parent_id) {
                    onOpenModal('SPLIT_VIEW', currentTransaction);
                }
                else if (!isSaving && currentTransaction.is_group) {
                    onOpenModal('GROUP_VIEW', currentTransaction);
                }
                else if (currentTransaction.plaid_account_id &&
                    _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                    !_assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual']) {
                    onOpenModal('DETAILS_VIEW', currentTransaction);
                }
            } },
            React.createElement(semantic_ui_react_1.Popup, { mouseEnterDelay: 300, disabled: (!currentTransaction.is_group ||
                    !currentTransaction.hasOwnProperty('children')) &&
                    !currentTransaction.parent_id, size: "small", trigger: React.createElement("div", { className: "flex--align-center-on-mobile" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "calculator", className: "show-on-mobile" }),
                    React.createElement(EditablePrice_1.default, { identifier: `transaction-price-${currentTransaction.id}`, state: readOnly ||
                            (currentTransaction.plaid_account_id &&
                                _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                                !_assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual'])
                            ? 'ReadOnly'
                            : 'Default', showSignLabel: true, amount: currentTransaction.amount, currency: currentTransaction.currency, location: 'inline-edit', isSplit: currentTransaction.has_parent, isGrouped: currentTransaction.is_group, isPartOfGroup: !!currentTransaction.group_id, shouldConfirmPlaidChange: !!currentTransaction.plaid_account_id, isRecurring: _user.settings['allow_recurring_categories'] && IS_RECURRING, onSave: (amount, currency) => {
                            if (amount !== currentTransaction.amount ||
                                currency !== currentTransaction.currency) {
                                update(currentTransaction.id, {
                                    amount,
                                    currency,
                                });
                                setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { amount,
                                    currency }));
                            }
                        } })) },
                currentTransaction.is_group && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "This group contains ", (_c = currentTransaction.children) === null || _c === void 0 ? void 0 :
                        _c.length,
                        ' ',
                        "transactions:"), (_d = currentTransaction === null || currentTransaction === void 0 ? void 0 : currentTransaction.children) === null || _d === void 0 ? void 0 :
                    _d.map((o, index) => {
                        return (React.createElement(LineItem_1.default, { key: `line-item-${index}`, inPopup: true, keyClass: "no-label", keyValue: `${Moment(o.date).format(_user.getFormat('month_day'))}: ${o.display_name || o.payee}`, propertyValue: (0, format_1.toPrice)(o.amount, o.currency) }));
                    }))),
                currentTransaction.parent_id && (React.createElement(SplitPreview_1.default, { parent: currentTransaction.parent_id, child: currentTransaction.id })))),
        (hiddenCols || []).indexOf('notes') == -1 && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `${currentTransaction.display_notes || currentTransaction.notes
                    ? ''
                    : 'no-data hover-toggle'} ${!readOnly && !currentTransaction.is_pending && !IS_RECURRING
                    ? 'editable'
                    : 'expandable'}`, onClick: () => {
                    if (preventModal) {
                        return;
                    }
                    else if (IS_RECURRING) {
                        onOpenModal('RECURRING_VIEW', currentTransaction);
                    }
                } },
                React.createElement("div", { className: "flex--align-center-on-mobile" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "sticky note outline", className: "show-on-mobile" }),
                    React.createElement(EditableString_1.default, { identifier: `transaction-notes-${currentTransaction.id}`, firstValue: currentTransaction.display_notes || currentTransaction.notes, location: 'inline', autoFocus: true, shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: false, blankIfNull: true, emptyText: 'Add a note', state: IS_RECURRING || readOnly ? 'ReadOnly' : 'Default', onSave: notes => {
                            if (notes !==
                                (currentTransaction.display_notes ||
                                    currentTransaction.notes)) {
                                update(currentTransaction.id, {
                                    notes,
                                });
                                setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { notes }));
                            }
                        } }))))),
        (hiddenCols || []).indexOf('accounts') == -1 && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: `${(currentTransaction.plaid_account_id &&
                    !_assets.getPlaidAccount(currentTransaction.plaid_account_id)) ||
                    (currentTransaction.asset_id &&
                        !_assets.getAsset(currentTransaction.asset_id)) ||
                    (!currentTransaction.is_pending &&
                        currentTransaction.plaid_account_id &&
                        _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                        !_assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual']) // and it's treatable as manual
                    ? 'expandable clickable'
                    : currentTransaction.is_pending || readOnly
                        ? 'expandable'
                        : 'editable'} ${_assets.getAssetName(currentTransaction.asset_id) ||
                    _assets.getPlaidAccountName(currentTransaction.plaid_account_id)
                    ? ''
                    : 'no-data hover-toggle'}`, onClick: () => {
                    if (preventModal) {
                        return;
                    }
                    else if ((currentTransaction.plaid_account_id &&
                        !_assets.getPlaidAccount(currentTransaction.plaid_account_id)) ||
                        (currentTransaction.asset_id &&
                            !_assets.getAsset(currentTransaction.asset_id)) ||
                        currentTransaction.is_pending ||
                        currentTransaction.is_group ||
                        (currentTransaction.plaid_account_id &&
                            _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                            !_assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual'])) {
                        onOpenModal('DETAILS_VIEW', currentTransaction);
                    }
                } },
                React.createElement("div", { className: "flex--align-center-on-mobile " }, currentTransaction.is_group ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Icon, { name: "university", className: "show-on-mobile mr-05rem" }),
                    React.createElement(EditableAsset_1.default, { defaultSelection: {
                            source: currentTransaction.asset_id
                                ? 'manual'
                                : currentTransaction.plaid_account_id
                                    ? 'plaid'
                                    : null,
                            id: currentTransaction.asset_id ||
                                currentTransaction.plaid_account_id ||
                                -1,
                        }, state: currentTransaction.is_pending ||
                            readOnly ||
                            (currentTransaction.plaid_account_id &&
                                !_assets.getPlaidAccount(currentTransaction.plaid_account_id)) ||
                            (currentTransaction.asset_id &&
                                !_assets.getAsset(currentTransaction.asset_id)) ||
                            (currentTransaction.plaid_account_id &&
                                _assets.getPlaidAccount(currentTransaction.plaid_account_id) &&
                                !_assets.getPlaidAccount(currentTransaction.plaid_account_id)['treat_as_manual'])
                            ? 'ReadOnly'
                            : 'Default', location: 'inline', onSave: selected => {
                            if (!readOnly) {
                                update(currentTransaction.id, Object.assign({}, (selected.id == -1
                                    ? { plaid_account_id: null, asset_id: null }
                                    : {
                                        [selected.source == 'plaid'
                                            ? 'plaid_account_id'
                                            : 'asset_id']: selected.id == -1 ? 'cash' : selected.id,
                                        [selected.source == 'plaid'
                                            ? 'asset_id'
                                            : 'plaid_account_id']: null,
                                    })));
                                setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), (selected.id == -1
                                    ? { plaid_account_id: null, asset_id: null }
                                    : {
                                        [selected.source == 'plaid'
                                            ? 'plaid_account_id'
                                            : 'asset_id']: selected.id == -1 ? 'cash' : selected.id,
                                        [selected.source == 'plaid'
                                            ? 'asset_id'
                                            : 'plaid_account_id']: null,
                                    })));
                            }
                        } }))))))),
        (hiddenCols || []).indexOf('tags') == -1 && !hideTags && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { style: { paddingTop: '3px' }, className: `cell-padding hover-toggle center-align ${readOnly ? '' : 'clickable'} ${((_e = currentTransaction.tags) === null || _e === void 0 ? void 0 : _e.length) > 0 ? '' : 'no-data'}`, onClick: () => {
                    if (preventModal) {
                        return;
                    }
                } }, tagsUI))),
        (showReviewed || !preventModal) && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            isSaving && (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-circle no-wrap no-data` },
                React.createElement(semantic_ui_react_1.Loader, { size: "mini", active: true, inline: true, className: "margin-5" }))),
            !currentTransaction.is_pending &&
                !isSaving &&
                showReviewed &&
                (isCleared ? (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-circle no-wrap no-data clickable`, onClick: () => {
                        if (currentTransaction.status === 'cleared') {
                            update(currentTransaction.id, {
                                status: 'uncleared',
                            });
                            setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { status: 'uncleared' }));
                            setIsCleared(false);
                        }
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }))) : (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-circle no-wrap no-data clickable`, onClick: () => {
                        update(currentTransaction.id, {
                            status: 'cleared',
                        });
                        setCurrentTransaction(Object.assign(Object.assign({}, currentTransaction), { status: 'cleared' }));
                        setIsCleared(true);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle", className: 'green-on-hover uncleared-button color--grey' })))),
            !isSaving &&
                showReviewed &&
                !currentTransaction.is_pending &&
                currentTransaction.status == 'delete_pending' && (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-circle no-wrap no-data` },
                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }), hoverable: true, position: 'bottom center', open: openDeletePending[currentTransaction.id] },
                    React.createElement("p", null,
                        "This transaction was marked as deleted by",
                        ' ',
                        currentTransaction.institution_name,
                        ". We've removed this transaction from any calculations and we recommend that you double-check if this transaction should be kept or deleted."),
                    React.createElement("p", null,
                        React.createElement("a", { className: "display--block clickable hover--bold", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                const results = yield utils._process(transactions_1.deleteTransaction)(currentTransaction.id, {});
                                if (!results.error) {
                                    utils._showToast({
                                        message: 'Successfully deleted transaction',
                                        type: 'success',
                                    });
                                    utils.amendTransactions({
                                        remove: [currentTransaction.id],
                                    });
                                }
                            }) },
                            React.createElement("b", null, "Delete transaction")),
                        React.createElement("span", { className: "display--block" }, "or"),
                        React.createElement("a", { className: "display--block clickable hover--bold", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                const results = yield utils._process(transactions_1.updateTransaction)(currentTransaction.id, {
                                    status: 'cleared',
                                });
                                if (!results.error) {
                                    utils._showToast({
                                        message: 'Successfully updated transaction',
                                        type: 'success',
                                    });
                                    utils.amendTransactions({
                                        update: {
                                            id: currentTransaction.id,
                                            status: 'cleared',
                                        },
                                    });
                                }
                            }) },
                            React.createElement("b", null, "Keep transaction")))))),
            !isSaving && showReviewed && currentTransaction.is_pending && (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-circle no-wrap no-data` },
                React.createElement(semantic_ui_react_1.Icon, { name: "clock outline", className: "color--grey" }))),
            !preventModal && currentTransaction.status !== 'delete_pending' && (React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-arrow no-wrap no-data clickable`, onClick: () => {
                    onOpenModal('DETAILS_VIEW', currentTransaction);
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: ((_f = currentTransaction.files) === null || _f === void 0 ? void 0 : _f.length) > 0
                        ? 'attach'
                        : 'angle right', className: `green-on-hover color--grey` })))))));
};
exports.default = TransactionRow;
