"use strict";
/**
 *  EditableCategory.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const TagsProvider_1 = require("@providers/TagsProvider");
const EditableTags = ({ allowAdd = true, defaultOpen = false, multiple = true, placeholder = 'Type to search or add', onChange = null, onClose = null, onBlur = null, useName = false, initialValue = [], showUntagged = false, className = null, hideArchived = true, }) => {
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const [tagOptions, setTagOptions] = (0, react_1.useState)([]);
    const [startingValue, setStartingValue] = (0, react_1.useState)(initialValue);
    (0, react_1.useEffect)(() => {
        var _a;
        const options = (_a = _tags.tags) === null || _a === void 0 ? void 0 : _a.filter(o => !(hideArchived && o.archived)).map(o => {
            return {
                key: o.id,
                value: useName ? o.name : o.id,
                text: o.name,
            };
        });
        const concat = [];
        if (showUntagged) {
            concat.push({
                key: '___Untagged',
                value: -1,
                text: 'Untagged',
            });
        }
        const dropdownOptions = concat.concat(options);
        setTagOptions(dropdownOptions);
        if (initialValue.length > 0) {
            // Sanity check.
            // Go through initial value. any values that are not numeric, try to find
            // the numeric equivalent
            // This is useful for NewTransactionRow
            const newInitialValue = initialValue.map(tag => {
                if (tag === null || tag === void 0 ? void 0 : tag.hasOwnProperty('id')) {
                    return tag.id;
                }
                else if (isNaN(tag)) {
                    // Try to match
                    const matched = dropdownOptions.find(o => o.text == tag);
                    if (!!matched) {
                        return matched.value;
                    }
                    else {
                        return tag;
                    }
                }
                else {
                    return tag;
                }
            });
            setStartingValue(newInitialValue);
        }
    }, [_tags.tags, hideArchived]);
    return (React.createElement(semantic_ui_react_1.Dropdown, { id: 'editable-tags-dropdown', className: `${className}
        p-category-modal category-dropdown multiselect-search-dropdown`, placeholder: placeholder, fluid: true, multiple: multiple, defaultOpen: defaultOpen, search: true, additionPosition: 'bottom', allowAdditions: allowAdd, selection: true, closeOnBlur: true, onOpen: () => {
            if (defaultOpen) {
                setTimeout(() => {
                    var _a, _b;
                    (_b = (_a = document
                        .getElementById('editable-tags-dropdown')) === null || _a === void 0 ? void 0 : _a.getElementsByTagName('input')[0]) === null || _b === void 0 ? void 0 : _b.focus();
                }, 100);
            }
        }, onClose: onClose, value: multiple ? startingValue : startingValue[0], onAddItem: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { value }) {
            if (!allowAdd)
                return;
            // Create the tag
            const id = yield _tags.insertTag({ name: value });
            setStartingValue(multiple ? [...startingValue, id] : [id]);
            onChange(multiple ? [...startingValue, id] : id);
        }), onBlur: onBlur, onChange: (e_2, _b) => __awaiter(void 0, [e_2, _b], void 0, function* (e, { value }) {
            const currentValue = multiple ? value : [value];
            if (currentValue.find(o => isNaN(parseInt(o.toString())))) {
                // New item added, will be handled by onAddItem
                return;
            }
            if (onChange) {
                setStartingValue(currentValue);
                onChange(multiple ? currentValue : currentValue[0]);
            }
        }), options: tagOptions }));
};
exports.default = EditableTags;
