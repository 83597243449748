"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const Moment = require("moment");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const _ = require("lodash");
const semantic_ui_react_1 = require("semantic-ui-react");
const AccountSummary_1 = require("@components/Dashboard/AccountSummary");
const LinkedAccounts_1 = require("@components/TaskCard/LinkedAccounts");
const Transactions_1 = require("@components/TaskCard/Transactions");
const RecurringExpenses_1 = require("@components/TaskCard/RecurringExpenses");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const SpendingBreakdownContainer_1 = require("@/components/Overview/SpendingBreakdownContainer");
const Summary_1 = require("@components/Overview/Summary");
const PeriodPicker_1 = require("@components/QueryTool/PeriodPicker");
const summary_1 = require("@actions/summary");
const UserProvider_1 = require("@providers/UserProvider");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const Budget_1 = require("@/components/TaskCard/Budget");
const budget_1 = require("@/helpers/budget");
const Main = ({ _process, _showToast, _removeToast }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isReloading, setIsReloading] = (0, react_1.useState)(false);
    const [summary, setSummary] = (0, react_1.useState)(null);
    // Task cards
    const [missingCount, setMissingCount] = (0, react_1.useState)(undefined);
    const [suggestedCount, setSuggestedCount] = (0, react_1.useState)(undefined);
    const [unclearedCount, setUnclearedCount] = (0, react_1.useState)(undefined);
    const [leftToBudget, setLeftToBudget] = (0, react_1.useState)(undefined);
    // Dates
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    // Transaction popup
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedQuery, setSelectedQuery] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Home - Lunch Money';
        init();
    }, []);
    (0, react_1.useEffect)(() => {
        if (startDate && endDate) {
            getData();
            fetchBudgets();
        }
    }, [startDate, endDate]);
    const fetchBudgets = () => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield (0, summary_1.getSummary)({
            start_date: startDate,
            end_date: endDate,
            include_exclude_from_budgets: true,
            include_occurrences: false,
            include_recurring: true,
            include_totals: true,
            include_rollover_pool: true,
            include_budget_properties: false,
        });
        console.log({ Main: results });
        setSummary(results);
        setLeftToBudget((0, budget_1.getLeftToBudget)(results));
    });
    // If changes are detected from the period picker, it comes here
    (0, react_1.useEffect)(() => {
        if (history.location.search.length > 0) {
            const dateObj = qs.parse(history.location.search);
            setTimePeriods(dateObj);
        }
    }, [history.location.search]);
    const init = () => {
        if (_user.settings['overview_time_period']) {
            setTimePeriods({ date_range: _user.settings['overview_time_period'] });
        }
        else {
            // No default yet
            setTimePeriods({ date_range: 'This month' });
        }
    };
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsReloading(true);
        const snapshot = yield _process(summary_1.getMainPage)({
            start_date: startDate,
            end_date: endDate,
        });
        const unmatched = yield _recurring.getUnmatched(startDate, endDate);
        const allMissing = _.flatten(unmatched.map(o => (o === null || o === void 0 ? void 0 : o.missing_dates_within_range) || []));
        // count what's missing between startDate and endDate
        setMissingCount(allMissing.length || 0);
        setSuggestedCount(snapshot.suggestedCount || 0);
        setUnclearedCount(snapshot.unclearedCount);
        setIsLoading(false);
        setIsReloading(false);
    });
    const setTimePeriods = (dateObj = null) => {
        if (dateObj == null) {
            dateObj = {
                date_range: _user.settings['overview_time_period'] || 'Month to date',
            };
        }
        let _startDate;
        let _endDate;
        if (dateObj['date_range'] == 'This month') {
            _startDate = Moment()
                .startOf('month')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'This year') {
            _startDate = Moment()
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('year')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Month to date') {
            _startDate = Moment()
                .startOf('month')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Year to date') {
            _startDate = Moment()
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 7 days') {
            _startDate = Moment()
                .subtract(6, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 14 days') {
            _startDate = Moment()
                .subtract(13, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 30 days') {
            _startDate = Moment()
                .subtract(29, 'days')
                .format('YYYY-MM-DD');
            _endDate = Moment().format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last month') {
            _startDate = Moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 3 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(2, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 6 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(5, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last 12 months') {
            _startDate = Moment()
                .startOf('month')
                .subtract(11, 'months')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .endOf('month')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['date_range'] == 'Last year') {
            _startDate = Moment()
                .subtract(1, 'year')
                .startOf('year')
                .format('YYYY-MM-DD');
            _endDate = Moment()
                .subtract(1, 'year')
                .endOf('year')
                .format('YYYY-MM-DD');
        }
        else if (dateObj['start_date'] && dateObj['end_date']) {
            _startDate = dateObj['start_date'];
            _endDate = dateObj['end_date'];
        }
        if (_startDate && _endDate) {
            setStartDate(_startDate);
            setEndDate(_endDate);
        }
        if (dateObj['date_range'] &&
            _user.settings['overview_time_period'] != dateObj['date_range']) {
            _user.updateSetting('overview_time_period', dateObj['date_range']);
        }
    };
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-dashboard" },
        React.createElement(ContainerHeader_1.default, { title: _user.name ? `Welcome ${_user.name}!` : `Welcome!` }),
        React.createElement(PeriodPicker_1.default, { showArrows: true, disabled: false, firstValue: _user.settings['overview_time_period'] || 'This month', className: 'display--inline-block overview-period-picker', useSelectedDate: true }),
        React.createElement("div", { className: "summary-container" },
            React.createElement("div", { className: "left-container" },
                React.createElement("div", { className: "left" },
                    React.createElement(AccountSummary_1.default, { _process: _process, _showToast: _showToast }),
                    React.createElement(Summary_1.default, { totals: summary === null || summary === void 0 ? void 0 : summary.totals, isLoading: isLoading || isReloading, startDate: startDate, endDate: endDate, openTable: query => {
                            setSelectedQuery(query);
                            setOpenTransactionsTable(true);
                        } })),
                React.createElement("div", { className: "center" },
                    React.createElement(SpendingBreakdownContainer_1.default, { summary: summary, isLoading: isReloading, startDate: startDate, endDate: endDate, openTable: query => {
                            setSelectedQuery(query);
                            setOpenTransactionsTable(true);
                        } }))),
            React.createElement("div", { className: "right" },
                React.createElement(Transactions_1.default, { unclearedCount: unclearedCount, hideQuestionMark: true, isLoading: isReloading, startDate: startDate, endDate: endDate }),
                React.createElement(Budget_1.default, { leftToBudget: leftToBudget }),
                React.createElement(RecurringExpenses_1.default, { suggestedCount: suggestedCount, missingCount: missingCount, hideQuestionMark: true, isLoading: isReloading }),
                React.createElement(LinkedAccounts_1.default, { onClick: type => {
                        if (type == 'has-error') {
                            history.push('/accounts?filter=error');
                        }
                        else {
                            history.push('/accounts');
                        }
                    }, hideQuestionMark: true }))),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedQuery({});
                setOpenTransactionsTable(false);
            }), headerText: `Transactions matching query`, query: Object.assign({ time: 'all', match: 'all' }, selectedQuery), _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = Main;
