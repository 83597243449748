"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const AccountStatus_1 = require("./AccountStatus");
const react_1 = require("react");
const assets_1 = require("@actions/assets");
const AccountSummary = ({ start_date, end_date, displayCurrency, setTriggerRefresh, triggerRefresh, flipSign, }) => {
    const [accountsAndAssets, setAccountsAndAssets] = (0, react_1.useState)([]);
    const [accountsUI, setAccountsUI] = (0, react_1.useState)(null);
    const [cashTotal, setCashTotal] = (0, react_1.useState)(null);
    const [total, setTotal] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const fetchAccountsAndAssets = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoading(true);
                const results = yield (0, assets_1.getAssetSummary)({
                    start_date,
                    end_date,
                    currency: displayCurrency,
                });
                setAccountsAndAssets(results.summary);
                setTotal(results.total);
                setCashTotal(results.cash);
                setIsLoading(false);
                setTriggerRefresh();
            }
            catch (e) {
                console.log('Error loading asset summary', e);
            }
        });
        fetchAccountsAndAssets();
    }, [triggerRefresh]);
    (0, react_1.useEffect)(() => {
        if ((!accountsAndAssets || accountsAndAssets.length == 0) &&
            cashTotal == 0) {
            setAccountsUI(null);
            return;
        }
        try {
            const accounts = accountsAndAssets
                .filter(o => {
                return o.base_total !== 0;
            })
                .concat({
                display_name: 'Cash',
                base_total: cashTotal,
                baseCurrency: displayCurrency,
            })
                .sort((a, b) => {
                if (flipSign) {
                    return a.base_total - b.base_total;
                }
                else {
                    return b.base_total - a.base_total;
                }
            })
                .map((account, index) => {
                return (React.createElement(AccountStatus_1.default, { key: `account-summary-${index}`, account: Object.assign(Object.assign({}, account), { baseCurrency: displayCurrency }), flipSign: flipSign }));
            });
            setAccountsUI(accounts);
            setIsLoading(false);
        }
        catch (e) {
            console.log('Error generating account rows', e);
        }
    }, [accountsAndAssets, cashTotal, flipSign]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card.Content, null,
            isLoading && React.createElement(semantic_ui_react_1.Loader, { active: true, size: "tiny", inline: "centered" }),
            !isLoading && accountsUI && accountsUI.length > 0 && accountsUI,
            React.createElement("span", { className: "display--flex align-center" },
                React.createElement(react_router_dom_1.Link, { to: { pathname: `/accounts` } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle right" }),
                    ' ',
                    accountsUI && accountsUI.length > 0
                        ? 'Set up more accounts'
                        : 'Set up accounts'))),
        !isLoading && !!accountsUI && (React.createElement(semantic_ui_react_1.Card.Content, null, React.createElement(AccountStatus_1.default, { key: `account-summary-total`, account: {
                display_name: 'Total Spent',
                base_total: total,
                baseCurrency: displayCurrency,
            }, flipSign: flipSign })))));
};
exports.default = AccountSummary;
