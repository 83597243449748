"use strict";
/**
 *  AddModal.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const qs = require("query-string");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const ChooseType_1 = require("@components/LinkedAccounts/Modal/ChooseType");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCrypto_1 = require("@components/elements/EditableCrypto");
const assets_1 = require("@actions/assets");
const crypto_1 = require("@actions/crypto");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const StaticProvider_1 = require("@/providers/StaticProvider");
var AccountsModalView;
(function (AccountsModalView) {
    AccountsModalView[AccountsModalView["CHOOSE_TYPE"] = 1] = "CHOOSE_TYPE";
    AccountsModalView[AccountsModalView["CHOOSE_SUBTYPE"] = 2] = "CHOOSE_SUBTYPE";
    AccountsModalView[AccountsModalView["CHOOSE_NAME"] = 3] = "CHOOSE_NAME";
    AccountsModalView[AccountsModalView["SET_BALANCE_CURRENCY"] = 4] = "SET_BALANCE_CURRENCY";
})(AccountsModalView || (AccountsModalView = {}));
const AddModal = ({ data, useModal, utils, setVisibility, setDisableOnClickOutside, }) => {
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const history = (0, react_router_dom_1.useHistory)();
    const [step, setStep] = (0, react_1.useState)(1);
    const [prevStep, setPrevStep] = (0, react_1.useState)(null);
    const [typeId, setTypeId] = (0, react_1.useState)(null);
    const [typeName, setTypeName] = (0, react_1.useState)(null);
    const [subtypeId, setSubtypeId] = (0, react_1.useState)(null);
    const [subtypeName, setSubtypeName] = (0, react_1.useState)(null);
    const [name, setName] = (0, react_1.useState)('');
    const [displayName, setDisplayName] = (0, react_1.useState)(null);
    const [amount, setAmount] = (0, react_1.useState)(0);
    const [currency, setCurrency] = (0, react_1.useState)(_user.primaryCurrency);
    const [institutionName, setInstitutionName] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [crypto, setCrypto] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        useModal({
            header: React.createElement("span", { className: "header-text-large" }, "Add Account or Asset"),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, step > 1 ? 'Cancel' : 'Close')),
        });
        return () => {
            useModal({
                header: null,
                leftButton: null,
            });
        };
    }, []);
    (0, react_1.useEffect)(() => {
        useModal({
            middleButton: step > AccountsModalView.CHOOSE_TYPE ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    goBack();
                }) }, "Back")) : (React.createElement(React.Fragment, null)),
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", loading: isLoading, disabled: isLoading ||
                    (!(step === AccountsModalView.CHOOSE_TYPE && typeId && typeName) &&
                        !(step === AccountsModalView.CHOOSE_SUBTYPE &&
                            ((subtypeId && subtypeName && typeName !== 'cryptocurrency') ||
                                (crypto && typeName == 'cryptocurrency'))) &&
                        !(step === AccountsModalView.CHOOSE_NAME && name) &&
                        !(step === AccountsModalView.SET_BALANCE_CURRENCY)), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (step !== AccountsModalView.SET_BALANCE_CURRENCY) {
                        goNext();
                    }
                    else {
                        setIsLoading(true);
                        let results = null;
                        if (typeName == 'cryptocurrency') {
                            results = yield (0, crypto_1.createManual)({
                                name,
                                display_name: displayName,
                                institution_name: institutionName,
                                balance: amount,
                                coingecko_id: crypto,
                                currency_ref: _static.coinGeckoMap[crypto],
                            });
                            results.data.source = 'manual-crypto';
                        }
                        else {
                            results = yield (0, assets_1.createAsset)({
                                type_id: typeId == 9999 ? null : typeId,
                                type_name: typeName,
                                subtype_id: subtypeId == 9999 ? null : typeId,
                                subtype_name: subtypeName,
                                name,
                                display_name: displayName,
                                balance: amount,
                                currency,
                                institution_name: institutionName,
                            });
                        }
                        if (results.error) {
                            setIsLoading(false);
                            return utils._showToast({
                                message: results.error.message || results.error,
                                type: 'error',
                            });
                        }
                        else {
                            // Add to AssetsContext
                            _assets.addAsset(results.data);
                            // Reset steps
                            setStep(1);
                            // Reset states
                            setTypeId(null);
                            setTypeName(null);
                            setSubtypeId(null);
                            setSubtypeName(null);
                            setName('');
                            setAmount(0);
                            setCurrency(_user.primaryCurrency);
                            setInstitutionName(null);
                            // Save & close
                            setIsLoading(false);
                            setVisibility(false);
                            return utils._showToast({
                                message: 'Successfully added asset.',
                                type: 'success',
                            });
                        }
                    }
                }) }, step === AccountsModalView.SET_BALANCE_CURRENCY
                ? 'Save & Close'
                : 'Next')),
        });
        return () => {
            useModal({
                middleButton: null,
                rightButton: null,
            });
        };
    }, [
        step,
        typeId,
        typeName,
        subtypeId,
        subtypeName,
        crypto,
        name,
        displayName,
        amount,
        currency,
        institutionName,
        isLoading,
    ]);
    const goNext = () => {
        setDisableOnClickOutside(true);
        if (step === AccountsModalView.CHOOSE_TYPE &&
            (typeName === 'other asset' || typeName === 'other liability')) {
            setPrevStep(AccountsModalView.CHOOSE_TYPE);
            setStep(AccountsModalView.CHOOSE_NAME);
        }
        else {
            setPrevStep(step);
            setStep(step + 1);
        }
    };
    const goBack = () => {
        if (step === AccountsModalView.CHOOSE_NAME &&
            (typeName === 'other asset' || typeName === 'other liability')) {
            setStep(AccountsModalView.CHOOSE_TYPE);
            setPrevStep(null);
        }
        else {
            setStep(prevStep);
            setPrevStep(prevStep - 1);
        }
    };
    return (React.createElement(React.Fragment, null,
        step === AccountsModalView.CHOOSE_TYPE && (React.createElement(React.Fragment, null,
            React.createElement("h2", null, "What type of account would you like to add?"),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: () => {
                    utils.launchPlaidLink();
                } }, "Auto-import from my bank"),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", onClick: utils.openCryptoConnect, loading: isLoading }, "Link my cryptocurrency wallet"),
            React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement("p", null,
                    "Connecting your bank will allow us to import transactions from all your associated accounts, including credit cards and checking/savings accounts.",
                    ' '),
                React.createElement("p", null,
                    React.createElement("a", { className: "link clickable", href: "https://support.lunchmoney.app/importing-transactions/automatic-imports#i-cant-connect-to-my-institution", target: "_blank" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                        React.createElement("b", null, "I am having trouble connecting to my account."))),
                React.createElement("p", null,
                    React.createElement("span", { className: "link clickable", onClick: () => {
                            setDisableOnClickOutside(true);
                            history.push({
                                search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { show_feedback: 'institution-request' })),
                            });
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "help" }),
                        React.createElement("b", null, "My institution is not supported.")))),
            React.createElement("p", { className: "divider-line mt-1rem" },
                React.createElement("span", null, "or")),
            React.createElement("p", { className: "form-label center-align mb-05rem" }, "Create a manually-managed asset:"),
            React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement("p", null,
                    "For manually-managed assets, you may add transactions manually or use our CSV import feature, accessible via the",
                    ' ',
                    React.createElement(react_router_dom_1.Link, { to: { pathname: `/transactions` }, className: "link font--bold" }, "Transactions"),
                    ' ',
                    "page.")),
            React.createElement(ChooseType_1.default, { data: _assets.types, type: 'type', selectedType: typeId, addOther: false, selectType: type => {
                    setTypeId(type.id);
                    setTypeName(type.name);
                    // Reset things
                    setSubtypeId(null);
                    setSubtypeName(null);
                } }))),
        step === AccountsModalView.CHOOSE_SUBTYPE && (React.createElement(React.Fragment, null,
            false && typeName === 'cryptocurrency' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                "Did you know you can connect directly to your cryptocurrency wallet to automatically sync balances? Go back and click on",
                ' ',
                React.createElement("b", null, "Link my cryptocurrency wallet"),
                " at the top.")),
            React.createElement("div", { id: "grouped-modal-detail-view" },
                React.createElement("div", { className: "transaction-details" }, typeName === 'cryptocurrency' ? (React.createElement(React.Fragment, null,
                    React.createElement("h2", null, "Which cryptocurrency would you like to track?"),
                    React.createElement(EditableCrypto_1.default, { className: "mb-1rem", onSave: (value, fullName) => {
                            setCrypto(value);
                            setName(fullName);
                        } }),
                    React.createElement(semantic_ui_react_1.Message, { className: "mb-1rem" },
                        "Don't see your token here?",
                        ' ',
                        React.createElement("span", { className: "link clickable", onClick: () => {
                                setDisableOnClickOutside(true);
                                history.push({
                                    search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { show_feedback: 'cryptocurrency-request' })),
                                });
                            } }, "Click here to request support for a new cryptocurrency.")))) : (React.createElement(React.Fragment, null,
                    React.createElement("h2", null,
                        "What type of ",
                        typeName,
                        " is this?"),
                    React.createElement(ChooseType_1.default, { data: _assets.subtypes[typeName], type: 'subtype', addOther: true, selectedType: subtypeId, selectType: type => {
                            setSubtypeId(type.subtype_id);
                            setSubtypeName(type.subtype_name);
                        } }))))))),
        step === AccountsModalView.CHOOSE_NAME && (React.createElement(React.Fragment, null,
            React.createElement("div", { id: "grouped-modal-detail-view" },
                React.createElement("div", { className: "transaction-details" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "What is the official name for this account?"),
                                React.createElement(EditableString_1.default, { placeholder: "Cashback Mastercard", identifier: 'add-account-name', autoFocus: true, shouldSaveOnBlur: false, shouldSaveOnChange: true, location: 'modal', state: 'Editing', firstValue: name, onSave: value => {
                                        setName(value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "What is the name of the institution associated? (optional)"),
                                React.createElement(EditableString_1.default, { placeholder: typeName === 'cryptocurrency'
                                        ? 'Coinbase'
                                        : 'Bank of America', identifier: 'add-institution-name', autoFocus: false, shouldSaveOnBlur: false, shouldSaveOnChange: true, location: 'modal', state: 'Editing', firstValue: institutionName, onSave: value => {
                                        setInstitutionName(value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "Give this account a nickname to be used throughout Lunch Money (optional)"),
                                React.createElement(EditableString_1.default, { placeholder: typeName === 'cryptocurrency'
                                        ? ''
                                        : `${_user.name}'s Credit Card`, identifier: 'add-display-name', autoFocus: false, shouldSaveOnBlur: false, shouldSaveOnChange: true, location: 'modal', state: 'Editing', firstValue: displayName, onSave: value => {
                                        setDisplayName(value);
                                    } })))))))),
        step === AccountsModalView.SET_BALANCE_CURRENCY && (React.createElement("div", { id: "grouped-modal-detail-view" },
            React.createElement("div", { className: "transaction-details" },
                typeName === 'cryptocurrency' ? (React.createElement("h2", null,
                    "How much ",
                    _static.coinGeckoMap[crypto],
                    " do you hold?")) : (React.createElement("h2", null, "What's the current value or balance for this account?")),
                React.createElement("p", null, "If you're not sure, an estimation will suffice. You can update this anytime."),
                (typeName === 'loan' || typeName === 'credit') && (React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement("p", null,
                        "The balance for loan accounts, such as credit cards, mortgages, car loans, etc, represent ",
                        React.createElement("b", null, "how much is owed"),
                        ' ',
                        "on the account."),
                    React.createElement("p", null,
                        ' ',
                        "A positive balance indicates how much debt you have and a negative balance indicates a credit on the account."),
                    React.createElement("p", null,
                        React.createElement("b", null,
                            "For ",
                            typeName,
                            " accounts, typically the balance will be positive.")))),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(EditablePrice_1.default, { identifier: "add-account-balance", location: 'modal', autoFocus: true, state: 'Editing', currency: typeName == 'cryptocurrency'
                                    ? _static.coinGeckoMap[crypto]
                                    : currency, disableCurrency: typeName == 'cryptocurrency', allowEmpty: true, saveOnChange: true, onSave: (amount, currency) => {
                                    setAmount(amount);
                                    if (typeName !== 'cryptocurrency') {
                                        setCurrency(currency);
                                    }
                                } })))))))));
};
exports.default = AddModal;
