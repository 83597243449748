"use strict";
/**
 *  EditCustomBudget.tsx
 *  Custom budgets add modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const custom_budgets_1 = require("@/actions/custom_budgets");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const EditableDate_1 = require("../elements/EditableDate");
const EditablePrice_1 = require("../elements/EditablePrice");
const SelectLineItem_1 = require("../global/SelectLineItem");
const budget_1 = require("@/helpers/budget");
const user_1 = require("@/actions/user");
const EditCustomBudget = ({ useModal, utils, data, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    // Notes
    const [notes, setNotes] = (0, react_1.useState)((_a = data.occurrences.find(o => !o.historical)) === null || _a === void 0 ? void 0 : _a.notes);
    // Quantity and granularity
    const [quantityValue, setQuantityValue] = (0, react_1.useState)(1);
    const [granularityValue, setGranularityValue] = (0, react_1.useState)('month');
    const [anchorDate, setAnchorDate] = (0, react_1.useState)(Moment()
        .startOf('year')
        .format('YYYY-MM-DD'));
    const [showCustomPeriod, setShowCustomPeriod] = (0, react_1.useState)(false);
    const [rolloverOption, setRolloverOption] = (0, react_1.useState)(null);
    const [rolloverStartDate, setRolloverStartDate] = (0, react_1.useState)(null);
    const [autoBudgetOption, setAutoBudgetOption] = (0, react_1.useState)('nothing');
    const [budgetAmount, setBudgetAmount] = (0, react_1.useState)(null);
    const [budgetCurrency, setBudgetCurrency] = (0, react_1.useState)(_user.primaryCurrency);
    const [showConfirm, setShowConfirm] = (0, react_1.useState)(false);
    const [budgetIncomeOption, setBudgetIncomeOption] = (0, react_1.useState)(_user.budgetPeriodSettings.budget_income_option);
    // Setup
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        setQuantityValue(data.properties.budget_settings.quantity);
        setGranularityValue(data.properties.budget_settings.granularity);
        setAnchorDate(data.properties.budget_settings.anchor_date);
        setRolloverStartDate(data.properties.budget_settings.rollover_start_date);
        setRolloverOption(data.properties.budget_settings.rollover_option);
        setShowCustomPeriod(!data.properties.budget_settings.is_aligned_with_budget);
        setAutoBudgetOption(data.selected_auto_budget_type || ((_a = data.properties.auto_budget) === null || _a === void 0 ? void 0 : _a.type));
        setBudgetAmount((_b = data.properties.auto_budget) === null || _b === void 0 ? void 0 : _b.amount);
        setBudgetCurrency((_c = data.properties.auto_budget) === null || _c === void 0 ? void 0 : _c.currency);
        if (!!data.selected_auto_budget_type) {
            setTimeout(() => {
                const modalContent = document.getElementById('modal-content');
                modalContent.scrollTop = modalContent.scrollHeight;
            }, 100);
        }
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" },
                    "Edit ",
                    _categories.getName(data.properties.category.id)))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: data.properties.category.is_group ? (React.createElement(React.Fragment, null)) : (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, []);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        let hasChanges = false;
        // Update budget settings
        if (budgetIncomeOption !== _user.budgetPeriodSettings.budget_income_option) {
            yield (0, user_1.updateAccountPreferences)({
                budget_income_option: budgetIncomeOption,
            });
            yield _user.fetchAccountSettings();
            hasChanges = true;
        }
        if (notes !== ((_b = data.occurrences.find(o => !o.historical)) === null || _b === void 0 ? void 0 : _b.notes)) {
            yield utils.onSaveBudget([
                {
                    category_id: data.properties.category.id,
                    start_date: data.occurrences.filter(o => !o.historical)[0].start_date,
                    notes: notes,
                },
            ]);
            hasChanges = true;
        }
        const updatedFields = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (quantityValue !== data.properties.budget_settings.quantity ||
            granularityValue !== data.properties.budget_settings.granularity
            ? { quantityValue: quantityValue }
            : {})), (granularityValue !== data.properties.budget_settings.granularity
            ? { granularityValue: granularityValue }
            : {})), (anchorDate !== data.properties.budget_settings.anchor_date
            ? { anchorDate: anchorDate }
            : {})), (rolloverOption !== data.properties.budget_settings.rollover_option
            ? { rolloverOption: rolloverOption }
            : {})), (rolloverStartDate !==
            data.properties.budget_settings.rollover_start_date
            ? { rolloverStartDate: rolloverStartDate }
            : {})), (autoBudgetOption !== data.properties.auto_budget.type
            ? { autoBudgetOption: autoBudgetOption }
            : {})), (budgetAmount !== data.properties.auto_budget.amount
            ? { budgetAmount: budgetAmount }
            : {})), (budgetCurrency !== data.properties.auto_budget.currency
            ? { budgetCurrency: budgetCurrency }
            : {}));
        if (((_c = Object.values(updatedFields)) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            yield utils._process(custom_budgets_1.createCustomBudget)([
                Object.assign({ filterType: 'category', filterValue: data.properties.category.id, rolloverOption, rolloverStartDate: rolloverOption == 'same category' ||
                        rolloverOption == 'available funds'
                        ? rolloverStartDate
                        : null, autoBudgetOption,
                    budgetAmount,
                    budgetCurrency }, updatedFields),
            ]);
            hasChanges = true;
        }
        if (hasChanges) {
            setVisibility(false);
        }
        else {
            setVisibility(false, true);
        }
    });
    const isValidDate = date => {
        return (0, budget_1.areDatesAligned)({
            date,
            anchorDate: rolloverOption == 'same category'
                ? anchorDate
                : _user.budgetPeriodSettings.anchor_date,
            granularityValue: rolloverOption == 'same category'
                ? granularityValue
                : _user.budgetPeriodSettings.granularity,
            quantityValue: rolloverOption == 'same category'
                ? quantityValue
                : _user.budgetPeriodSettings.quantity,
        });
    };
    // Setup
    (0, react_1.useEffect)(() => {
        var _a;
        useModal({
            rightButton: data.properties.category.is_group ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setVisibility(false, true);
                }) }, "Close")) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", loading: buttonIsLoading, disabled: budgetIncomeOption ==
                        _user.budgetPeriodSettings.budget_income_option &&
                        // Budget period changed
                        notes == ((_a = data.occurrences.find(o => !o.historical)) === null || _a === void 0 ? void 0 : _a.notes) &&
                        (showCustomPeriod ||
                            (data.properties.budget_settings.granularity ==
                                granularityValue &&
                                data.properties.budget_settings.quantity == quantityValue &&
                                data.properties.budget_settings.anchor_date == anchorDate &&
                                data.properties.budget_settings.rollover_option ==
                                    rolloverOption &&
                                (!rolloverOption ||
                                    data.properties.budget_settings.rollover_start_date ==
                                        rolloverStartDate) &&
                                data.properties.auto_budget.type == autoBudgetOption &&
                                ((autoBudgetOption !== 'fill' &&
                                    autoBudgetOption !== 'fixed') ||
                                    data.properties.auto_budget.amount == budgetAmount) &&
                                ((autoBudgetOption !== 'fill' &&
                                    autoBudgetOption !== 'fixed') ||
                                    data.properties.auto_budget.currency == budgetCurrency)) ||
                            (rolloverOption == 'same category' &&
                                !isValidDate(rolloverStartDate)) ||
                            ((autoBudgetOption == 'fill' || autoBudgetOption == 'fixed') &&
                                !budgetAmount) ||
                            (rolloverOption == 'available funds' && showCustomPeriod)), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (data.properties.budget_settings.quantity !== quantityValue ||
                            data.properties.budget_settings.granularity !== granularityValue) {
                            setDisableOnClickOutside(true);
                            setShowConfirm(true);
                        }
                        else {
                            setButtonIsLoading(true);
                            yield submit();
                            setButtonIsLoading(false);
                        }
                    }) }, "Save Changes"))),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [
        data,
        notes,
        buttonIsLoading,
        quantityValue,
        granularityValue,
        anchorDate,
        rolloverOption,
        rolloverStartDate,
        autoBudgetOption,
        budgetAmount,
        budgetCurrency,
        showCustomPeriod,
        budgetIncomeOption,
    ]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null,
                "Notes for",
                ' ',
                Moment(data.periodStart).format(_user.getFormat('month_day')),
                " to",
                ' ',
                Moment(data.periodEnd).format(_user.getFormat('month_day'))),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(semantic_ui_react_1.TextArea, { rows: 2, value: notes, onChange: (e, { value }) => {
                                setNotes(value);
                                setDisableOnClickOutside(true);
                            } }))))),
        !data.properties.budget_settings.is_aligned_with_budget && (React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail mb-05rem" },
                React.createElement("h3", null, "Set Budget Period"),
                React.createElement("div", { className: "transaction-detail" },
                    showCustomPeriod && (React.createElement(semantic_ui_react_1.Message, { error: true },
                        React.createElement("p", null, "We are temporarily no longer supporting custom budgeting periods for individual categories as we continue to refine and improve this subfeature!"),
                        React.createElement("p", null, "Please update this category to align with the overall budget. It will be done for you automatically when we move to beta. Thanks!"))),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, disabled: data.properties.category.is_group, selection: true, className: "category-dropdown p-category-modal", value: showCustomPeriod ? 'custom' : 'aligned', placeholder: "Select type", onChange: (e, { value }) => {
                                        if (value == 'aligned') {
                                            setQuantityValue(_user.budgetPeriodSettings.quantity);
                                            setGranularityValue(_user.budgetPeriodSettings.granularity);
                                            setAnchorDate(_user.budgetPeriodSettings.anchor_date);
                                        }
                                        setShowCustomPeriod(value == 'custom');
                                    }, options: [
                                        {
                                            key: 'aligned',
                                            value: 'aligned',
                                            text: `Align to budget (Every ${_user.budgetPeriodSettings.quantity} ${_user.budgetPeriodSettings.granularity}${_user.budgetPeriodSettings.quantity > 1 ? 's' : ''})`,
                                        },
                                        {
                                            key: 'custom',
                                            value: 'custom',
                                            text: 'By custom period',
                                        },
                                    ] })))),
                    data.properties.category.is_group && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" }, "Category groups will always align to overall budget.")),
                    showCustomPeriod && (React.createElement("div", null,
                        React.createElement("div", { className: "flex--space-between" },
                            React.createElement("label", { style: {
                                    display: 'flex',
                                    position: 'relative',
                                    top: '2px',
                                    whiteSpace: 'nowrap',
                                    marginRight: '5px',
                                } }, "Reset budget every"),
                            React.createElement(semantic_ui_react_1.Form.Input, { type: "text", placeholder: "30", action: true },
                                React.createElement(semantic_ui_react_1.Input, { style: {
                                        width: '120px',
                                        height: '38px',
                                        paddingRight: '3px',
                                    }, value: quantityValue, className: "p-input-modal", error: quantityValue < 1 || isNaN(quantityValue), onChange: (e, { value }) => {
                                        setDisableOnClickOutside(true);
                                        if (Number(value) < 1 || isNaN(Number(value))) {
                                            setQuantityValue(value);
                                            //   onSave({ quantity: value })
                                        }
                                        else {
                                            setQuantityValue(Number(value));
                                            //   onSave({ quantity: Number(value) })
                                        }
                                    } }),
                                React.createElement(semantic_ui_react_1.Form.Select, { compact: true, placeholder: "Select", value: granularityValue, options: [
                                        'day(s)',
                                        'week(s)',
                                        'month(s)',
                                        'year(s)',
                                    ].map(o => {
                                        return {
                                            key: o,
                                            value: o.replace('(s)', ''),
                                            text: o,
                                        };
                                    }), onChange: (e, { value }) => {
                                        setDisableOnClickOutside(true);
                                        setGranularityValue(value.toString());
                                    } }))),
                        React.createElement("div", { className: "transaction-detail mt-05rem" },
                            React.createElement("label", null,
                                "Anchor date",
                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                                    React.createElement("p", null, "Specify any date expected to be the beginning of a budget period. This will be used to calculate expected budget periods."),
                                    React.createElement("p", null, "Note that this is simply used as an anchor\u2013 this category will be able to be budgeted before this specified date."))),
                            React.createElement(semantic_ui_react_1.Form, null,
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement(EditableDate_1.default, { identifier: "recurring-billing-date", firstValue: anchorDate, placeholder: Moment()
                                                .startOf('year')
                                                .format(_user.getFormat('month_day_year')), state: 'Editing', location: 'modal', format: 'month_day_year', onSave: date => {
                                                setAnchorDate(date);
                                            } }))))))),
                    (data.properties.budget_settings.quantity !== quantityValue ||
                        data.properties.budget_settings.granularity !==
                            granularityValue) && (React.createElement(semantic_ui_react_1.Message, { error: true },
                        "By changing the budgeting period, all historical budgets for",
                        ' ',
                        React.createElement("b", null, _categories.getName(data.properties.category.id)),
                        " will be removed.")))))),
        !data.properties.category.is_group && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null,
                "Rollover Options",
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                    React.createElement("p", null,
                        "Choose how to manage your over- and underspending for",
                        ' ',
                        _categories.getName(data.properties.category.id),
                        "."))),
            rolloverOption == 'same category' &&
                data.properties.category.is_income && (React.createElement(semantic_ui_react_1.Message, { error: true }, "We highly recommend against setting income categories to roll over to the same category. Please opt instead to rollover income categories to general pool next period.")),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null, [
                        {
                            key: 'same category',
                            value: 'same category',
                            text: `Rollover to same category next period`,
                            disabled: data.properties.category.is_income,
                        },
                        {
                            key: 'available funds',
                            value: 'available funds',
                            text: `Rollover to general pool next period ${!!data.properties.category.is_income
                                ? `(Recommended)`
                                : ''}`,
                            disabled: showCustomPeriod,
                        },
                        {
                            key: 'none',
                            value: null,
                            text: `Do nothing (Not recommended)`,
                        },
                    ].map(o => (React.createElement(SelectLineItem_1.default, { keyValue: o.value, selectedValue: rolloverOption, disabled: o.disabled, displayOnSelect: React.createElement(React.Fragment, null,
                            rolloverOption == null && (React.createElement("div", { className: "mt-05rem" }, "If any categories will be rolling over into general pool, we highly recommend setting income categories to also roll into general pool. If not, then the calculations may not be accurate.")),
                            (rolloverOption == 'same category' ||
                                rolloverOption == 'available funds') &&
                                (_user.budgetPeriodSettings
                                    .budget_rollover_start_date ? (React.createElement("div", { className: "mt-1rem" },
                                    "Rollover starts from",
                                    ' ',
                                    Moment(_user.budgetPeriodSettings
                                        .budget_rollover_start_date).format(_user.getFormat('month_day_year')),
                                    ". To update this, go to",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Button, { className: "ml-05rem", size: "mini", onClick: () => {
                                            switchView('EDIT_BUDGET_SETTINGS');
                                        } }, "Budget Settings"))) : (React.createElement("div", { className: "transaction-detail mt-05rem mb-0" },
                                    React.createElement("label", null,
                                        "Rollover starts from",
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                                            React.createElement("p", null, "Specify a date from which the rollover calculation will begin. For instance, if you pick the beginning of the current month, budgets and transaction activity prior to this month will not be involved in the calculation of the envelope value."),
                                            React.createElement("p", null, "Reset your rollover calculation at any time by resetting this date."))),
                                    React.createElement(semantic_ui_react_1.Form, null,
                                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                                React.createElement(EditableDate_1.default
                                                //   showError={!expense.billing_date}
                                                , { 
                                                    //   showError={!expense.billing_date}
                                                    identifier: "recurring-billing-date-2", firstValue: rolloverStartDate, state: 'Editing', location: 'modal', format: 'month_day_year', showError: !isValidDate(rolloverStartDate), onSave: anchor_date => {
                                                        setDisableOnClickOutside(true);
                                                        setRolloverStartDate(anchor_date);
                                                    }, filterDate: date => {
                                                        return isValidDate(date);
                                                    } })))),
                                    !isValidDate(rolloverStartDate) && (React.createElement(semantic_ui_react_1.Message, { error: true, className: "mb-1rem" }, "The rollover start date must match the current category's budgeting period.")))))), label: o.text, onClick: value => {
                            setRolloverOption(value);
                            if ((value == 'same category' ||
                                value == 'available funds') &&
                                !rolloverStartDate) {
                                setRolloverStartDate(data.occurrences.filter(o => !o.historical)[0]
                                    .start_date);
                            }
                        } })))))))), //!data.properties.category.is_income &&
        !data.properties.category.is_group && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "One-click budgeting"),
            data.properties.category.is_group && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" }, "Category groups will be automatically set to the sum of the subcategory budgets.")),
            autoBudgetOption !== 'nothing' && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" },
                "To set budgets for the current period, click on the",
                ' ',
                React.createElement("b", null, "Set One-Click Budgets"),
                " ",
                React.createElement(semantic_ui_react_1.Icon, { name: "magic", fitted: true }),
                " button.")),
            !data.properties.category.is_group && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Form, { className: "mb-0" },
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null, [
                            {
                                key: 'fixed',
                                value: 'fixed',
                                text: `Set fixed amount`,
                            },
                            data.properties.category.is_income
                                ? null
                                : {
                                    key: 'fill',
                                    value: 'fill',
                                    text: "Fill 'Available' up to a certain amount",
                                },
                            {
                                key: 'spend',
                                value: 'spend',
                                text: `Set to previous period's ${data.properties.category.is_income
                                    ? 'activity'
                                    : 'spend'}`,
                            },
                            {
                                key: 'budget',
                                value: 'budget',
                                text: `Set to previous period's ${data.properties.category.is_income
                                    ? 'expected'
                                    : 'budget'}`,
                            },
                            {
                                key: 'average-3',
                                value: 'average-3',
                                text: `Set to average ${data.properties.category.is_income
                                    ? 'activity'
                                    : 'spend'} of last 3 periods`,
                            },
                            {
                                key: 'nothing',
                                value: 'nothing',
                                text: `Do nothing`,
                            },
                        ]
                            .filter(o => !!o)
                            .map(o => (React.createElement(SelectLineItem_1.default, { keyValue: o.key, selectedValue: autoBudgetOption, displayOnSelect: React.createElement(React.Fragment, null, (autoBudgetOption == 'fixed' ||
                                autoBudgetOption == 'fill') && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Set amount"),
                                    React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: budgetAmount, currency: budgetCurrency, location: 'modal', autoFocus: !!data.selected_auto_budget_type, state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                                            setBudgetAmount(amount);
                                            setBudgetCurrency(currency);
                                        }) }))))), label: o.text, onClick: value => {
                                setAutoBudgetOption(value);
                            } })))))))))),
        data.properties.category.is_income && (React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail mb-05rem" },
                React.createElement("h3", null, "Global Settings"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement("label", null, "Base income on"),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, disabled: false, selection: true, className: "category-dropdown p-category-modal", value: budgetIncomeOption, placeholder: "Select type", onChange: (e, { value }) => {
                                        setDisableOnClickOutside(true);
                                        setBudgetIncomeOption(value);
                                    }, options: [
                                        {
                                            key: 'budgeted',
                                            value: 'budgeted',
                                            text: 'Budgeted Income',
                                        },
                                        {
                                            key: 'actvitiy',
                                            value: 'actvitiy',
                                            text: 'Actual Income Activity',
                                        },
                                        {
                                            key: 'max',
                                            value: 'max',
                                            text: 'Larger of Budgeted and Actual Income Activity',
                                        },
                                    ] })))))))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showConfirm, closeOnEscape: false, closeOnDocumentClick: false, closeOnDimmerClick: false, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Warning: Action will permanently remove budget history"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        "By changing the budgeting period, all historical budgets for",
                        ' ',
                        React.createElement("b", null, _categories.getName(data.properties.category.id)),
                        " will be deleted. To preserve historical budget data, we recommend you create a new category instead."),
                    React.createElement("p", null,
                        React.createElement("b", null, "This is a nuclear option and cannot be reversed.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, onClick: () => {
                        setShowConfirm(false);
                    }, negative: true }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setButtonIsLoading(true);
                        yield submit();
                        utils.onShowOccurrences(data.properties.category.id, true);
                        setButtonIsLoading(false);
                        setShowConfirm(false);
                    }) }, "Confirm")))));
};
exports.default = EditCustomBudget;
