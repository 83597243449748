"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
// Actions
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@/providers/UserProvider");
const Budget_1 = require("../TaskCard/Budget");
const Overview = ({ oneClickBudgetBtn, clearAllBudgetsBtn, copyBudgetBtn, leftToBudget, totalBudgeted, totalAvailableOutflow, totalAvailableInflow, periodStart, periodEnd, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(Budget_1.default, { leftToBudget: leftToBudget }),
        React.createElement("div", { className: "mb-05rem display--flex flex--column" },
            oneClickBudgetBtn,
            copyBudgetBtn,
            clearAllBudgetsBtn),
        React.createElement(semantic_ui_react_1.Card, null,
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, null, "Budget Overview")),
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement("div", { className: `card-content-wrapper` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis font--bold" }, "Available to Budget"),
                        React.createElement("span", { className: `card-number` }, (0, format_1.toPrice)(leftToBudget + totalBudgeted, _user.primaryCurrency)))),
                React.createElement("div", { className: `card-content-wrapper` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis font--bold" }, "Total Budgeted"),
                        React.createElement("span", { className: `card-number` }, (0, format_1.toPrice)(totalBudgeted, _user.primaryCurrency)))),
                React.createElement("p", { className: "divider" }),
                React.createElement("div", { className: `card-content-wrapper` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis font--bold" }, "Left To Budget"),
                        React.createElement("span", { className: `card-number ${leftToBudget > 0
                                ? 'color--green'
                                : leftToBudget < 0
                                    ? 'color--red'
                                    : ''}` }, (0, format_1.toPrice)(leftToBudget, _user.primaryCurrency)))),
                React.createElement("div", { className: `card-content-wrapper` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis font--bold" }, "Budget Status"),
                        React.createElement("span", { className: `card-number ${leftToBudget == 0
                                ? 'color--green'
                                : leftToBudget < 0
                                    ? 'color--red'
                                    : 'color--yellow'}` }, leftToBudget < 0 ? ('Overbudgeted') : leftToBudget > 0 ? ('Underbudgeted') : (React.createElement(React.Fragment, null,
                            "Balanced ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" })))))),
                React.createElement("p", { className: "divider" }),
                React.createElement("div", { className: `card-content-wrapper` },
                    React.createElement("div", { className: "card-content no-wrap" },
                        React.createElement("span", { className: "card-text ellipsis font--bold" },
                            "Net Total Available",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "clickable" }), inverted: true, size: "small" },
                                React.createElement("p", null, "This is the total amount you have accounted for in your budget. You'll want to make sure that you have at least this amount of money in your accounts."),
                                React.createElement("div", { className: `line-item` },
                                    React.createElement("div", { className: "" }, "Left to Budget"),
                                    React.createElement("div", { className: `underline` }),
                                    React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(leftToBudget, _user.primaryCurrency))),
                                React.createElement("div", { className: `line-item` },
                                    React.createElement("div", { className: "" }, "Total Available Outflow"),
                                    React.createElement("div", { className: `underline` }),
                                    React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(totalAvailableOutflow, _user.primaryCurrency))),
                                React.createElement("div", { className: `line-item` },
                                    React.createElement("div", { className: "font--bold" }, "Net Total Available"),
                                    React.createElement("div", { className: `underline` }),
                                    React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(totalAvailableOutflow + leftToBudget, _user.primaryCurrency))))),
                        React.createElement("span", { className: `card-number` }, (0, format_1.toPrice)(leftToBudget + totalAvailableOutflow, _user.primaryCurrency))))))));
};
exports.default = Overview;
