"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const format_1 = require("@/helpers/format");
const TransferPopup = ({ category_id, category_group_id, start_date, available, activity, outflowBudgets, onMoveBudget, leftToBudget, }) => {
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [amount, setAmount] = (0, react_1.useState)(activity == 0 && available == 0 ? undefined : Math.abs(available));
    const [srcCategoryId, setSrcCategoryId] = (0, react_1.useState)(available > 0 ? category_id : leftToBudget > 0 ? -1 : null);
    const [destCategoryId, setDestCategoryId] = (0, react_1.useState)(available > 0 ? (leftToBudget > 0 ? null : -1) : category_id);
    const getDropdownItems = (budgets, isSource) => {
        return _.flatten(budgets.map(o => {
            return o.occurrences
                .filter(o => !o.historical)
                .map(occurrence => {
                const available = occurrence.is_aligned
                    ? (o.properties.budget_settings.rollover_option == 'same category'
                        ? o.totals['rollover_balance'] || 0
                        : 0) + o.totals['period_balance']
                    : (o.properties.budget_settings.rollover_option == 'same category'
                        ? occurrence['rollover_balance'] || 0
                        : 0) + occurrence['period_balance'];
                return {
                    key: `${o.properties.category.id}/${isSource ? 'source' : 'dest'}`,
                    value: o.properties.category.id,
                    text: `${_categories.getName(o.properties.category.id)}: ${(0, format_1.toPrice)(available, _user.primaryCurrency)}`,
                    disabled: (isSource && destCategoryId == o.properties.category.id) ||
                        (!isSource && srcCategoryId == o.properties.category.id) ||
                        o.properties.category.is_group,
                    content: (React.createElement("div", { className: "flex--space-between" },
                        React.createElement("div", null,
                            React.createElement("div", { className: o.properties.category.is_group ? `font--bold` : '' },
                                o.properties.category.is_child && (React.createElement("span", { className: "hierarchy-line-icon-5" })),
                                _categories.getName(o.properties.category.id)),
                            occurrence.is_aligned ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "font-size--smaller" },
                                Moment(occurrence.start_date).format(_user.getFormat('month_day')),
                                ' ',
                                "-",
                                ' ',
                                Moment(occurrence.end_date).format(_user.getFormat('month_day'))))),
                        React.createElement("div", { className: available > 0
                                ? 'color--green'
                                : available < 0
                                    ? 'color--red'
                                    : '' }, (0, format_1.toPrice)(available, _user.primaryCurrency)))),
                };
            });
        }));
    };
    const getDropdown = isSource => {
        return (React.createElement(semantic_ui_react_1.Dropdown, { className: `category-dropdown p-category-modal modal-dropdown mb-1rem`, placeholder: 'Select category', fluid: true, selection: true, 
            // search
            selectOnNavigation: false, selectOnBlur: false, closeOnEscape: true, closeOnChange: true, value: (isSource && !srcCategoryId) || (!isSource && !destCategoryId)
                ? undefined
                : isSource
                    ? srcCategoryId == -1
                        ? -1
                        : srcCategoryId
                    : destCategoryId == -1
                        ? -1
                        : destCategoryId, options: [
                {
                    key: 'header-inflow',
                    value: 'Inflow',
                    content: React.createElement("div", { className: "label-style" }, "Inflow"),
                    disabled: true,
                },
                ...(leftToBudget > 0
                    ? [
                        {
                            key: 'left-to-budget',
                            value: -1,
                            text: `Left to budget: ${(0, format_1.toPrice)(leftToBudget, _user.primaryCurrency)}`,
                            content: (React.createElement("div", { className: "flex--space-between" },
                                React.createElement("div", null, "Left to budget"),
                                React.createElement("div", { className: leftToBudget > 0 ? 'color--green' : 'color--red' }, (0, format_1.toPrice)(leftToBudget, _user.primaryCurrency)))),
                        },
                    ]
                    : []),
                // ...getDropdownItems(inflowBudgets, isSource),
                {
                    key: 'header-outflow',
                    value: 'Outflow',
                    content: React.createElement("div", { className: "label-style" }, "Outflow"),
                    disabled: true,
                },
                ...getDropdownItems(outflowBudgets, isSource),
            ], onChange: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { value }) {
                if (value == -1) {
                    if (isSource) {
                        setSrcCategoryId(-1);
                    }
                    else {
                        setDestCategoryId(-1);
                    }
                }
                else {
                    if (isSource) {
                        setSrcCategoryId(value);
                    }
                    else {
                        setDestCategoryId(value);
                    }
                }
            }) }));
    };
    return (React.createElement("div", { style: { width: '300px' } },
        React.createElement(semantic_ui_react_1.Form, { className: "mb-1rem" },
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "Move"),
                    React.createElement(semantic_ui_react_1.Form.Input, { value: amount, placeholder: (0, format_1.toPrice)(available - activity, _user.primaryCurrency), onChange: (e, { value }) => {
                            setAmount(value);
                        } }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement("label", null, "from"),
                    getDropdown(true))),
            React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-0", widths: "equal" },
                React.createElement(semantic_ui_react_1.Form.Field, { className: "mt-0 mb-0 flex--space-between-flex-start" },
                    React.createElement("label", null, "to"),
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "arrows alternate vertical", className: "clickable", onClick: () => {
                                const src = srcCategoryId;
                                const dest = destCategoryId;
                                setSrcCategoryId(dest);
                                setDestCategoryId(src);
                            } }), inverted: true, size: "tiny", content: "Swap to and from categories" }))),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    getDropdown(false),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: isLoading, disabled: isLoading ||
                            isNaN(Number(amount)) ||
                            !Number(amount) ||
                            Number(amount) < 0 ||
                            !srcCategoryId ||
                            !destCategoryId, size: "small", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            yield onMoveBudget({
                                src_category_id: srcCategoryId == -1 ? null : srcCategoryId,
                                dest_category_id: destCategoryId == -1 ? null : destCategoryId,
                                src_start_date: srcCategoryId == -1 ? null : start_date,
                                dest_start_date: destCategoryId == -1 ? null : start_date,
                                amount: Number(amount),
                                currency: _user.primaryCurrency,
                            });
                        }) }, "Move budget"))))));
};
exports.default = TransferPopup;
