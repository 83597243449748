"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_1 = require("react");
const format_1 = require("@helpers/format");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const NewModal_1 = require("@components/global/NewModal");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const StaticProvider_1 = require("@providers/StaticProvider");
const QueryLoader_1 = require("@components/global/QueryLoader");
const checks_1 = require("@/helpers/checks");
const NetWorthTable = ({ _process, _showToast, netWorthArray, timeArray, hiddenAccounts, balanceHistory, updateData, setExportableData, remove, update, fetch, }) => {
    const [sortKey, setSortKey] = (0, react_1.useState)('account_name');
    const reverseSorted = (0, react_1.useRef)(false);
    const [data, setData] = (0, react_1.useState)(null);
    const _static = (0, react_1.useContext)(StaticProvider_1.StaticContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    // UI
    const [timeRow, setTimeRow] = (0, react_1.useState)([]);
    const [accountsCol, setAccountsCol] = (0, react_1.useState)([]);
    const [dataRows, setDataRows] = (0, react_1.useState)([]);
    const [netWorthRow, setNetWorthRow] = (0, react_1.useState)([]);
    //
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    // Modal
    const [showModal, setShowModal] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    //
    const [rerender, setRerender] = (0, react_1.useState)(null);
    const netWorthArrayOverride = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (!balanceHistory)
            return;
        let sorted = balanceHistory.filter(o => {
            return hiddenAccounts[o._id] !== true;
        });
        if (sortKey == 'account_name') {
            sorted = sorted.sort((a, b) => {
                const str_a = a.display_name;
                const str_b = b.display_name;
                return str_a.localeCompare(str_b);
            });
        }
        else if (sortKey == 'account_type') {
            sorted = sorted.sort((a, b) => {
                if (a.type == 'depository') {
                    a.type = 'cash';
                }
                if (b.type == 'depository') {
                    b.type = 'cash';
                }
                const str_a = `${a.type} ${a.display_name}`.trim().toLowerCase();
                const str_b = `${b.type} ${b.display_name}`.trim().toLowerCase();
                return str_a.localeCompare(str_b);
            });
        }
        else if (sortKey == 'account_status') {
            const getSortOrder = account => {
                // Set sort order
                if (account.plaid_account_id ||
                    account.zabo_account_id ||
                    account.crypto_account_id) {
                    if (account.status == 'active') {
                        return 5;
                    }
                    else {
                        return 4;
                    }
                }
                else if (account.asset_id) {
                    return 3;
                }
                else if (!account.plaid_account_id &&
                    !account.asset_id &&
                    !account.zabo_account_id) {
                    return 2;
                }
                else {
                    return 1;
                }
            };
            sorted = sorted.sort((a, b) => {
                const str_a = `${getSortOrder(a)} ${a.display_name}`
                    .trim()
                    .toLowerCase();
                const str_b = `${getSortOrder(b)} ${b.display_name}`
                    .trim()
                    .toLowerCase();
                return str_a.localeCompare(str_b);
            });
        }
        else {
            // Sorted by amount by time
            // Get index in time array
            const timeIndex = timeArray.indexOf(sortKey);
            sorted = sorted.sort((a, b) => {
                var _a, _b;
                const compare_a = (_a = a.data[timeIndex]) === null || _a === void 0 ? void 0 : _a.to_base;
                const compare_b = (_b = b.data[timeIndex]) === null || _b === void 0 ? void 0 : _b.to_base;
                if (compare_a == null || typeof compare_a == 'undefined') {
                    return 1;
                }
                if (compare_b == null || typeof compare_b == 'undefined') {
                    return -1;
                }
                return compare_b - compare_a;
            });
        }
        // Are there any hidden accounts?
        // If so, we should recalculate the net worth array
        const _netWorthArrayOverride = [];
        if (_.find(Object.values(hiddenAccounts))) {
            for (let i = 0; i < timeArray.length; i++) {
                _netWorthArrayOverride[i] = 0;
                sorted.forEach(o => {
                    if (o.data[i] && o.data[i].hasOwnProperty('to_base')) {
                        if (o.is_asset) {
                            _netWorthArrayOverride[i] += o.data[i].to_base;
                        }
                        else {
                            _netWorthArrayOverride[i] -= o.data[i].to_base;
                        }
                    }
                });
            }
            netWorthArrayOverride.current = _netWorthArrayOverride;
        }
        else {
            netWorthArrayOverride.current = null;
        }
        if (reverseSorted.current) {
            setData([...sorted.reverse()]);
        }
        else {
            setData([...sorted]);
        }
    }, [balanceHistory, timeArray, hiddenAccounts, sortKey]);
    const applySort = col => {
        if (col == sortKey) {
            reverseSorted.current = !reverseSorted.current;
            setData([...data.reverse()]);
        }
        else {
            reverseSorted.current = false;
            setSortKey(col);
        }
    };
    const addTimeCol = () => {
        // Prepend to timeArray
        const newMonth = Moment(timeArray[0], 'YYYY-MM-DD')
            .subtract(1, 'month')
            .format('YYYY-MM-DD');
        // Prepend to each data.data
        const newData = data.map(o => {
            return Object.assign(Object.assign({}, o), { data: [null, ...o.data] });
        });
        // Update data
        updateData({
            time_array: [newMonth, ...timeArray],
            net_worth: [null, ...netWorthArray],
            by_accounts: [...newData],
        }, newMonth);
    };
    const updateExportableData = () => {
        // Figure out if we need to have base currency in sheet
        const allCurrencies = new Set();
        data.forEach(datum => {
            datum.data.forEach(o => {
                if (o) {
                    allCurrencies.add(o.currency);
                }
            });
        });
        let currentTimeIndex = timeArray.indexOf(Moment().format('YYYY-MM') + '-01');
        const showAllBalances = allCurrencies.size > 1;
        const _export = [];
        const headerRow = [
            'account_type',
            'account_status',
            'account_name',
            'type_name',
        ];
        timeArray.slice(0, currentTimeIndex + 1).forEach(time => {
            const displayTime = Moment(time, 'YYYY-MM-DD').format(_user.getMonthYearFormat());
            if (showAllBalances) {
                headerRow.push(`${displayTime} balance`);
                headerRow.push(`${displayTime} currency`);
                headerRow.push(`${displayTime} balance (base)`);
                headerRow.push(`${displayTime} currency (base)`);
            }
            else {
                headerRow.push(`${displayTime}`);
            }
        });
        _export.push(headerRow);
        data.forEach(datum => {
            const row = [];
            row.push(datum.asset_id || datum.crypto_manual_id
                ? 'manually-managed'
                : datum.plaid_account_id ||
                    data.zabo_account_id ||
                    data.crypto_account_id
                    ? 'synced'
                    : null, datum.asset_id ||
                datum.plaid_account_id ||
                data.zabo_account_id ||
                data.crypto_manual_id ||
                data.crypto_account_id
                ? datum.status
                : 'deleted', `${datum.display_name}`, datum.type);
            datum.data.slice(0, currentTimeIndex + 1).forEach(o => {
                if (o) {
                    if (showAllBalances) {
                        row.push(o.balance, o.currency, o.to_base, _user.primaryCurrency);
                    }
                    else {
                        row.push(o.balance);
                    }
                }
                else {
                    if (showAllBalances) {
                        row.push(null, null, null, null);
                    }
                    else {
                        row.push(null);
                    }
                }
            });
            _export.push(row);
        });
        // Net worth row
        const netWorthRow = [null, null, null, 'totals'];
        const netWorthData = (netWorthArrayOverride.current || netWorthArray).slice(0, currentTimeIndex + 1);
        if (showAllBalances) {
            netWorthData.forEach(value => {
                netWorthRow.push(null, null, value, value == null ? null : _user.primaryCurrency);
            });
        }
        else {
            netWorthRow.push(...netWorthData);
        }
        _export.push(netWorthRow);
        setExportableData(_export);
    };
    (0, react_1.useEffect)(() => {
        // The data here is already clean and sorted
        if (!data || !timeArray)
            return;
        updateExportableData();
        const _timeRow = [
            React.createElement("div", { key: 'date-empty', className: 'cell skinny-cell', onClick: addTimeCol },
                React.createElement(semantic_ui_react_1.Popup, { mouseEnterDelay: 750, inverted: true, size: "tiny", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "add circle" }) }, "Add historical balances")),
        ];
        let currentTimeIndex = timeArray.indexOf(Moment().format('YYYY-MM') + '-01');
        timeArray.slice(0, currentTimeIndex + 1).forEach((time, index) => {
            _timeRow.push(React.createElement("div", { key: `date-${time}`, className: `cell ${sortKey == time ? 'active-sort' : ''}`, onClick: () => {
                    applySort(time);
                } }, Moment(time).format(_user.getMonthYearFormat())));
        });
        setTimeRow([..._timeRow]);
        const _accountsCol = [];
        const _dataRow = [];
        data.forEach((account, index) => {
            _accountsCol.push(React.createElement("div", { className: `row table`, key: `account-${index}` },
                React.createElement("div", { className: `cell icon-cell` },
                    (account.plaid_account_id ||
                        account.zabo_account_id ||
                        (account.crypto_account_id && !account.is_excluded)) && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "cloud download", className: account.status == 'active'
                                ? 'color--green'
                                : 'color--grey' }), inverted: true, size: "small" }, account.status == 'active'
                        ? 'This is a currently active synced account.'
                        : 'This is a synced account which is not actively syncing. It may be set to inactive or there may be an error. To debug, go to the Accounts page.')),
                    (account.crypto_manual_id ||
                        (account.asset_id && account.status !== 'closed')) && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "upload", className: 'color--green' }), inverted: true, size: "small" }, "This is a manually-managed asset.")),
                    account.asset_id && account.status == 'closed' && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "calendar times outline", color: "grey" }), inverted: true, size: "small" },
                        "This account was closed on",
                        ' ',
                        Moment(account.closed_on).format(_user.getMonthDayYearFormat()),
                        ".")),
                    !account.plaid_account_id &&
                        !account.asset_id &&
                        !account.zabo_account_id &&
                        !account.crypto_manual_id &&
                        (!account.crypto_account_id || account.is_excluded) && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "lock", className: 'color--grey' }), inverted: true, hoverable: true, size: "small" },
                        "This is an account that previously existed. There are still historical balances available for this account which is why it shows up.",
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: buttonIsLoading, disabled: buttonIsLoading, size: "mini", className: "mt-1rem", color: "red", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield remove([
                                    {
                                        asset_id: null,
                                        plaid_account_id: null,
                                        zabo_account_id: null,
                                        zabo_currency: null,
                                        _id: account._id,
                                    },
                                ], account.data.filter(o => !!o).map(o => o.history_id));
                            }) }, "Remove this account")))),
                React.createElement("div", { className: `cell`, onClick: () => {
                        if (account.plaid_account_id) {
                            setShowModal(_assets.getPlaidAccount(account.plaid_account_id));
                        }
                        else if (account.asset_id) {
                            setShowModal(_assets.getAsset(account.asset_id));
                        }
                        else if (account.zabo_account_id) {
                            setShowModal(Object.assign({}, _assets.getCrypto(account.zabo_account_id, account.currency)));
                        }
                        else if (account.crypto_manual_id) {
                            setShowModal(Object.assign({}, _assets.getManualCrypto(account.crypto_manual_id)));
                        }
                        else if (account.crypto_account_id) {
                            setShowModal(Object.assign({}, _assets.getSyncedCrypto(account.crypto_account_id, account.crypto_coingecko_id)));
                        }
                    } },
                    React.createElement("span", { className: `ellipsis ${account.plaid_account_id ||
                            account.asset_id ||
                            account.zabo_account_id ||
                            account.crypto_manual_id ||
                            (account.crypto_account_id && !account.is_excluded)
                            ? 'link clickable'
                            : ''}` },
                        React.createElement("span", null, account.display_name))),
                React.createElement("div", { className: `cell right-align` },
                    React.createElement("span", null, account.type === 'other liability'
                        ? 'Liability'
                        : account.type === 'other asset'
                            ? 'Asset'
                            : account.type === 'cryptocurrency'
                                ? 'Crypto'
                                : account.type === 'employee compensation'
                                    ? 'Employee'
                                    : (0, format_1.capitalize)(account.type)))));
            const cells = [React.createElement("div", { key: 'non-value', className: "cell skinny-cell" })];
            account.data
                .slice(0, currentTimeIndex + 1)
                .forEach((balance, balanceIndex) => {
                const isCurrentMonth = balanceIndex == currentTimeIndex;
                const isReadOnly = 
                // If it's a synced account and it's not a stored historical balance
                (balance &&
                    !balance.history_id &&
                    (account.plaid_account_id || account.zabo_account_id)) ||
                    // If it's this month, only allow changing if it's asset or locked
                    (isCurrentMonth &&
                        (account.plaid_account_id ||
                            account.zabo_account_id ||
                            account.crypto_account_id)) ||
                    // Don't allow editing at all if it's in the future
                    balanceIndex > currentTimeIndex ||
                    (account.status == 'closed' &&
                        account.closed_on &&
                        Moment(timeArray[balanceIndex]).isSameOrAfter(Moment(account.closed_on)));
                cells.push(React.createElement("div", { className: `${isReadOnly ? '' : 'editable no-wrap-on-hover'} number-cell`, key: `cell-${index}-${balanceIndex}` },
                    React.createElement(EditablePrice_1.default, { identifier: `balance-editable-${index}-${balanceIndex}`, className: 'short net-worth-cell', state: isReadOnly ? 'ReadOnly' : 'Default', showSignLabel: false, showEmptyState: false, displayNegativeSign: true, allowEmpty: balanceIndex < currentTimeIndex, additionalCurrencies: !!account.crypto_manual_id && balance && isCurrentMonth
                            ? [_static.coinGeckoMap[balance.crypto_coingecko_id]]
                            : [], disableCurrency: !!account.crypto_manual_id && isCurrentMonth, amount: balance === null || balance === void 0 ? void 0 : balance.balance, currency: (balance === null || balance === void 0 ? void 0 : balance.currency) || _user.primaryCurrency, location: 'inline-edit', onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            if (isCurrentMonth && amount == null) {
                                _showToast({
                                    type: 'error',
                                    message: `Balance for current month's asset cannot be empty.`,
                                });
                            }
                            else if (!(0, checks_1.areSameNumber)(amount, balance === null || balance === void 0 ? void 0 : balance.balance) ||
                                currency != (balance === null || balance === void 0 ? void 0 : balance.currency) ||
                                ((balance === null || balance === void 0 ? void 0 : balance.currency) == null &&
                                    currency != _user.primaryCurrency)) {
                                const updateObj = {};
                                if (account.crypto_manual_id || account.crypto_account_id) {
                                    // The only update that can happen that keeps zabo currency is to manual crypto
                                    updateObj['crypto_currency_ref'] =
                                        account.crypto_currency_ref;
                                    updateObj['crypto_coingecko_id'] =
                                        account.crypto_coingecko_id;
                                    updateObj['crypto_balance'] = amount;
                                    updateObj['balance'] = amount;
                                    updateObj['currency'] = currency;
                                }
                                else {
                                    // Any other updates should override zabo currency/balance
                                    updateObj['crypto_currency_ref'] = null;
                                    updateObj['crypto_balance'] = null;
                                    updateObj['crypto_coingecko_id'] = null;
                                    updateObj['balance'] = amount;
                                    updateObj['currency'] = currency;
                                }
                                update(Object.assign({ date: timeArray[balanceIndex], _id: account._id, asset_id: account.asset_id, plaid_account_id: account.plaid_account_id, zabo_account_id: account.zabo_account_id, crypto_manual_id: account.crypto_manual_id, crypto_account_id: account.crypto_account_id, account_name: account.account_name, account_institution_name: account.account_institution_name, account_type: account.type }, updateObj), balance ? balance.to_base : 0);
                            }
                        }) })));
            });
            _dataRow.push(React.createElement("div", { className: `row`, key: `row-${index}` }, cells));
        });
        setDataRows([..._dataRow]);
        setAccountsCol([..._accountsCol]);
        const _netWorthCells = (netWorthArrayOverride.current || netWorthArray)
            .slice(0, currentTimeIndex + 1)
            .map((netWorth, index) => {
            if (netWorth !== null) {
                return (React.createElement("div", { className: "cell", key: `net-worth-${index}` }, (0, format_1.toPrice)(netWorth, _user.primaryCurrency)));
            }
            else {
                return (React.createElement("div", { className: "cell", key: `net-worth-${index}` }, "-"));
            }
        });
        setNetWorthRow([
            React.createElement("div", { key: 'cell-value', className: "cell skinny-cell" }),
            ..._netWorthCells,
        ]);
        setIsLoading(false);
    }, [data, rerender]);
    return isLoading ? (React.createElement(QueryLoader_1.default, { noBorder: false })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "net-worth-report tabular-report" },
            React.createElement("div", { className: "report" },
                React.createElement("div", { className: "left" },
                    React.createElement("div", { className: "row header table" },
                        React.createElement("div", { className: `cell icon-cell`, onClick: () => {
                                applySort('account_status');
                            } }),
                        React.createElement("div", { className: `cell ${sortKey == 'account_name' ? 'active-sort' : ''}`, onClick: () => {
                                applySort('account_name');
                            } }, "Account Name"),
                        React.createElement("div", { className: `cell right-align ${sortKey == 'account_type' ? 'active-sort' : ''}`, onClick: () => {
                                applySort('account_type');
                            } }, "Type")),
                    accountsCol,
                    React.createElement("div", { className: "footer row table" },
                        React.createElement("div", { className: "cell" }),
                        React.createElement("div", { className: "cell" }),
                        React.createElement("div", { className: "cell right-align" },
                            React.createElement("span", null, "Totals")))),
                React.createElement("div", { className: "center" },
                    React.createElement("div", { className: "center-table" },
                        React.createElement("div", { className: "row header" }, timeRow),
                        dataRows,
                        React.createElement("div", { className: "footer row" }, netWorthRow))))),
        !!showModal && (React.createElement(NewModal_1.default, { show: !!showModal, setShow: show => {
                setShowModal(show);
            }, data: {
                account: showModal,
            }, utils: {
                _process,
                _showToast,
                relinkAccount: () => { },
                setRerender,
            }, view: 'LINKED_ACCOUNT_VIEW', closeCallback: () => {
                fetch();
            } }))));
};
exports.default = NetWorthTable;
